export const sharedWorker = {};

export const sharedWorkerMessageType = {
    registerWindow: 'REGISTER_NEW_WINDOW',
    windowIdUpdate: 'WINDOW_ID_UPDATE',
    removeWindow: 'REMOVE_WINDOW',
    windowsCountChange: 'WINDOWS_COUNT_CHANGE',
    windowModeChange: 'WINDOW_MODE_CHANGE',
    deskPauseRequestSent: 'DESK_PAUSE_REQUEST_SENT',
    deskSelected: 'DESK_SELECTED',
    transactionFinished: 'TRANSACTION_FINISHED',
    transactionReopen: 'TRANSACTION_REOPEN',
    subscribe: 'SUBSCRIBE_TO_STOMP_BROKER_TOPIC',
    unsubscribe: 'UNSUBSCRIBE_FROM_STOMP_BROKER_TOPIC',
    stompMessage: 'STOMP_BROKER_MESSAGE',
    machineWakeUpDetected: 'MACHINE_WAKE_UP_DETECTED',
};

export const stompTopics = {
    transactionDesk: 'transactionDesk',
    videoWall: 'videoWall',
    streamTest: 'streamTest',
    vinCheck: 'vinCheck',
    vinCheckLocation: 'vinCheckLocation',
    allDesks: 'allDesks',
    cameraUpdates: { topicName: 'cameraUpdates', topicUrl: '/topic/camera-updates' },
};
export const cameraUpdatesRelatedTopics = [stompTopics.transactionDesk, stompTopics.videoWall, stompTopics.streamTest, stompTopics.vinCheck];

let messageHandler = {
    get: function (target, name) {
        if (Object.hasOwn(target, name)) {
            return (data) => ({
                type: sharedWorkerMessageType[name],
                ...(sharedWorker.id && { id: sharedWorker.id }),
                data,
            });
        } else return () => {};
    },
};

let actionHandler = {
    get: function (target, name) {
        if (Object.hasOwn(target, name)) {
            return (data) =>
                sharedWorker.port &&
                sharedWorker.port.postMessage({
                    type: sharedWorkerMessageType[name],
                    id: sharedWorker.id,
                    data,
                });
        } else return () => {};
    },
};

export const sharedWorkerMessage = new Proxy(sharedWorkerMessageType, messageHandler);
export const sharedWorkerActions = new Proxy(sharedWorkerMessageType, actionHandler);
