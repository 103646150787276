import { axiosAiq } from 'config';

export class CameraAudioSettings {
    cameraId;
    #hasPresetValues;

    #micGain;
    #soundGain;
    #filterEnabled;
    #filterType;
    #filterFrequency;
    #filterQ;
    #filterGain;
    #backendFilterEnabled;
    #backendDenoiserEnabled;
    #backendGain;
    fetchingPreset;

    #subscribers = [];
    #audioSettingsDefaults = {
        browser: { mic: 1, sound: 35, filter: true, filterType: 'bandpass', filterFreqHz: 355, filterQ: 8.3, filterGain: 3.0 },
        talk: { gain: 0, denoiser: false, filter: false },
    };

    constructor(cameraId) {
        this.cameraId = cameraId;
    }

    #set(audioSettings) {
        const {
            browser: { mic, sound, filter: filterEnabled, filterType, filterFreqHz, filterQ, filterGain },
            talk: { gain, denoiser, filter },
        } = audioSettings || this.#audioSettingsDefaults;

        this.#micGain = mic;
        this.#soundGain = sound;
        this.#filterEnabled = filterEnabled;
        this.#filterType = filterType;
        this.#filterFrequency = filterFreqHz;
        this.#filterQ = filterQ;
        this.#filterGain = filterGain;
        this.#backendFilterEnabled = filter;
        this.#backendDenoiserEnabled = denoiser;
        this.#backendGain = gain;
        this.publish();
    }

    set micGain(gain) {
        this.#log(`micGain():New micGain = ${gain}`);
        this.#micGain = gain;
    }

    set soundGain(gain) {
        this.#log(`soundGain():New sound gain = ${gain}`);
        this.#soundGain = gain;
    }

    set filterEnabled(boolean) {
        if (boolean === this.#filterEnabled) return;
        this.#log(`filterEnabled(): filterEnabled = ${boolean}`);
        this.#filterEnabled = boolean;
    }
    set filterType(type) {
        this.#log(`filterType():New filterType = ${type}`);
        this.#filterType = type;
    }
    set filterFrequency(frequency) {
        this.#log(`filterFrequency():New frequency = ${frequency}`);
        this.#filterFrequency = frequency;
    }

    set filterQ(value) {
        this.#log(`filterQ():New filterQ = ${value}`);
        this.#filterQ = value;
    }

    set filterGain(value) {
        this.#log(`filterGain():New filterGainValue = ${value}`);
        this.#filterGain = value;
    }

    set backendGain(gain) {
        this.#log(`backendGain():New backend gain = ${gain}`);
        this.#backendGain = gain;
    }

    set backendFilterEnabled(boolean) {
        this.#log(`backendFilterEnabled(): backendFilterEnabled = ${boolean}`);
        this.#backendFilterEnabled = boolean;
    }

    set backendDenoiserEnabled(boolean) {
        this.#log(`backendDenoiserEnabled(): backendDenoiserEnabled = ${boolean}`);
        this.#backendDenoiserEnabled = boolean;
    }

    get settings() {
        return {
            micGain: this.#micGain,
            soundGain: this.#soundGain,
            filterEnabled: this.#filterEnabled,
            filterType: this.#filterType,
            filterFrequency: this.#filterFrequency,
            filterQ: this.#filterQ,
            filterGain: this.#filterGain,
            backendGain: this.#backendGain,
            backendDenoiserEnabled: this.#backendDenoiserEnabled,
            backendFilterEnabled: this.#backendFilterEnabled,
            hasPresetValues: this.#hasPresetValues,
        };
    }

    publish() {
        this.#subscribers.forEach((subscriber) => {
            subscriber((state) => {
                return !state;
            });
        });
    }

    set subscriber(subscriber) {
        if (!this.#subscribers.includes(subscriber)) {
            this.#subscribers.push(subscriber);
            this.publish();
        }
    }

    get subscribers() {
        return this.#subscribers;
    }

    removeSubscriber(subscriber) {
        this.#subscribers = this.#subscribers.filter((s) => s !== subscriber);
    }

    async fetchPreset() {
        this.fetchingPreset = true;
        this.#hasPresetValues = false;
        let data;
        try {
            const response = await axiosAiq(`/camera/${this.cameraId}/config`);
            data = response?.data?.talkConfig;
            this.#hasPresetValues = true;
        } catch (err) {
            this.#log(`fetchPreset(): Error getting audio settings for cameraId=${this.cameraId}, message=${err.message}, setting defaults.`);
        }
        this.#set(data);
        this.fetchingPreset = false;
    }

    async savePreset() {
        try {
            axiosAiq.put(`/camera/${this.cameraId}/config`, {
                browser: {
                    mic: this.#micGain,
                    sound: this.#soundGain,
                    filter: this.#filterEnabled,
                    filterType: this.#filterType,
                    filterFreqHz: this.#filterFrequency,
                    filterQ: this.#filterQ,
                    filterGain: this.#filterGain,
                },
                talk: {
                    gain: this.#backendGain,
                    denoiser: this.#backendDenoiserEnabled,
                    filter: this.#backendFilterEnabled,
                },
            });
            this.#hasPresetValues = true;
            this.#log(`savePreset(): Audio settings saved`);
        } catch (err) {
            this.#log(`savePreset(): Error saving audio settings, message=${err.message}`);
        }
    }

    #log(message, mode = 'log') {
        console[mode](`[Audio settings cameraId=${this.cameraId}] ${message}`);
    }

    get settingNames() {
        return this.constructor.settingNames;
    }

    static get settingNames() {
        return {
            micGain: 'micGain',
            soundGain: 'soundGain',
            filterEnabled: 'filterEnabled',
            filterType: 'filterType',
            filterFrequency: 'filterFrequency',
            filterQ: 'filterQ',
            filterGain: 'filterGain',
            backendGain: 'backendGain',
            backendDenoiserEnabled: 'backendDenoiserEnabled',
            backendFilterEnabled: 'backendFilterEnabled',
            hasPresetValues: 'hasPresetValues',
            talkService: { filter: 'filter', denoiser: 'denoiser', gain: 'gain' },
        };
    }
}
