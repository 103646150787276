import Select from 'components/UI/Inputs/select/Select';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import CircularLoader from 'components/UI/Loaders/CircularLoader';
import { axiosAiq } from 'config';

import styles from './LocationsDropdown.module.css';

function LocationsDropdown({
    onGetCameras,
    onSelectedLocation,
    onStreamPerPageChange,
    fetchingStreamsInProgress,
    fetchingStreamsError,
    locationsUrl,
    liveStreamsPerPage,
    streamsPerPageLookup,
}) {
    const [locations, setLocations] = useState({ selectedLocation: '', locationsArr: [], fetchingInProgress: false, locationError: '' });
    const { selectedLocation, locationsArr, fetchingInProgress, locationError } = locations;
    const [streamsPerPage, setStreamsPerPage] = useState({
        streamsPerPageValue: liveStreamsPerPage,
    });
    const { streamsPerPageValue } = streamsPerPage;

    useEffect(() => {
        fetchLocations(locationsUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLocationsPropertyValue('locationError', fetchingStreamsError);
    }, [fetchingStreamsError]);

    const locationChangeHandler = function (_, selectedLocation) {
        setLocationsPropertyValue('selectedLocation', selectedLocation);
        setLocationsPropertyValue('locationError', '');
        onSelectedLocation(selectedLocation);
    };

    const streamsPerPageChangeHandler = function (_, streamPerPage) {
        setStreamsPerPagePropertyValue('streamsPerPage', streamPerPage);
        onStreamPerPageChange(streamPerPage);
    };

    const locationLookup = {
        displayExpr: 'name',
        dataSource: locationsArr,
    };

    return (
        <div className={styles.location}>
            <Select
                label="Location"
                lookup={locationLookup}
                changeHandler={locationChangeHandler}
                value={selectedLocation}
                isOptionsLoadingInProgress={fetchingInProgress}
                required
                error={locationError}
                touched
                containerClasses={styles['location-autocomplete']}
                helperText={`${typeof locationError === 'string' ? locationError : ''}`}
            />

            {!!streamsPerPageLookup && (
                <Select
                    label="Streams per page"
                    lookup={streamsPerPageLookup}
                    changeHandler={streamsPerPageChangeHandler}
                    value={streamsPerPageValue}
                    required
                    touched
                    containerClasses={styles['location-autocomplete']}
                />
            )}
            <div style={{ position: 'relative', paddingTop: '0.1rem' }}>
                {!!fetchingStreamsInProgress && <CircularLoader containerStyles={{ transform: 'scale(0.8)' }} />}

                <Button
                    sx={{ width: '10rem', visibility: fetchingStreamsInProgress ? 'hidden' : 'visible' }}
                    variant="outlined"
                    onClick={onGetCameras}
                >
                    Get cameras
                </Button>
            </div>
        </div>
    );

    async function fetchLocations(url) {
        try {
            setLocationsPropertyValue('fetchingInProgress', true);
            const response = await axiosAiq(url);
            const { data } = response;
            const locationsArr = data.map((location) => {
                return { name: location.fullName, id: location.id };
            });
            setLocationsPropertyValue('locationsArr', locationsArr);
        } catch {
            setLocationsPropertyValue('locationError', 'Error fetching locations');
        }
        setLocationsPropertyValue('fetchingInProgress', false);
    }

    function setLocationsPropertyValue(property, value) {
        setLocations((state) => {
            return { ...state, [property]: value };
        });
    }

    function setStreamsPerPagePropertyValue(property, value) {
        setStreamsPerPage((state) => {
            return { ...state, [property]: value };
        });
    }
}
export default LocationsDropdown;
