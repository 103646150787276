import Alert from './Alert';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';

const AlertInfo = ({ alerts, mode }) => {
    return (
        <div>
            {alerts.map(({ color, note, name, accessPeriod }, index) => {
                return (
                    <div key={name}>
                        <Alert name={name} color={color} note={note} mode={mode} accessPeriod={accessPeriod} />
                        {index < alerts.length - 1 && <Divider />}
                    </div>
                );
            })}
        </div>
    );
};

export default AlertInfo;

AlertInfo.propTypes = {
    alerts: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            note: PropTypes.string,
            name: PropTypes.string,
            accessPeriod: PropTypes.object,
        })
    ).isRequired,
    mode: PropTypes.string,
};
