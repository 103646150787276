import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import wrapWithProviders from 'utils/wrapWithProviders';

async function enableAPIMocking() {
    if (import.meta.env.VITE_MSW !== 'enabled') return;
    const { worker } = await import('./mocks/browser/serviceWorker.js');
    return worker.start({
        onUnhandledRequest() {}, //disable warnings for unhandeled requests
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
enableAPIMocking().then(() => {
    root.render(wrapWithProviders(<App />));
});
