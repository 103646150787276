import ProcessedSnapshotsActions from 'components/vinCheck/processingSection/processedSnapshots/ProcessedSnapshotsActions/ProcessedSnapshotsActions';
import styles from './MasterDetail.module.css';

function MasterDetail(props) {
    const { encoded_processed_image: images, vin_transaction_id: vinTransactionId, location, status } = props?.data?.data || {};
    const locationId = location?.id;
    const { onConfirmProcessedSnapshot, sendingConfirmation } = this;

    const imagesArr =
        images?.map((src, index) => <img key={index} className={styles.snapshot} src={`data:image/jpeg;base64,${src}`} alt="asd" />) || [];

    return (
        <div className={styles.snapshots}>
            {imagesArr}
            {status === 'REVIEW' && (
                <ProcessedSnapshotsActions
                    vinTransactionId={vinTransactionId}
                    locationId={locationId}
                    onConfirmProcessedSnapshot={onConfirmProcessedSnapshot}
                    sendingConfirmation={sendingConfirmation}
                    tablePageIndex={props.data.component.pageIndex()}
                    processedSnapshotsLoaded
                    clearCurrentTransaction={false}
                />
            )}
        </div>
    );
}

export default MasterDetail;
