import { useEffect, useState } from 'react';
import React, { useRef } from 'react';
import styles from './AIQInput.module.css';
import Automation from './Automation/Automation';
import Input from './Input/Input';
import Label from './Label/Label';
import Alerts from 'components/UI/Alerts/Alerts';
import { useDispatch } from 'react-redux';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';

let componentId = 1;

function AIQInput(props) {
    const {
        label,
        automation,
        backgroundColor,
        borderColor,
        disabled,
        error,
        helperText,
        forceAutomationStyles,
        name,
        option,
        issues,
        transactionId,
        camInfo,
        inputContainerClasses,
    } = props;

    const [focused, setFocused] = useState(false);
    const [id] = useState(componentId++);
    const hasAutomation = (automation && Object.keys(automation).length) || (forceAutomationStyles && name !== 'sendToBison'); //TODO do not hardcode, ask for flags from BE
    const containerRef = useRef();
    const labelRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        const { current: containerElement } = containerRef;
        const { current: labelElement } = labelRef;

        if (backgroundColor) {
            containerElement.style.backgroundColor = backgroundColor;
            labelElement.style.outline = `1px solid ${backgroundColor}`;
            // labelElement.style.color = mainColor;
        }
        if (borderColor) {
            containerElement.style.outline = `2px solid ${borderColor}`;
            labelElement.style.backgroundColor = borderColor;
            labelElement.style.color = 'white';
        }
        return () => {
            containerElement.removeAttribute('style');
            labelElement.removeAttribute('style');
        };
    }, [backgroundColor, borderColor]);

    const containerStyles = getContainerStyles({
        inputContainerClasses,
        focused,
        hasAutomation,
        disabled,
        error,
    });

    const inputStyles = `${styles.input}${backgroundColor || hasAutomation ? ` ${styles['input--border-color']}` : ''}`;

    const clickHandlerTransactionBugIcon = (state) => {
        dispatch(transactionActions.setReportIssuesModalState(state));
        dispatch(transactionActions.setSelectedCameraIssues(issues));
        dispatch(transactionActions.setSelectedIssueUrl(`/transactions/trucks/${transactionId}/issue`));
        dispatch(transactionActions.setIssueTitle(issues[0]?.issueType?.issueName));
        dispatch(transactionActions.setIssueDataLoggs({ ...camInfo, fieldName: props.name }));
    };

    return (
        <div className={styles['wrapper-container']}>
            <div className={containerStyles} ref={containerRef}>
                <Label {...{ id, label, focused, error }} ref={labelRef} />
                <Alerts alerts={option?.alerts} containerClasses={styles['alert-container--absolute']} mode="hover" />
                <div className={styles['issue-icon-automation-container']}>
                    <Automation disabled={disabled} automation={automation} issues={issues} onBugIconClick={clickHandlerTransactionBugIcon} />
                </div>
                <Input {...{ inputStyles, setFocused, id, focused, ...props }} />
            </div>
            {/* {helperText !== undefined && ( */}
            <p className={styles['helper-text']}>{helperText}</p>
            {/* )} */}
        </div>
    );
}

export default React.memo(AIQInput);

function getContainerStyles({ focused, hasAutomation, disabled, error, inputContainerClasses }) {
    return `${styles['input-container']}${inputContainerClasses ? ` ${inputContainerClasses}` : ''}${
        focused ? ` ${styles['input-container--focus']}` : ''
    }${hasAutomation ? '' : ` ${styles['input-container--input-only']}`}${disabled ? '' : ` ${styles['input-container--standard']}`}${
        error
            ? focused
                ? ` ${styles['input-container--error']} ${styles['input-container--focus--error']}`
                : ` ${styles['input-container--error']}`
            : ''
    }`;
}
