import styles from './AudioSettings.module.css';
import { Slider } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import RefreshIcon from '@mui/icons-material/Refresh';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useState } from 'react';
import Checkbox from 'components/UI/Inputs/checkbox/Checkbox';
import BrowserFilterSettings from './BrowserFilterSettings/BrowserFilterSettings';
import CancelIcon from '@mui/icons-material/Cancel';
import { talkManager } from 'services/Talk/TalkManager';

const settingNames = talkManager.settingNames;

function AudioSettings({ cameraId, onClose, disabled, active, position }) {
    // backend audio settings
    const [changeDetected, setChangeDetected] = useState(false);
    const [settings, setSettings] = useState(getCameraAudioSettings(cameraId));
    const [showFilterSettings, setShowFilterSettings] = useState({ show: false, position: 'left' });

    const toggleShowFilterSettings = () =>
        setShowFilterSettings((state) => {
            return { ...state, show: !state.show };
        });

    const handleShowFilterSettings = (e) => {
        const position = e.target.getBoundingClientRect().x > 350 ? 'left' : 'right';
        setShowFilterSettings((state) => ({ show: !state.show, position }));
    };
    useEffect(() => {
        talkManager.registerSubscriber(cameraId, setChangeDetected);
        return () => talkManager.removeSubscriber(cameraId, setChangeDetected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!active) return;
        setSettings(getCameraAudioSettings(cameraId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, changeDetected]);

    function handleChange(e, sliderValue) {
        const { setting, slider, save, talkServiceSetting } = this;
        let value = slider ? sliderValue : e;
        setSettings((state) => ({ ...state, [setting]: value }));

        const val = setting === settingNames.micGain ? convertGainValue.toActualValue(value) : value;
        talkManager.set(cameraId, setting, val);
        save && saveAudioSettings();
        talkServiceSetting && talkManager.changeTalkServiceSetting(cameraId, { [talkServiceSetting]: value });
    }

    const saveAudioSettings = function () {
        talkManager.saveAudioSettings(cameraId);
    };

    const classes = `${styles['sound-config']} ${styles[`sound-config--position-${position}`]}`;
    return (
        <>
            <div
                onMouseLeave={onClose}
                className={classes}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
            >
                <CancelIcon
                    color="error"
                    style={{ position: 'absolute', top: 0, right: 0, transform: 'translate(30%, -30%)', cursor: 'pointer' }}
                    onClick={onClose}
                />
                <div className={styles['mic-gain']}>
                    <MicIcon color="action" />
                    <Slider
                        aria-label="Mic Gain"
                        value={settings.micGain}
                        onChange={handleChange.bind({ slider: true, setting: settingNames.micGain })}
                        onMouseUp={saveAudioSettings}
                        scale={convertGainValue.toActualValue}
                        size="small"
                        min={soundConfig.mic.minValue}
                        max={soundConfig.mic.maxValue}
                        step={soundConfig.mic.step}
                        valueLabelDisplay="auto"
                    />
                </div>
                <div className={styles['speaker-config']}>
                    <div className={`${styles.divider} ${styles.divider__top}`} />
                    <VolumeUpIcon color="action" style={{ padding: 0 }} />
                    <Slider
                        aria-label="Talk service speaker gain"
                        value={settings.backendGain}
                        onChange={handleChange.bind({ slider: true, setting: settingNames.backendGain })}
                        onMouseUp={(e) => {
                            const value = e.target.querySelector('input')?.value;
                            talkManager.changeTalkServiceSetting(cameraId, { gain: value });
                            saveAudioSettings();
                        }}
                        size="small"
                        min={soundConfig.talkService.minValue}
                        max={soundConfig.talkService.maxValue}
                        step={soundConfig.talkService.step}
                        valueLabelDisplay="auto"
                    />
                    <div className={styles['filters']}>
                        <Checkbox
                            label="Filter"
                            id={`talk-service-filter-${cameraId}`}
                            value={settings.backendFilterEnabled}
                            onChange={handleChange.bind({
                                setting: settingNames.backendFilterEnabled,
                                talkServiceSetting: settingNames.talkService.filter,
                                save: true,
                            })}
                        />
                        <Checkbox
                            label="Denoizer"
                            id={`talk-service-denoizer-${cameraId}`}
                            value={settings.backendDenoiserEnabled}
                            onChange={handleChange.bind({
                                setting: settingNames.backendDenoiserEnabled,
                                talkServiceSetting: settingNames.talkService.denoiser,
                                save: true,
                            })}
                        />

                        <RefreshIcon
                            color={disabled ? 'disabled' : 'primary'}
                            style={{ justifySelf: 'end', alignSelf: 'center', fontSize: '2rem', cursor: 'pointer' }}
                            onClick={() => talkManager.flush(cameraId)}
                        />
                    </div>

                    <div className={`${styles.divider} ${styles.divider__bottom}`} />
                    <VolumeUpIcon color="action" style={{ padding: 0 }} />
                    <Slider
                        aria-label="Browser speaker gain"
                        value={settings.soundGain}
                        onChange={handleChange.bind({ slider: true, setting: settingNames.soundGain })}
                        onMouseUp={saveAudioSettings}
                        size="small"
                        min={soundConfig.browser.minValue}
                        max={soundConfig.browser.maxValue}
                        step={soundConfig.browser.step}
                        valueLabelDisplay="auto"
                    />
                    <div className={styles['filters']}>
                        <Checkbox
                            label="Filter"
                            id={`incomingAudio-filter-${cameraId}`}
                            initialState={settings.filterEnabled}
                            onChange={handleChange.bind({ setting: settingNames.filterEnabled, save: true })}
                        />
                        {settings.filterEnabled && (
                            <SettingsIcon
                                color={showFilterSettings.show ? 'action' : 'primary'}
                                style={{ gridColumn: 2, justifySelf: 'end', cursor: 'pointer' }}
                                onClick={handleShowFilterSettings}
                            />
                        )}
                    </div>
                </div>

                {showFilterSettings.show && (
                    <BrowserFilterSettings
                        onClose={toggleShowFilterSettings}
                        onChange={handleChange}
                        settings={settings}
                        saveAudioSettings={saveAudioSettings}
                        settingNames={settingNames}
                        position={showFilterSettings.position}
                    />
                )}
            </div>
        </>
    );
}

export default AudioSettings;

class ConvertGainValue {
    toSliderValue(value = 1) {
        if (Number.isNaN(value)) value = 1;
        if (value <= 1) return value;
        return Math.round(value) / 10 + 0.9;
    }

    toActualValue(value = 1) {
        if (Number.isNaN(value)) value = 1;
        if (value <= 1) return value;
        return Math.round((value - 0.9) * 10);
    }
}

const convertGainValue = new ConvertGainValue();

function getCameraAudioSettings(cameraId) {
    const settings = talkManager.getCameraAudioSettings(cameraId);
    settings.micGain = convertGainValue.toSliderValue(settings.micGain);
    return settings;
}

const soundConfig = {
    mic: {
        minValue: 0,
        maxValue: 1.9,
        step: 0.1,
    },

    talkService: {
        minValue: -200,
        maxValue: 200,
        step: 1,
    },

    browser: {
        minValue: 0,
        maxValue: 200,
        step: 1,
    },
};
