import { Slider } from '@mui/material';
import styles from './BrowserFilterSettings.module.css';
import Select from 'components/UI/Inputs/select/Select';

function BrowserFilterSettings({ onClose, onChange, settings, settingNames, saveAudioSettings, position }) {
    const handleFilterTypeChange = function (e, option) {
        if (e.type === 'mousedown') return;
        onChange.call({ setting: settingNames.filterType, save: true }, option);
    };

    const classes = `${styles['filter-settings']} ${styles[`filter-settings--${position}`]}`;

    return (
        <div className={classes} onMouseLeave={onClose}>
            <div className={styles.divider} />
            <Select lookup={filterConfig.typeLookup} value={settings.filterType} changeHandler={handleFilterTypeChange} label="Type" />
            <div className={styles.slider}>
                <p>Frequency</p>
                <Slider
                    aria-label="Browser filter frequency"
                    value={settings.filterFrequency}
                    onChange={onChange.bind({ slider: true, setting: settingNames.filterFrequency })}
                    onClick={saveAudioSettings}
                    size="small"
                    min={filterConfig.frequency.minValue}
                    max={filterConfig.frequency.maxValue}
                    step={filterConfig.frequency.step}
                    valueLabelDisplay="auto"
                />
            </div>
            <div className={styles.slider}>
                <p>Q</p>
                <Slider
                    aria-label="Browser filter Q"
                    value={settings.filterQ}
                    onChange={onChange.bind({ slider: true, setting: settingNames.filterQ })}
                    onClick={saveAudioSettings}
                    size="small"
                    min={filterConfig.q.minValue}
                    max={filterConfig.q.maxValue}
                    step={filterConfig.q.step}
                    valueLabelDisplay="auto"
                />
            </div>
            <div className={styles.slider}>
                <p>Filter gain</p>
                <Slider
                    aria-label="Browser filter gain"
                    value={settings.filterGain}
                    onChange={onChange.bind({ slider: true, setting: settingNames.filterGain })}
                    onClick={saveAudioSettings}
                    size="small"
                    min={filterConfig.gain.minValue}
                    max={filterConfig.gain.maxValue}
                    step={filterConfig.gain.step}
                    valueLabelDisplay="auto"
                />
            </div>
        </div>
    );
}

export default BrowserFilterSettings;

const filterConfig = {
    typeLookup: { dataSource: ['lowpass', 'highpass', 'bandpass', 'lowshelf', 'highshelf', 'peaking', 'notch', 'allpass'] },
    frequency: {
        minValue: 0,
        maxValue: 1000,
        step: 5,
    },
    q: {
        minValue: -20,
        maxValue: 20,
        step: 0.1,
    },

    gain: {
        minValue: 0,
        maxValue: 200,
        step: 1,
    },
};
