export const transactionTableConfig = {
    allLocationsTransactions: {
        dataUrlPath: (pageNum, size) => `/transactions/trucks/recent?page=${pageNum}&size=${size}`,
        configUrlPath: `/transactions/trucks/table/columns?type=Status`,
        configName: 'ALL',
        tableId: 'all_transactions',
    },
    singleLocationTransactions: {
        dataUrlPath: (pageNum, size, locationId) => `/transactions/trucks/location/${locationId}?page=${pageNum}&size=${size}`,
        configUrlPath: `/transactions/trucks/table/columns?type=Status`,
        configName: 'ALL',
    },

    singleLocationAnnouncements: {
        dataUrlPath: (pageNum, size, locationId) => `/transactions/trucks/announcement/location/${locationId}?page=${pageNum}&size=${size}`,
        configUrlPath: `/transactions/trucks/table/announcements/table/columns`,
        configName: 'ANNOUNCEMENT',
    },

    allLocationsAnnouncements: {
        dataUrlPath: (pageNum, size) => `/transactions/trucks/announcement/location?page=${pageNum}&size=${size}`,
        configUrlPath: `/transactions/trucks/table/announcements/table/columns/all`,
        configName: 'ANNOUNCEMENT_ALL',
        tableId: 'all_announcements',
    },
};
