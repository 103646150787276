import AiqDialog from 'components/UI/Dialogs/AiqDialog';
import styles from './Help.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';

const keyboardShortcuts = [
    { shortcut: 'CTRL + Number', description: 'will activate nth(Number) transaction tab' },
    { shortcut: 'CTRL + O', description: 'will activate Reopen tab' },
    { shortcut: 'CTRL + Enter', description: 'will submit opened transaction' },
    { shortcut: 'CTRL + Q', description: 'will select first enabled input' },
    { shortcut: 'CTRL + E', description: 'will select first enabled and empty (has no value) input' },
    { shortcut: 'CTRL + M', description: 'will mute/unmute audio' },
    { shortcut: 'CTRL + D', description: 'will mute/unmute microphone' },
    { shortcut: 'Tab', description: 'will move focus to the next field (component)' },
    { shortcut: 'Shift + Tab', description: 'will move focus to the previous field (component)' },
    { shortcut: 'Shift + Mouse wheel scroll', description: 'will scroll page horizontally' },
    { shortcut: 'CTRL + Shift + Z', description: 'will open the last selected camera in fullscreen' },
];

function Help({ isTransactionPageActive }) {
    const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);

    const toggleOpenHelpDialog = () => setIsHelpDialogOpen((state) => !state);
    const shortcuts = keyboardShortcuts.map(({ shortcut, description }) => (
        <div className={styles.shortcut} key={shortcut}>
            <strong>{shortcut}</strong> <span>- {description}</span>
        </div>
    ));

    const color = isTransactionPageActive ? 'var(--color-white)' : 'var(--color-disabled)';
    const cursor = isTransactionPageActive ? 'pointer' : 'default';

    return (
        <>
            <HelpOutlineIcon {...(isTransactionPageActive && { onClick: toggleOpenHelpDialog })} sx={{ cursor, color }} />
            {isHelpDialogOpen && (
                <AiqDialog open={isHelpDialogOpen} onClose={toggleOpenHelpDialog} title="Keyboard shortcuts">
                    {shortcuts}
                </AiqDialog>
            )}
        </>
    );
}

export default Help;
