import { fieldMappings } from 'components/transaction/components/panels/transaction/announcements/announcementsConfig';
import { axiosAiq } from 'config';
import { omit } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { notificationHandler } from 'utils/utils';
import { selectFormById } from '../formControlSelectors';
import { formControlActions } from '../formControlSlice';

class CopyToFormSagas {
    init = () => {
        return [
            call(this.fromBisonAnnouncementTable.bind(this)),
            call(this.fromLocationTransactionsTable.bind(this)),
            call(this.fromAppointmentsTable.bind(this)),
        ];
    };

    *fromBisonAnnouncementTable() {
        yield takeEvery(formControlActions.copyBisonAnnouncement.type, this.copyBisonAnnouncement.bind(this));
    }

    *fromLocationTransactionsTable() {
        yield takeEvery(formControlActions.copyLocationTransaction.type, this.copyLocationTransaction.bind(this));
    }

    *fromAppointmentsTable() {
        yield takeEvery(formControlActions.copyAppointmentData.type, this.copyAppointmentData.bind(this));
    }

    *copyBisonAnnouncement({ payload: { transactionId, tableName: table, values, key, copy } }) {
        const tableName = copy ? table : '';
        const internalIdAnnouncementUsed = copy ? key : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, transactionId, values)));
        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));
        yield put(formControlActions.setAnnouncementIdUsed({ formId: transactionId, internalIdAnnouncementUsed }));
        copy && (yield put(formControlActions.setFieldValue({ formId: transactionId, fieldName: 'sendToBison', value: true })));
        function getAutomationValuesActionObj(select, transactionId, values) {
            if (select)
                return {
                    transactionId,
                    automationObjTemplate: { source: 'Bison' },
                    customSource: { values, fieldMappings },
                };
            return { transactionId, setAlisaAutomation: true };
        }
    }

    *copyAppointmentData({ payload: { transactionId, values, copy, appointmentId } }) {
        const existingForm = yield select(selectFormById(transactionId));
        if (copy) {
            try {
                const nullTransaction = Object.keys(existingForm.form.values).reduce((acc, key) => {
                    const mainKey = key.split('.')[0];
                    if (!(mainKey in values)) {
                        acc[mainKey] = null;
                    }
                    return acc;
                }, {});

                const transactionData = {
                    ...existingForm?.transactionData,
                    ...omit(values, ['number', 'plannedTime', 'status', 'waitingReason']),
                    ...nullTransaction,
                    id: transactionId,
                    appointment: { id: appointmentId },
                };

                yield put(formControlActions.setTransactionData({ transactionData }));
            } catch (err) {
                yield notificationHandler({ err, title: `ERROR COPYING DATA TO FORM` });
                return;
            }
        }
        if (!copy) {
            // reset appointment data to null
            const transactionData = { ...existingForm?.transactionData, appointment: null };
            yield put(formControlActions.setTransactionData({ transactionData }));
        }

        const tableName = copy ? 'appointments' : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, transactionId)));

        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));

        function getAutomationValuesActionObj(select, transactionId) {
            const source = 'Copied';
            if (select) return { transactionId, automationObjTemplate: { source } };
            return { transactionId, setAlisaAutomation: true };
        }
    }

    *copyLocationTransaction({ payload: { transactionId, announcementsMode, copy, tableName: table, key } }) {
        if (copy) {
            try {
                const urlPath = `/transactions/trucks${announcementsMode ? '/announcements' : ''}/from/${key}/to/${transactionId}`;
                const { data: transactionData } = yield axiosAiq.get(urlPath);
                yield put(formControlActions.setTransactionData({ transactionData }));
            } catch (err) {
                yield notificationHandler({ err, title: `ERROR COPYING DATA TO FORM` });
                return;
            }
        }

        const manualAnnouncementIdUsed = copy && announcementsMode ? key : '';
        const tableName = copy ? table : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, announcementsMode, transactionId)));

        yield put(formControlActions.setAnnouncementIdUsed({ formId: transactionId, manualAnnouncementIdUsed }));

        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));

        function getAutomationValuesActionObj(select, announcementsMode, transactionId) {
            const source = announcementsMode ? 'Manual' : 'Copied';
            if (select) return { transactionId, automationObjTemplate: { source } };
            return { transactionId, setAlisaAutomation: true };
        }
    }
}

export default new CopyToFormSagas();
