export function setOptionLabel(option, getOptionLabel) {
    if (option) {
        if (typeof option === 'object') {
            return getOptionLabel ? getOptionLabel(option) : option;
        } else {
            return option;
        }
    }

    return '';
}

export function checkIfValueIncludes(option, inputTextStandardized) {
    return Object.values(option).some((value) => {
        if (typeof value === 'object' && value !== null) {
            return checkIfValueIncludes(value, inputTextStandardized);
        }
        const fieldValue = value ? value.toString().toLowerCase() : '';
        return fieldValue.includes(inputTextStandardized);
    });
}

export function checkIfValueStartsWith(option, inputTextStandardized) {
    return Object.values(option).some((value) => {
        if (typeof value === 'object' && value !== null) {
            return checkIfValueStartsWith(value, inputTextStandardized);
        }
        const fieldValue = value ? value.toString().toLowerCase() : '';
        return fieldValue.startsWith(inputTextStandardized);
    });
}
