import TakeSnapshot from 'components/UI/TakeSnapshot/TakeSnapshot';
import { axiosAiq } from 'config';
import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';
import { tableControlActions } from 'store/tableControlSlice/tableControlSlice';
import { tableConfig } from './VinCheckConfig';

export default class VinCheckControler {
    #memoizedHandlers;
    #reduxDispatch;
    #setSnapshotsState;
    #setProcessingActions;
    #maxNumberOfSnapshots;
    #snapshotsInitialState;
    #processingActionsInitialState;

    static snapshotsInitialState = { snapshots: {}, warnings: {}, outputs: [] };
    static processingActionsInitialState = {
        requestInProgress: { sendingSnapshots: false, sendingConfirmation: false },
        transactionId: 0,
        isTransactionProcessed: false,
        processedSnapshots: [],
        processedSnapshotsLoaded: false,
    };

    constructor(reduxDispatch, setSnapshotsState, setProcessingActions, maxNumberOfSnapshots) {
        this.#setSnapshotsState = setSnapshotsState;
        this.#setProcessingActions = setProcessingActions;
        this.#reduxDispatch = reduxDispatch;
        this.#maxNumberOfSnapshots = maxNumberOfSnapshots;
        this.#memoizedHandlers = {};
        this.#snapshotsInitialState = this.constructor.snapshotsInitialState;
        this.#processingActionsInitialState = this.constructor.processingActionsInitialState;
    }

    ///API

    get takeSnapshotHandler() {
        return this.#getMemoizedBindedFunction('takeSnapshotHandler', this.#takeSnapshotHandler);
    }
    get removeSnapshotHandler() {
        return this.#getMemoizedBindedFunction('removeSnapshotHandler', this.#removeSnapshotHandler);
    }

    get sendSnapshotsHandler() {
        return this.#getMemoizedBindedFunction('compareBOLToScannerHandler', this.#sendSnapshotsHandler);
    }

    get confirmDiscardProcessedSnapshotsHandler() {
        return this.#getMemoizedBindedFunction('confirmDiscardProcessedSnapshotsHandler', this.#confirmDiscardProcessedSnapshotsHandler);
    }

    get processedSnaphotLoadedHanler() {
        return this.#getMemoizedBindedFunction('processedSnaphotLoadedHanler', this.#setProcessedSnapshotsLoaded);
    }
    get fetchTableData() {
        return this.#getMemoizedBindedFunction('fetchTableData', this.#fetchTableData);
    }
    get removeTableData() {
        return this.#getMemoizedBindedFunction('removeTableData', this.#removeTableData);
    }
    get getProcessedSnapshots() {
        return this.#getMemoizedBindedFunction('getProcessedSnapshots', this.#getProcessedSnapshots);
    }

    //  helpers

    #getMemoizedBindedFunction(fnName, fn) {
        //return same handler instance to avoid unnecesary rerenders of child components using it
        const { [fnName]: handlerFn } = this.#memoizedHandlers;
        if (handlerFn) return handlerFn;

        this.#memoizedHandlers[fnName] = fn.bind(this);
        return this.#memoizedHandlers[fnName];
    }

    #waitMiliseconds(miliseconds) {
        return new Promise((res) => setTimeout(res, miliseconds));
    }

    //              ##### Snapshots actions  #####

    #setNewWarning(cameraId, { type, warning }) {
        this.#setSnapshotsState((state) => {
            const { warnings } = state;
            let newWarnings = { ...warnings, [cameraId]: { type, warning } };
            return { ...state, warnings: newWarnings };
        });
    }

    //takeSnaphot

    #takeSnapshotHandler(cameraId, videoOutputId) {
        return this.#takeSnapshot(cameraId, videoOutputId);
    }

    #takeSnapshot(cameraId, videoOutputId) {
        this.#setSnapshotsState((state) => {
            const { outputs } = state;
            const id = Date.now();
            const snapshot = (
                <TakeSnapshot key={id} id={id} videoElementId={videoOutputId} imageQuality={1} onSnapshot={this.#getOnSnapshotHandler(cameraId)} />
            );

            return { ...state, outputs: [...outputs, { snapshot, id }] };
        });
    }

    #getOnSnapshotHandler(cameraId) {
        return (snapshotId, snapshot) => {
            this.#setSnapshotsState((state) => {
                const { snapshots, outputs } = state;
                let newSnapshots;
                if (snapshots[cameraId]) {
                    newSnapshots = { ...snapshots, [cameraId]: [...snapshots[cameraId], { snapshot, id: snapshotId }] };
                } else {
                    newSnapshots = { ...snapshots, [cameraId]: [{ snapshot, id: snapshotId }] };
                }

                let newOutputs = outputs.filter(({ id }) => snapshotId !== id);

                const maxSnapshots = this.#maxNumberOfSnapshots - 1;
                if (snapshots[cameraId]?.length >= maxSnapshots) {
                    const warning = `Max number of snapshots reached!`;
                    const type = 'maxSnapshotReached';
                    this.#setNewWarning(cameraId, { type, warning });
                }

                return { ...state, snapshots: newSnapshots, outputs: newOutputs };
            });
        };
    }

    //removeSnaphot

    #removeSnapshotHandler(cameraId, snapshotId) {
        this.#setSnapshotsState((state) => {
            const { snapshots, warnings } = state;
            if (!snapshots?.[cameraId]) return state;
            const newSnapshots = this.#snapshotsStateOnSnapshotRemoved(cameraId, snapshotId, snapshots) || snapshots;
            const doesMaxSnapshotWarningExist = warnings?.[cameraId]?.type === 'maxSnapshotReached';
            const isNewSnapshotNumAllowed = newSnapshots?.[cameraId]?.length < this.#maxNumberOfSnapshots;
            const doesSnapshotObjExists = newSnapshots?.[cameraId];

            let newWarnings = warnings;
            if (doesMaxSnapshotWarningExist && (isNewSnapshotNumAllowed || !doesSnapshotObjExists)) {
                /* eslint-disable-next-line no-unused-vars */
                const { [cameraId]: toBeDeleted, ...rest } = warnings;
                newWarnings = rest;
            }
            return { ...state, snapshots: newSnapshots, warnings: newWarnings };
        });
    }

    #snapshotsStateOnSnapshotRemoved = (cameraId, snapshotId, snapshots) => {
        let snapshotsToSet;
        let hasMultipleSnapshots;
        switch (true) {
            case snapshotId === 'last':
                hasMultipleSnapshots = snapshots[cameraId].length > 1;
                if (hasMultipleSnapshots) {
                    snapshotsToSet = { ...snapshots, [cameraId]: snapshots[cameraId].slice(0, -1) };
                } else {
                    /* eslint-disable-next-line no-unused-vars */
                    const { [cameraId]: toBeRemoved, ...newSnaphots } = snapshots;
                    snapshotsToSet = newSnaphots;
                }
                break;
            case snapshotId === 'all':
                {
                    /* eslint-disable-next-line no-unused-vars */
                    const { [cameraId]: _, ...newSnaphots } = snapshots;
                    snapshotsToSet = newSnaphots;
                }
                break;

            default:
                snapshotsToSet = { ...snapshots, [cameraId]: snapshots[cameraId].filter(({ id }) => snapshotId !== id) };
        }

        return snapshotsToSet;
    };

    //              ##### Processing snapshot  #####

    #setProcessingActionsPropertyValue(firstLevelObjName, propertyName, value) {
        this.#setProcessingActions((state) => {
            if (firstLevelObjName) {
                const { [firstLevelObjName]: firstLevelObj } = state;

                let newFirstLevelObj;
                if (propertyName) newFirstLevelObj = { ...firstLevelObj, [propertyName]: value };
                else newFirstLevelObj = [...firstLevelObj, ...value];

                return { ...state, [firstLevelObjName]: newFirstLevelObj };
            } else {
                return { ...state, [propertyName]: value };
            }
        });
    }

    #setProcessedSnapshotsLoaded() {
        this.#setProcessingActionsPropertyValue('processedSnapshotsLoaded', true);
    }

    #showUserNotification(message, variant = 'error') {
        this.#reduxDispatch(
            snackbarActions.enqueueSnackbar({
                message: `${message.toUpperCase()}`,
                options: {
                    variant,
                },
            })
        );
    }

    //  compare BOL to scanner
    async #sendSnapshotsHandler(locationId, activeStreams, snapshots) {
        this.#setProcessingActionsPropertyValue('requestInProgress', 'sendingSnapshots', true);
        try {
            const dataToSend = this.#prepareSnapshotsForSending(locationId, activeStreams, snapshots);
            const { data } = await axiosAiq.post('/transactions/vin', dataToSend);
            const transactionId = data?.id;
            transactionId && this.#setProcessingActionsPropertyValue('', 'transactionId', transactionId);
            this.#fetchTableData({ tableId: 'vinCheck', locationId, pageNum: 0, append: true });
        } catch (err) {
            this.#showUserNotification(`Error sending snapshots to Pigeon: ${err.message}`);
            this.#setProcessingActionsPropertyValue('requestInProgress', 'sendingSnapshots', false);
        }
    }

    async #getProcessedSnapshots(vinTransactionId, locationId) {
        try {
            const { data } = await axiosAiq(`/transactions/vin/${vinTransactionId}`);
            const processedSnapshots = data?.['encoded_processed_image'] || [];
            this.#setProcessingActionsPropertyValue('processedSnapshots', '', processedSnapshots);
            this.#fetchTableData({ tableId: 'vinCheck', locationId, pageNum: 0, append: true });
        } catch (err) {
            this.#showUserNotification(err.message);
        }
        this.#setProcessingActionsPropertyValue('requestInProgress', 'sendingSnapshots', false);
    }

    #prepareSnapshotsForSending(locationId, activeStreams, snapshots) {
        try {
            let isDataValid = true;
            const cameraSnapshots = Object.keys(snapshots).reduce((cameraSnapshots, key) => {
                const cameraId = +key;
                const encodedSnapshotImages = snapshots?.[cameraId]?.map(({ snapshot }) => snapshot.split(',').at(1)) || [];
                const cameraInfo = activeStreams?.find(({ camId }) => camId === cameraId) || {};

                const { type: cameraType, name: cameraName } = cameraInfo;

                if (!cameraType || !cameraName || !encodedSnapshotImages?.length) isDataValid = false;

                cameraSnapshots.push({
                    camera_id: cameraId,
                    camera_name: cameraName,
                    camera_type: cameraType,
                    encoded_snapshot_images: encodedSnapshotImages,
                });
                return cameraSnapshots;
            }, []);

            if (!isDataValid || !locationId) throw new Error('Invalid data');

            return { location_id: locationId, camera_snapshots: cameraSnapshots };
        } catch (err) {
            throw new Error(err.message);
        }
    }

    async #confirmDiscardProcessedSnapshotsHandler({ confirmed, vinTransactionId, locationId, pageNum = 0, clearCurrentTransaction = true }) {
        try {
            this.#setProcessingActionsPropertyValue('requestInProgress', 'sendingConfirmation', true);
            await this.#waitMiliseconds(1000);
            await axiosAiq.put(`/transactions/vin/${vinTransactionId}?confirmed=${confirmed}`);
            confirmed && this.#showUserNotification('Succesfuly confirmed processed snapshots', 'success');
            !confirmed && this.#showUserNotification('Succesfuly discarded processed snapshots', 'warning');
            clearCurrentTransaction && (await this.#clearCurrentTransaction());
            this.#setProcessingActionsPropertyValue('requestInProgress', 'sendingConfirmation', false);
            this.#fetchTableData({ tableId: 'vinCheck', locationId, pageNum, append: true });
        } catch (err) {
            this.#showUserNotification(err.message);
        }
    }

    async #clearCurrentTransaction() {
        this.#setProcessingActionsPropertyValue(null, 'processedSnapshotsLoaded', false);
        await this.#waitMiliseconds(1000);
        this.#setProcessingActions(this.#processingActionsInitialState);
        this.#setSnapshotsState(this.#snapshotsInitialState);
        await this.#waitMiliseconds(20);

        window.scrollTo({
            top: 45,
            left: 0,
            behavior: 'smooth',
        });
    }

    //table

    #fetchTableData({ tableId, locationId, pageNum, size = tableConfig.rowsPerPage, append = false }) {
        this.#reduxDispatch(
            tableControlActions.fetchTableDataStart({
                tableId: tableId,
                pageNum,
                size,
                urlPath: `transactions/vin/all?locationId=${locationId}&page=${pageNum}&size=${size}`,
                fetchColumnsConfig: false,
                append,
            })
        );
    }

    #removeTableData(tableId) {
        this.#reduxDispatch(tableControlActions.removeTable(tableId));
    }
}
