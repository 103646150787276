import styles from './CameraStreamBugButton.module.css';
import { Tooltip } from '@mui/material';
import bugImage from 'assets/bug.png';

function BugIcon({ clickHandler, classes }) {
    return (
        <Tooltip title="Report Issue">
            <img
                onClick={() => clickHandler(true)}
                className={`${styles['tc-cs-footer--camera-icon']} ${styles[classes]}`}
                alt="report"
                src={bugImage}
            />
        </Tooltip>
    );
}

export default BugIcon;
