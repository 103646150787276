import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableIdFetchingDataInProgress: '',
    fetchingTableDataError: null,

    tableConfigFetchingInProgress: '',
    fetchingTableConfigError: null,

    tableConfig: {},
    allTablesData: {},
};

const tableControlSlice = createSlice({
    name: 'tableControls',
    initialState,
    reducers: {
        fetchTableDataStart(state, { payload: { tableId } }) {
            state.tableIdFetchingDataInProgress = tableId;
            state.fetchingTableDataError = null;
        },
        fetchTableDataFailed(state, { payload: error }) {
            state.tableIdFetchingDataInProgress = '';
            state.fetchingTableDataError = error;
        },
        fetchTableDataSuccess(state, { payload: { data, tableId, pageNum, append } }) {
            state.tableIdFetchingDataInProgress = '';
            const allTablesData = state.allTablesData;

            if (append) {
                allTablesData[tableId][pageNum] = { data };
            } else {
                allTablesData[tableId] = {
                    [pageNum]: { data },
                };
            }
        },

        fetchColumnsConfigStart(state, { payload: configName }) {
            state.tableConfigFetchingInProgress = configName;
            state.fetchingTableConfigError = null;
        },
        fetchColumnsConfigSuccess(state, { payload: tableConfig }) {
            state.tableConfig[tableConfig.name] = tableConfig.columns;
            state.tableConfigFetchingInProgress = '';
        },
        fetchColumnsConfigFailed(state, { payload: error }) {
            state.tableConfigFetchingInProgress = '';
            state.fetchingTableConfigError = error;
        },

        setAllTablesData(state, { payload: newAllTablesData }) {
            state.allTablesData = newAllTablesData;
        },

        resetTableControlSlice(state) {
            state.allTablesData = {};
            state.tableConfig = {};
        },

        removeTable(state, { payload: tableId }) {
            // eslint-disable-next-line no-unused-vars
            const { [tableId]: toBeDeleted, ...rest } = state.allTablesData;
            state.allTablesData = rest;
        },
    },
});

export const tableControlReducer = tableControlSlice.reducer;
export const tableControlActions = tableControlSlice.actions;
