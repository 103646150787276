import { createSlice } from '@reduxjs/toolkit';

const initialState = { snackbars: [], snackbarId: 0, displayed: [] };

const snackbarsSlice = createSlice({
    name: 'snackbars',
    initialState,
    reducers: {
        enqueueSnackbar(state, { payload: notification }) {
            if (!state.snackbars.length && !state.displayed.length) state.snackbarId = 0;

            ++state.snackbarId;
            state.snackbars.push({ key: state.snackbarId, ...notification });
        },

        removeSnackbar(state, { payload: key }) {
            state.snackbars = state.snackbars.filter((snackbar) => snackbar.key !== key);

            state.displayed = state.displayed.filter((displayedKey) => displayedKey !== key);
        },

        updateDisplayedSnackbars(state, { payload: key }) {
            state.displayed.push(key);
        },
    },
});

export const snackbarsReducer = snackbarsSlice.reducer;
export const snackbarActions = snackbarsSlice.actions;
