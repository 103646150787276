import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Note from 'components/UI/Alerts/Alert/Note/Note';
import styles from './Option.module.css';

const Alert = ({ note, name, mode, accessPeriod, color }) => {
    const backgroundColor = color || '#DCDCDC';
    return (
        <Stack direction="row" spacing={1} padding={1}>
            <div
                className={styles['alert']}
                style={{
                    backgroundColor: backgroundColor,
                }}
            >
                <p>{name[0]}</p>
            </div>

            <Note mode={mode} name={name} note={note} accessPeriod={accessPeriod} />
        </Stack>
    );
};

export default Alert;

Alert.propTypes = {
    note: PropTypes.string,
    name: PropTypes.string,
    mode: PropTypes.string,
    accessPeriod: PropTypes.object,
    color: PropTypes.string,
};
