import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import styles from './NavigationDrawer.module.css';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { routes } from './navigationRoutes';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import DrawerListItem, { FoldableDrawerListItem } from './DrawerListItem/DrawerListItem';
import { GENERAL } from 'config';
import { selectActiveTab } from 'store/transactionsSlice/transactionsSelectors';
import UserNavigation from './userNavigation/UserNavigation';
import { useState } from 'react';
const ssoAddress = GENERAL.ADDRESSES.SSO;

function MainNavigation({ user }) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawerOpenHandler = () => setDrawerOpen((state) => !state);
    const homeButtonClickHandler = () => window.open(ssoAddress);
    const isTransactionPageActive = !!useSelector(selectActiveTab);

    const availableRoutes = getAvailableRoutes(routes, user, toggleDrawerOpenHandler);

    return (
        <div className={styles.navigation}>
            <div className={styles['menu']}>
                <IconButton onClick={toggleDrawerOpenHandler}>
                    <MenuIcon sx={{ color: 'white' }} />
                </IconButton>
            </div>

            <p className={isTransactionPageActive ? styles['reaload-warning'] : styles.hidden}>Refreshing page will reload all streams</p>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawerOpenHandler}>
                <List sx={{ paddingTop: 'var(--header__height--main)' }}>
                    <DrawerListItem listItemComponent={'div'} onClick={homeButtonClickHandler} text="Birdseye Home" Icon={HomeIcon} />
                    <Divider />
                    {availableRoutes}
                </List>
            </Drawer>
            <UserNavigation user={user} isTransactionPageActive={isTransactionPageActive} />
        </div>
    );
}

export default MainNavigation;

//TODO  disabling eslint warining for now, export function to separate file
// eslint-disable-next-line react-refresh/only-export-components
export function getAvailableRoutes(routes, user, onClick) {
    return routes.reduce((availableRoutes, { text, path, allowedRoles, items }) => {
        if (!checkIsAllowed(user, allowedRoles)) return availableRoutes;

        if (items) {
            const routeItem = <FoldableDrawerListItem key={text} text={text} childListItems={items} user={user} onClick={onClick} />;
            availableRoutes.push(routeItem);
            return availableRoutes;
        }

        const routeItem = <DrawerListItem key={text} listItemComponent={Link} listItemAttributes={{ to: path }} onClick={onClick} text={text} />;
        availableRoutes.push(routeItem);
        return availableRoutes;
    }, []);
}

function checkIsAllowed(user, allowedRoles) {
    if (!allowedRoles) return true;
    let allowedAccess = false;
    if (user) {
        allowedAccess = allowedRoles.reduce((allowedAccess, allowedRole) => {
            !allowedAccess && user.roles.includes(allowedRole) && (allowedAccess = true);
            return allowedAccess;
        }, false);
    }

    return allowedAccess;
}
