import { axiosAiq } from 'config';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';

export class StreamManager {
    #dispatch;
    #reduxDispatch;
    #getStreamsUrl;
    #memoizedHandlers = {};

    #liveStreamTabId;
    #selectedLocation;
    #liveStreamsPerPage;
    #activeStreamType;

    static config = {
        sampleStreamsTabId: 'streamTest|sampleStreams',
        sampleStreamType: 'Sample streams',
        liveStreamType: 'Live streams',
        replayPOC: 'Replay PoC',
        streamTypeLookup: { dataSource: ['Sample streams', 'Live streams', 'Replay PoC'] },
    };

    #tabIdByStreamType = {
        'Sample streams': 'streamTest|sampleStreams',
        'Live streams': '',
    };

    constructor(dispatch, reduxDispatch, liveStreamTabId, defaultStreamType, defaultStreamsPerPage, getStreamsUrl) {
        this.#liveStreamTabId = liveStreamTabId;
        this.#tabIdByStreamType['Live streams'] = liveStreamTabId;
        this.#dispatch = dispatch;
        this.#reduxDispatch = reduxDispatch;
        this.defaultStreamType = defaultStreamType;
        this.#getStreamsUrl = getStreamsUrl;
        this.#liveStreamsPerPage = defaultStreamsPerPage;
        this.#activeStreamType = defaultStreamType;
    }

    get setSelectedLocation() {
        return this.#getMemoizedBindedFunction('setSelectedLocation', this.#setSelectedLocation);
    }

    get setLiveStreamsPerPage() {
        return this.#getMemoizedBindedFunction('setLiveStreamsPerPage', this.#setLiveStreamsPerPage);
    }

    get fetchLocationCameras() {
        return this.#getMemoizedBindedFunction('fetchLocationCameras', this.#fetchLocationCameras);
    }

    get getStreamsForPage() {
        return this.#getMemoizedBindedFunction('getStreamsForPage', this.#getStreamsForPage);
    }
    get setStreamType() {
        return this.#getMemoizedBindedFunction('setStreamType', this.#setStreamType);
    }
    get setSampleStreams() {
        return this.#getMemoizedBindedFunction('setSampleStreams', this.#setSampleStreams);
    }
    get enableStreamAudio() {
        return this.#getMemoizedBindedFunction('enableStreamAudio', this.#enableStreamAudio);
    }
    get handleRestartVideoStream() {
        return this.#getMemoizedBindedFunction('handleRestartVideoStream', this.#handleRestartVideoStream);
    }

    get addVideoFailedToLoad() {
        return this.#getMemoizedBindedFunction('addVideoFailedToLoad', this.#addVideoFailedToLoad);
    }

    #getMemoizedBindedFunction(fnName, fn) {
        //return same binded handler instance to avoid unnecesary rerenders of child components using it
        const { [fnName]: handlerFn } = this.#memoizedHandlers;
        if (handlerFn) return handlerFn;

        this.#memoizedHandlers[fnName] = fn.bind(this);
        return this.#memoizedHandlers[fnName];
    }

    /// fetching streams
    async #fetchLocationCameras() {
        const pageNum = 0;
        const liveStreamsPerPage = this.#liveStreamsPerPage;
        const locationId = this.#selectedLocation?.id;
        try {
            this.#setFetchingStreamsError('');
            this.#setFetchingStreamsInProgress(true);
            if (this.#selectedLocation) {
                const fetchURL = this.#getStreamsUrl(locationId, pageNum, liveStreamsPerPage);
                const data = await this.#fetch(fetchURL);
                if (data.length) {
                    await this.#handleNewStreams({ data, liveStreamsPerPage, pageNum, reset: true });
                } else {
                    this.#setFetchingStreamsError('No streams for selected location');
                }
            } else {
                this.#setFetchingStreamsError('Please select location');
            }
        } catch (err) {
            this.#showUserNotification(`Error fetching streams ${err.message}`);
        }
        this.#setFetchingStreamsInProgress(false);
    }

    async #getStreamsForPage({ pageNum, tabsAudio, previous }) {
        const liveStreamsPerPage = this.#liveStreamsPerPage;
        const locationId = this.#selectedLocation?.id;
        try {
            const soundOnStreamId = tabsAudio[this.#liveStreamTabId].soundOnStreamId;
            if (soundOnStreamId) kurentoManager.enableAudio(soundOnStreamId);
            if (previous) {
                await kurentoManager.closeTab(this.#liveStreamTabId);
                this.#setLiveStreamsPageActive({ pageNum: --pageNum, lastPage: false });
            } else {
                const fetchURL = this.#getStreamsUrl(locationId, ++pageNum, liveStreamsPerPage);
                const data = await this.#fetch(fetchURL);
                if (data.length) await this.#handleNewStreams({ data, liveStreamsPerPage, pageNum });
                else this.#dispatch({ type: 'setLastPage' });
            }
        } catch (err) {
            this.#showUserNotification(`Error fetching streams ${err.message}`);
        }
    }

    async fetchSampleStreams() {
        this.#setFetchingSampleStreamsInProgress(true);
        try {
            const response = await axiosAiq.get(`/test-streams?connect=true`);
            const { data } = response;
            this.#setSampleStreams(data);
        } catch {
            this.#showUserNotification('Error fething sample streams');
            return [];
        }
        this.#setFetchingSampleStreamsInProgress(false);
    }

    async #fetch(url) {
        try {
            const { data } = await axiosAiq(url);
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    ///  handle fetched streams

    async #handleNewStreams({ data, liveStreamsPerPage, pageNum, reset }) {
        const lastPage = data.length < liveStreamsPerPage;

        await this.#addLiveStreams({ data, pageNum, lastPage, reset });
        this.#setLiveStreamsPageActive({ pageNum, lastPage, reset });
    }

    async #addLiveStreams({ data, pageNum, reset }) {
        await kurentoManager.closeTab(this.#liveStreamTabId);
        const dispatchActionObj = {
            type: 'addLiveStreams',
            payload: {
                data,
                pageNum,
                reset,
            },
        };
        if (reset) {
            this.#dispatch({ type: 'resetLiveStreams' });
            this.#queueDispatch(dispatchActionObj);
            return;
        }
        this.#dispatch(dispatchActionObj);
    }

    #setLiveStreamsPageActive({ pageNum, lastPage, reset }) {
        const dispatchActionObj = {
            type: 'setLiveStream',
            payload: { pageNum, lastPage },
        };

        if (reset) {
            this.#queueDispatch(dispatchActionObj);
            return;
        }
        this.#dispatch(dispatchActionObj);
    }

    #queueDispatch(payload) {
        this.#dispatch({ type: 'addToDispatchQueue', payload });
    }

    #setSampleStreams(payload) {
        this.#dispatch({ type: 'setSampleStreams', payload });
    }

    /// Stream controls
    #enableStreamAudio(camVideoId, tabId, muteTab) {
        this.#dispatch({ type: 'enableAudio', payload: { camVideoId, tabId, muteTab } });
    }

    #handleRestartVideoStream(camId, tabId) {
        const camVideoId = kurentoManager.getVideoElementId(tabId, camId);
        this.#dispatch({ type: 'removeVideoFailedToLoad', payload: camVideoId });
    }

    ///

    #setSelectedLocation(payload) {
        this.#selectedLocation = payload;
        this.#dispatch({ type: 'setSelectedLocation', payload });
    }

    #setLiveStreamsPerPage(liveStreamsPerPage) {
        this.#liveStreamsPerPage = liveStreamsPerPage;
    }

    #setStreamType(_, payload) {
        this.#dispatch({ type: 'setStreamsType', payload });
    }

    #addVideoFailedToLoad(e) {
        this.#dispatch({
            type: 'addVideoFailedToLoad',
            payload: e.target.id,
        });
    }

    #setFetchingStreamsError(payload) {
        this.#dispatch({
            type: 'setFetchingStreamsError',
            payload,
        });
    }

    #setFetchingStreamsInProgress(payload) {
        this.#dispatch({ type: 'setFetchingStreamsInProgress', payload });
    }
    #setFetchingSampleStreamsInProgress(payload) {
        this.#dispatch({ type: 'setFetchingSampleStreamsInProgress', payload });
    }

    #showUserNotification(message, variant = 'error') {
        this.#reduxDispatch(
            snackbarActions.enqueueSnackbar({
                message: `${message.toUpperCase()}`,
                options: {
                    variant,
                },
            })
        );
    }

    onStreamTypeChange(streamType) {
        this.#dispatch({ type: 'resetLiveStreams' });
        const activeStreamType = this.#activeStreamType;
        const tabId = this.#tabIdByStreamType[activeStreamType];
        kurentoManager.closeTab(tabId);
        this.#setSelectedLocation(null);
        this.#activeStreamType = streamType;
    }

    static getInitialState(liveStreamTabId, defaultStreamType, defaultStreamsPerPage) {
        return {
            page: {
                pageNum: 0,
                lastPage: false,
            },
            tabsAudio: {
                [liveStreamTabId]: {
                    isTabMuted: true,
                    soundOnStreamId: '',
                    availableAudioStreams: [],
                },
            },
            dispatchQueue: [],
            fetchingStreams: { inProgress: false, error: '' },
            fetchingSampleStreamsInProgress: false,
            streamType: defaultStreamType,
            liveStreamsPerPage: defaultStreamsPerPage,
            selectedLocation: null,
            videosFailedToLoad: [],
            liveStreams: {},
            sampleStreams: [],
        };
    }
}
