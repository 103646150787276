import { kurentoManager } from './KurentoManager';

import { TRANSACTION_PAGE_TABS } from 'config';
import { WebRTCPlayer } from '@eyevinn/webrtc-player';
import { kurentoConfig } from './KurentoConfig';
import { AIQAdapter } from './WebRtcPlayerAdapter/AIQAdapter.js';
const videoWallTransactioId = TRANSACTION_PAGE_TABS.VIDEO_WALL_TAB.DUMMY_TRANSACTION_ID;

export default class MediaMtxSource {
    #webRtcPlayer = null;
    #mediaStreamPromise = null;
    #resolveMediaStreamPromise = null;
    isActivated = false;
    #webRtcUrl;
    cameraId;

    #freezeEventTrasholdMilis = 6000;
    #freezeTimeoutId;

    #initialState = { info: 'Initiating...', error: '', freezed: false, isLoading: true };
    streamState = this.#initialState;

    #mediaStream;
    #videoOutputs = new Map();
    #fullHeader;

    constructor(camConfig) {
        this.cameraId = camConfig.camId;
        let url = camConfig.videoData.url.replace('rtsp', 'https').replace(':8554/', ':8889/');
        if (url.at(-1) === '/') url = url.slice(0, -1);
        this.#webRtcUrl = url;
        this.#createWebRtcPlayer();
        this.#setLogHeaders();
    }

    #createWebRtcPlayer() {
        this.#mediaStreamPromise = new Promise((res) => {
            this.#resolveMediaStreamPromise = res;
        });

        this.#webRtcPlayer = new WebRTCPlayer({
            video: { srcObject: null, load() {} },
            iceServers: kurentoConfig.options.iceServers,
            type: 'custom',
            statsTypeFilter: '^candidate-*|^inbound-rtp',
            adapterFactory: (peer, channelUrl) => new AIQAdapter(peer, channelUrl, this.#handleWebRtcPlayerError),
            debug: true,
        });

        this.#webRtcPlayer.onTrack = () => {
            this.#mediaStream = this.#webRtcPlayer?.peer?.getRemoteStreams()[0];
            this.#resolveMediaStreamPromise(this.#mediaStream);
            this.#setMediaStream(this.#mediaStream);
        };
        window.player = this.#webRtcPlayer;
        this.#webRtcPlayer.onError = this.#handleWebRtcPlayerError;
    }

    async activateStreamType(streamType) {
        try {
            if (this.isActivated) return;
            this.isActivated = true;
            this.#log(`activateStreamType(): activating streamType=${streamType}`);
            await this.#webRtcPlayer.load(new URL(this.#webRtcUrl + '/whep'));
        } catch (err) {
            this.#handle(err, 'activateStreamType()', false);
        }
    }

    async release(clearVideoOutputs) {
        try {
            this.streamState = this.#initialState;
            if (clearVideoOutputs) this.#videoOutputs = new Map();
            if (this.isActivated) await this.#webRtcPlayer.destroy();
            this.#log(`release(): released WebRTCPlayer`);
            this.#webRtcPlayer = null;
            this.#mediaStreamPromise = null;
            this.#resolveMediaStreamPromise = null;
            this.isActivated = false;
        } catch (err) {
            this.#handle(err, 'release()', false);
        }
    }

    async restart() {
        try {
            this.#dispatchEvent('videoStreamState', { info: 'Stream refresh in progress...', error: '', freeze: '' });
            this.#setMediaStreamOnVideoOutputs(null);
            await this.release(false);
            this.#createWebRtcPlayer();
            await this.activateStreamType();
        } catch (err) {
            this.#handle(err, 'restart()', false);
        }
    }

    async hardRefresh() {
        await this.restart();
    }

    //placeholders
    playerByStreamType() {}
    async addCameraStreamConfiguration() {}
    async deactivate() {}
    get webRtcEndpointId() {
        return undefined; //explicitly retutning undefined to switch off linter warning
    }
    ///////

    #setMediaStream = (mediaStream) => {
        this.#mediaStream = mediaStream;
        if (mediaStream) {
            const soundActiveOnCamera = kurentoManager.getSourceWithAudio()?.cameraId;
            if (soundActiveOnCamera !== this.cameraId) this.audio(false);
            this.#setMediaStreamListeners(mediaStream);
            // this is called once on CameraStreamConnection create/update
        }
        this.#setMediaStreamOnVideoOutputs(mediaStream);
    };

    #setMediaStreamListeners(mediaStream) {
        const videoTrack = mediaStream.getVideoTracks().at(0);
        videoTrack.onunmute = this.#onMediaStreamTrackUnmute;
        videoTrack.onmute = this.#onMediaStreamTrackMute;
    }

    #onMediaStreamTrackMute = () => {
        this.#log('Video track STOPPED');
        if (this.#freezeTimeoutId) return;
        this.#log(`Scheduling Freezed event in ${this.#freezeEventTrasholdMilis} ms`);
        this.#freezeTimeoutId = setTimeout(() => {
            this.#log('Dispatching Freezed event to video element');
            this.#dispatchEvent('videoStreamState', {
                freezed: true,
            });
            this.#freezeTimeoutId = 0;
        }, this.#freezeEventTrasholdMilis);
    };

    #onMediaStreamTrackUnmute = () => {
        this.#log('Video track ACTIVE');
        if (this.#freezeTimeoutId) {
            this.#log('Removing scheduled Freezed event');
            clearTimeout(this.#freezeTimeoutId);
            this.#freezeTimeoutId = 0;
            return;
        }
        this.#dispatchEvent('videoStreamState', {
            freezed: false,
        });
    };

    ///Events

    #dispatchEvent = (type, detail, videoOutputId) => {
        if (type === 'videoStreamState') {
            const event = new CustomEvent(type, {
                detail: {
                    ...this.#updateStreamState(detail),
                    updateSourceState: (detail) => this.#dispatchEvent('videoStreamState', detail),
                },
            });

            let dispatching;

            const videoOutputIds = videoOutputId ? [videoOutputId] : this.#getVideoOutputIds();

            if (videoOutputIds.length) {
                this.#getVideoOutputIds().forEach((videoOutputId) => {
                    const videoOutput = document.getElementById(videoOutputId);
                    if (!videoOutput) return;
                    videoOutput.dispatchEvent(event);
                    if (type === 'videoStreamState')
                        dispatching = Object.keys(this.streamState)
                            .map((key) => ` [${key}:${this.streamState[key]}]`)
                            .toString();

                    this.#log(`#dispatchEvent(): Target "${videoOutputId}" type="${type}" detail:{${dispatching}}`);
                });
            }
        }
    };

    #updateStreamState(detail) {
        return (this.streamState = { ...(this.streamState && { ...this.streamState }), ...detail });
    }

    ///Managing video outputs
    addVideoOutput(videoOutputId, transactionId) {
        if (this.#videoOutputs.get(videoOutputId)) return;
        this.#videoOutputs.set(videoOutputId, transactionId);
        this.#setLogHeaders();
        this.#log(`addVideoOutput(): Added new video output: ${videoOutputId}`);
        this.#dispatchEvent('videoStreamState', this.streamState, videoOutputId);
        if (this.#mediaStream) this.#setMediaStreamOnVideoOutput(videoOutputId, this.#mediaStream);
        else {
            this.#mediaStreamPromise
                ?.then((mediaStream) => this.#setMediaStreamOnVideoOutput(videoOutputId, mediaStream))
                .catch((err) => this.#handle(err, 'audio()', false));
        }
    }

    async removeVideoOutput(videoOutputId) {
        try {
            this.#setMediaStreamOnVideoOutput(videoOutputId, null);
            this.#log(`Removing video output: ${videoOutputId}`);
            this.#videoOutputs.delete(videoOutputId);
            this.#setLogHeaders();
        } catch (err) {
            this.#handle(err, 'removeVideoOutput', false);
        }
    }

    async makeExclusiveForVideoOutput(videoOutputId) {
        for (let vOutputId of [...this.#videoOutputs.keys()]) {
            if (vOutputId !== videoOutputId) await this.removeVideoOutput(vOutputId);
        }
    }

    #getVideoOutputIds() {
        return Array.from(this.#videoOutputs.keys());
    }

    getTransactionIds() {
        return [...new Set(Array.from(this.#videoOutputs.values()))];
    }

    isActiveOnVideoWall() {
        return this.getTransactionIds().includes(videoWallTransactioId);
    }

    #getTransactionTagValue() {
        const transactionIds = this.getTransactionIds();
        if (transactionIds.length) return transactionIds.length === 1 ? transactionIds[0] : `[${transactionIds.join(', ')}]`;
        else return 'noActiveTransaction';
    }

    #setLogHeaders() {
        const videoOutputIdsString = this.#getVideoOutputIds().join(', ');
        this.#fullHeader = `[MediaMtxSource cameraId=${this.cameraId}, ${
            videoOutputIdsString ? `videoOutputIds=[${videoOutputIdsString}], ` : ''
        }transactionId=${this.#getTransactionTagValue()}]`;
    }

    hasVideoOutputId(videoOutputId) {
        return this.#getVideoOutputIds().includes(videoOutputId);
    }

    isExclusivelyUsedForTransactionId(transactionId) {
        const transactions = this.getTransactionIds();
        if (transactions.length > 1) return false;
        return transactions[0] === transactionId;
    }

    ////Main source controls
    audio(enabled) {
        if (!this.#mediaStream)
            this.#mediaStreamPromise?.then((mediaStream) => this.#setAudio(mediaStream, enabled)).catch((err) => this.#handle(err, 'audio()', false));
        else this.#setAudio(this.#mediaStream, enabled);
    }

    #setAudio = (mediaStream, enabled) => {
        this.#log(`#setAudio(): Setting audio track enabled = ${enabled}`);
        if (!mediaStream.getAudioTracks().length) {
            this.#log(`#setAudio(): Audio track not available, exititng`);
            return;
        }
        mediaStream.getAudioTracks().at(0).enabled = enabled;
    };

    ///////////

    // Video/audio status

    #setMediaStreamOnVideoOutputs(mediaStream) {
        const videoOutputIds = this.#getVideoOutputIds();
        videoOutputIds.forEach((videoOutputId) => {
            this.#setMediaStreamOnVideoOutput(videoOutputId, mediaStream);
        });
    }

    #setMediaStreamOnVideoOutput(videoOutputId, mediaStream) {
        const videoOutput = document.getElementById(videoOutputId);
        if (videoOutput) {
            videoOutput.srcObject = mediaStream;
            this.#log(
                `#setMediaStreamOnVideoOutput(): ${mediaStream ? 'Attached' : 'Removed'} mediaStream ${
                    mediaStream ? 'to' : 'from'
                } videoOutputId=${videoOutputId}`
            );
        }
    }

    // Debugging information

    getInfo() {
        let info = `<strong>CameraId: </strong> ${this.cameraId}`;
        info += `<br/><br/><strong >URL: </strong> <p style="word-wrap:break-word">${this.#webRtcUrl}</p> <br/><br/>`;

        info += '<strong>Media:</strong>&nbsp;';
        const mediaStream = this.#mediaStream;
        if (!mediaStream) {
            info += 'not available';
        } else {
            const [videoTrack] = mediaStream.getVideoTracks();
            const videoTrackSettings = videoTrack.getSettings();
            info += `\n[id=${videoTrack.id},\n`;
            info += `\treadyState=${videoTrack.readyState}, frameRate=${videoTrackSettings.frameRate},\n`;
            info += `\tfacingMode=${videoTrackSettings.facingMode}, h=${videoTrackSettings.height}, w=${videoTrackSettings.width}]\n`;

            const [audioTrack] = mediaStream.getAudioTracks();
            if (!audioTrack) return info;

            info += '<br/><br/><strong>Audio:</strong>';

            const audioTrackSettings = audioTrack.getSettings();
            info += `\n[id=${audioTrack.id},\n`;
            info += `\treadyState=${audioTrack.readyState}, volume=${audioTrackSettings.volume},\n`;
            info += `\tchannelCount=${audioTrackSettings.channelCount}, sampleRate=${audioTrackSettings.sampleRate}]`;
        }
        return info;
    }

    #log(message, mode = 'log') {
        console[mode](`${this.#fullHeader} ${message}`);
    }

    //ERROR HANDLERS

    #handleWebRtcPlayerError = (err) => {
        if (err) {
            this.#log(`#webRtcPlayer.onError():${err}`, 'error');
            this.#handlePlaybackError({ message: 'WebRTC Player error' });
        }
    };

    #handle(err, message, rethrow = true) {
        if (err) {
            this.#log(`${message}: ${err?.message || err}`, 'error');
            this.#handlePlaybackError(err, message);
            if (rethrow) throw new Error(err?.message || err);
        }
    }

    #handlePlaybackError(err) {
        this.#dispatchEvent('videoStreamState', { info: '', error: err.message, isLoading: false });
    }

    // String representation

    toString() {
        return this.#fullHeader;
    }
}
