class KurentoConfig {
    #wsUri;
    #options;
    #testVideos;

    get wsUri() {
        return this.#wsUri;
    }

    get options() {
        return this.#options;
    }

    get testVideos() {
        return this.#testVideos;
    }

    set configuration(configObj) {
        const { wsUri, options, testVideos } = configObj;
        this.#wsUri = wsUri;
        this.#options = options;
        this.#testVideos = testVideos;
    }

    set wsUri(wsUri) {
        this.#wsUri = wsUri;
    }

    set options(options) {
        this.#options = options;
    }

    set testVideos(testVideos) {
        this.#testVideos = testVideos;
    }

    removeConfig() {
        this.configuration = {};
    }

    toString() {
        return `[KurentoConfig host=${this.#wsUri}, options= ${JSON.stringify(this.#options)}]`;
    }
}
export const kurentoConfig = new KurentoConfig();

if (import.meta.env.VITE_ENV !== 'production') window.kurentoConfig = kurentoConfig;
