export const fieldMappings = {
    'gate.location.fullName': 'locationName',
    gateDirection: 'direction',
    'person.fullName': 'driverName',
    'person.companyName': 'companyName',
    'vehicle.unitNumber': 'truckNumber',
    'vehicle.licensePlateNumber': 'licencePlate',
    'trailer.trailerNumber': 'trailerNumber',
    'trailer.trailerNumberTwo': 'trailerTwoNumber',
    'cargo.moveNumber': 'moveNumber',
    description: 'description',
};

export const bisonAnnouncementsColumnsConfig = [
    {
        caption: 'General',
        columns: [
            {
                caption: 'Activity date',
                dataField: 'activityDate',
                dataType: 'datetime',
                editable: false,
                format: 'dd.MM.yyyy. HH:mm:ss',
            },
            {
                caption: 'Scheduled entry date',
                dataField: 'scheduledEntryDate',
                dataType: 'datetime',
                editable: false,
                format: 'dd.MM.yyyy. HH:mm:ss',
            },
            {
                caption: 'Direction',
                dataField: 'direction',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Location',
                dataField: 'locationName',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Person',
        columns: [
            {
                caption: 'Full name',
                dataField: 'driverName',
                dataType: 'string',
                editable: false,
            },

            {
                caption: 'Company name',
                dataField: 'companyName',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Vehicle',
        columns: [
            {
                caption: 'License Plate #',
                dataField: 'licencePlate',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Unit #',
                dataField: 'truckNumber',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Trailer',
        columns: [
            {
                caption: 'Trailer #',
                dataField: 'trailerNumber',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Trailer 2 #',
                dataField: 'trailerTwoNumber',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Cargo',
        columns: [
            {
                caption: 'Move number',
                dataField: 'moveNumber',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Additional',
        columns: [
            {
                caption: 'Description',
                dataField: 'description',
                dataType: 'string',
                editable: false,
            },
        ],
    },
];

export const bisonAnnouncementsExitColumnsConfig = [
    {
        caption: 'General',
        columns: [
            {
                caption: 'Activity date',
                dataField: 'activityDate',
                dataType: 'datetime',
                editable: false,
                format: 'dd.MM.yyyy. HH:mm:ss',
            },
            {
                caption: 'Scheduled Exit Date',
                dataField: 'scheduledExitDate',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Direction',
                dataField: 'direction',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Location',
                dataField: 'locationName',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Person',
        columns: [
            {
                caption: 'Full name',
                dataField: 'driverName',
                dataType: 'string',
                editable: false,
            },

            {
                caption: 'Company name',
                dataField: 'companyName',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Vehicle',
        columns: [
            {
                caption: 'License Plate #',
                dataField: 'licencePlate',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Unit #',
                dataField: 'truckNumber',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Trailer',
        columns: [
            {
                caption: 'Trailer #',
                dataField: 'trailerNumber',
                dataType: 'string',
                editable: false,
            },
            {
                caption: 'Trailer 2 #',
                dataField: 'trailerTwoNumber',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Cargo',
        columns: [
            {
                caption: 'Move number',
                dataField: 'moveNumber',
                dataType: 'string',
                editable: false,
            },
        ],
    },
    {
        caption: 'Additional',
        columns: [
            {
                caption: 'Description',
                dataField: 'description',
                dataType: 'string',
                editable: false,
            },
        ],
    },
];

/// hardcoded external announcements used for debugging purposes
export const externalAnnouncements = [
    {
        id: 1,
        internalId: 'AIQ-1',
        direction: 'IN',
        locationName: 'Sabac',
        scheduledEntryDate: 'scheduledEntryDate',
        scheduledExitDate: 'scheduledExitDate',
        activityDate: 'activityDate',
        truckNumber: '#1222599887',
        moveNumber: 12313,
        driverName: null,
        companyName: 'Bison',
        licencePlate: 'SA098UR',
        trailerNumber: 'Trailserrrnum',
        trailerTwoNumber: 'trailerTwoNumber',
        description: 'description',
    },
    {
        id: null,
        internalId: 'AIQ-2',
        direction: 'direction',
        locationName: 'locationName',
        scheduledEntryDate: 'scheduledEntryDate',
        scheduledExitDate: 'scheduledExitDate',
        activityDate: 'activityDate',
        truckNumber: 'truckNumber',
        moveNumber: 12311,
        driverName: 'driverName',
        companyName: 'companyName',
        licencePlate: 'License',
        trailerNumber: 'trailerNumber',
        trailerTwoNumber: 'trailerTwoNumber',
        description: 'description',
    },
    {
        id: null,
        internalId: 'AIQ-3',
        direction: 'direction',
        locationName: 'locationName',
        scheduledEntryDate: 'scheduledEntryDate',
        scheduledExitDate: 'scheduledExitDate',
        activityDate: 'activityDate',
        truckNumber: 'truckNumber',
        moveNumber: 12314,
        driverName: 'driverName',
        companyName: 'companyName',
        licencePlate: 'licencePlate',
        trailerNumber: 'trailerNumber',
        trailerTwoNumber: 'trailerTwoNumber',
        description: 'description',
    },
];
