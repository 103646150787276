import { WebRtcPeer } from 'kurento-utils';
import { KurentoClient } from 'kurento-client';
import { kurentoConfig } from './KurentoConfig';
import { BaseCameraStream } from './BaseCameraStream';

export default class CameraStreamConnection extends BaseCameraStream {
    mediaPipelineId;

    #webRTCPeer;
    #mediaPipeline;
    webRTCEndpoint;

    #streamTypeConfiguration;

    #onMediaStream;
    #onReactivateStream;
    #dispatchEvent;

    fullHeader;
    #user;

    #recoverDelayMilis = 60000;
    #maxRecoverRetries = 2;
    #recoverCount;

    //promises (controlled outside)
    activationPromise;
    mediaStreamPromise;
    connectedPromise;
    #resolveConnectedPromise;
    #rejectConnectedPromise;
    localIceCandidatesUsed = [];
    mediaStream;

    //state
    #activating;
    live;
    recreating;
    #forceStop;
    constructor(user, kurentoClient, onMediaStream, onReactivateStream, dispatchEvent, camConfig) {
        super();
        const {
            camId,
            videoData: { playerEndpointId },
        } = camConfig;
        this.cameraId = camId;
        this.#user = user;
        this.kurentoClient = kurentoClient;
        this.mediaPipelineId = playerEndpointId ? playerEndpointId.split('/').at(0) : undefined;
        this.#onMediaStream = onMediaStream;
        this.#onReactivateStream = onReactivateStream;
        this.#dispatchEvent = dispatchEvent;
        this.fullHeader = `[CameraStreamConnection cameraId=${camId}]`;
    }

    /////////////////////////////  API   //////////////////////////////////////

    async create(streamType) {
        if (this.live || this.#activating) {
            this.log(`create(): Already started or starting is in progress | exiting`);
            return null;
        }
        try {
            this.#activating = true;
            this.#forceStop = false;
            this.connectedPromise = new Promise((res, rej) => {
                this.#resolveConnectedPromise = res;
                this.#rejectConnectedPromise = rej;
            });

            const [offer, mediaPipeline] = await Promise.all([this.#createWebRTCPeer(), this.getMediaObjectById(this.mediaPipelineId)]);

            this.log(`create(): created offer and media pipeline`);

            this.#mediaPipeline = mediaPipeline;

            await this.#createWebRTCEndpoint(streamType, offer);
            this.log(`create(): created webRTCEndpoint`);

            this.#activating = false;
            this.live = true;

            // in case command for releasing connection came while connection creation was in progress
            if (this.#forceStop) {
                this.log(`create(): initiating release`);
                await this.release();
                this.#forceStop = false;
            }

            return this.webRTCEndpoint;
        } catch (err) {
            this.#rejectConnectedPromise(err.message);
            this.handle(err, 'create()');
        }
    }

    async release(forceStop = false) {
        this.#onMediaStream(null);
        this.activationPromise = null;
        this.mediaStreamPromise = null;

        if (forceStop) {
            this.#forceStop = true;
        }

        try {
            if (this.#webRTCPeer) {
                await this.#webRTCPeer.dispose();
                this.#webRTCPeer = null;
                this.log(`release(): disposed WebRTCPeer`);
            }
        } catch (err) {
            this.handle(err, 'release()|webRtcPeer dispose');
        }

        try {
            if (this.webRTCEndpoint) {
                this.log(`release(): releasing webRTCEndpoint id=${this.webRTCEndpoint?.id}`);
                await this.webRTCEndpoint.release();
                this.log(`release(): released webRTCEndpoint id=${this.webRTCEndpoint?.id}`);
                this.webRTCEndpoint = null;
            }
        } catch (err) {
            this.handle(err, 'release()|webRTCEndpoint release');
        }
        this.#streamTypeConfiguration = '';
        this.connectedPromise = null;
        this.#mediaPipeline = null;
        this.#activating = false;
        this.live = false;
    }

    async update(mediaPipelineId, streamType, forceUpdate = false) {
        try {
            if (mediaPipelineId === this.mediaPipelineId && !forceUpdate) {
                this.log(`update(): Same MediaPipelineId | exiting`);
                return;
            }
            !forceUpdate && this.#dispatchEvent('videoStreamState', { info: 'Hard refresh in progress...' });

            await this.release(true);
            this.mediaPipelineId = mediaPipelineId;
            await (this.activationPromise = this.create(streamType));
            !forceUpdate && this.log(`update(): updated CameraStreamConnection to new config mediaPipelineId=${this.mediaPipelineId}`);
        } catch (err) {
            this.handle(err, 'update()');
        }
    }

    async recreate(recover) {
        try {
            if (recover) {
                this.recreating = true;
                this.log(`recreate(): Trying to recover, attempt #${this.#recoverCount + 1}`);
                this.#dispatchEvent('videoStreamState', { info: `Stream auto recover initiated... attempt #${this.#recoverCount + 1}` });
            } else if (this.recreating) {
                this.log(`recreate(): Recreation already in progress, exiting`);
                return;
            }

            await this.update(this.mediaPipelineId, this.#streamTypeConfiguration, true);
            this.log(`recreate():Successfully recreated CameraStreamConnection with same config`);
        } catch (err) {
            ++this.#recoverCount;
            const retryOverflow = this.#recoverCount >= this.#maxRecoverRetries;
            recover && !retryOverflow && (await this.recreate(true));
            if (!recover || retryOverflow) {
                this.recreating = false;
                this.handle(err, 'recreate()');
            }
        }
        this.recreating = false;
        return this.webRTCEndpoint;
    }

    async configureWebRTCEndpointForStreamType(streamType) {
        try {
            if (!streamType || typeof streamType !== 'string') {
                this.log(`configureWebRTCEndpointForStreamType(): no type provided, exiting`);
                return;
            }
            const isSupported = Object.keys(VIDEO_CONFIG).includes(streamType);

            if (!isSupported) {
                this.log(
                    `configureWebRTCEndpointForStreamType(): No custom configuration for streamType=${streamType}, currently active configuration streamType=${
                        this.#streamTypeConfiguration
                    } will not change , exiting`
                );
                return;
            }
            this.addWebRtcEndpointTag('activeStream', streamType);
            if (this.#streamTypeConfiguration === streamType) {
                this.log(`configureWebRTCEndpointForStreamType(): Already configured for streamType=${streamType}, exiting`);
                return;
            }

            const bitrates = VIDEO_CONFIG[streamType].bitrates;
            const minVideoKbps = bitrates.minVideoKbps;
            const maxVideoKbps = bitrates.maxVideoKbps;
            const minOutputBitrate = (minVideoKbps + bitrates.minAudioKbps) * 1000;
            const maxOutputBitrate = (maxVideoKbps + bitrates.maxAudioKbps) * 1000;

            // must set maxes before mins, so Kurento accepts min > previous max
            await Promise.all([
                this.configureMediaObject(this.webRTCEndpoint, 'setMaxOutputBitrate', maxOutputBitrate),
                this.configureMediaObject(this.webRTCEndpoint, 'setMaxVideoSendBandwidth', maxVideoKbps),
            ]);

            await Promise.all([
                this.configureMediaObject(this.webRTCEndpoint, 'setMinOutputBitrate', minOutputBitrate),
                this.configureMediaObject(this.webRTCEndpoint, 'setMinVideoSendBandwidth', minVideoKbps),
            ]);

            this.#streamTypeConfiguration = streamType;
        } catch (err) {
            this.handle(err, 'configureWebRTCEndpointForStreamType()');
        }
    }

    async addWebRtcEndpointTag(tag, value) {
        try {
            if (this.#activating) await this.activationPromise;
            if (!this.webRTCEndpoint) return;
            await this.addTagToMediaObject(this.webRTCEndpoint, tag, value); //
        } catch (err) {
            this.handle(err, 'addTag()');
        }
    }

    isConnected() {
        return this.#webRTCPeer?.peerConnection?.connectionState === 'connected';
    }

    async getWebRTCEndpoint(recover) {
        let webRTCEndpoint = null;
        try {
            if (this.webRTCEndpoint) {
                try {
                    await this.getMediaObjectCreationTime(this.webRTCEndpoint);
                    webRTCEndpoint = this.webRTCEndpoint;
                } catch {
                    try {
                        await this.getMediaObjectCreationTime(this.#mediaPipeline);
                    } catch {
                        throw new Error('Media Pipeline not found', { cause: 'MediaPipeline' });
                    }
                    throw new Error('WebRTCEndpoint not found');
                }

                if (recover) webRTCEndpoint = await this.recoverConnection();
            }
        } catch (err) {
            const rethrow = err.cause === 'MediaPipeline' || err.cause === 'RequestFailed';

            if (rethrow) this.#handleAutoRecoverFailed(err);
            this.handle(err, 'getWebRTCEndpoint()', rethrow);

            webRTCEndpoint = await this.recoverConnection(); //hardRefresh
        }

        return webRTCEndpoint;
    }

    async recoverConnection(hardRefresh) {
        try {
            if (hardRefresh) {
                this.#dispatchEvent('videoStreamState', { action: 'hardRefresh' });
                return;
            }
            this.#recoverCount = 0;
            return await this.recreate(true); //try softrefresh for maxretries times
        } catch (err) {
            // this.#dispatchEvent('videoStreamState', { error: err.message, action: 'hardRefresh' }); //if soft refresh fail try hard refresh
            this.#handleAutoRecoverFailed(err);
            return;
        }
    }

    async #handleAutoRecoverFailed(err) {
        this.#dispatchEvent('videoStreamState', {
            error: `Auto recover failed 
            [${err.message}]
            Please try hard refresh!`,
        });
        await this.release();
    }

    ////////////////////////////   PRIVATE  //////////////////////////////////////////
    async #createWebRTCPeer() {
        const options = {
            configuration: kurentoConfig.options,
        };

        try {
            this.#webRTCPeer = WebRtcPeer.WebRtcPeerRecvonly(options);
            this.log(`#createWebRTCPeer():created webRTCPeer`);
            this.#setWebRTCPeerListeners();

            const offer = await new Promise((res, rej) => {
                this.#webRTCPeer.generateOffer((err, offer) => {
                    if (err) {
                        rej(err);
                        return;
                    }
                    this.log(`#createWebRTCPeer():generated offer`);
                    res(offer);
                });
            });

            this.log(`#createWebRTCPeer():returning offer`);
            return offer;
        } catch (err) {
            this.handle(err, '#createWebRTCPeer()', 'Error creating WebRTCPeer');
        }
    }

    async #createWebRTCEndpoint(streamType, offer) {
        try {
            this.webRTCEndpoint = await this.createMediaPipelineElement(this.#mediaPipeline);
            this.log(`#createWebRTCEndpoint(): created webRTCEndpoint id=${this.webRTCEndpoint?.id}`);
            this.configureWebRTCEndpointForStreamType(streamType);
            this.#setWebRTCEndpointListeners();
            this.#setIceCandidateCallbacks();
            await this.#processOffer(offer);
            this.addWebRtcEndpointTag('user', this.#user);
            this.log(`#createWebRTCEndpoint(): processed offer`);
        } catch (err) {
            this.handle(err, '#createWebRtcEndpoint()', 'Error creating WebRTCEndpoint');
        }
    }

    #autoRecover = () => {
        this.log(`#autoRecover(): Initiating auto recover, current webRTCEndpointId=${this.webRTCEndpoint?.id}`);
        this.#dispatchEvent('videoStreamState', { info: 'Stream auto recover initiated...' });
        this.getWebRTCEndpoint(true).then((webRTCEndpoint) => webRTCEndpoint && this.#onReactivateStream());
    };

    #setWebRTCPeerListeners() {
        let timeOut;

        this.#webRTCPeer.peerConnection.addEventListener('iceconnectionstatechange', () => {
            const connectionState = this.#webRTCPeer.peerConnection.iceConnectionState;

            this.log(`WebRTCPeer event - ICEconnectionstatechange=${connectionState} `);
            if (connectionState === 'checking') return;

            if (connectionState === 'disconnected' || connectionState === 'failed' || connectionState === 'closed') {
                if (this.connectedPromise) {
                    this.log(`Rejecting connectedPromise due connection failed`, 'error');
                    this.#rejectConnectedPromise('Connection failed');
                    this.connectedPromise = null;
                    this.#autoRecover();
                    return;
                }

                if (this.recreating) {
                    this.log(`WebRTCPeer event - ICEconnectionstatechange auto recover already initiated, exiting`);
                } else if (!timeOut) {
                    this.log(
                        `WebRTCPeer event - ICEconnectionstatechange=${connectionState}, scheduling stream autorecover in ${
                            this.#recoverDelayMilis
                        } ms`
                    );
                    timeOut = setTimeout(this.#autoRecover, this.#recoverDelayMilis);
                }
            } else {
                if (this.connectedPromise) {
                    this.#resolveConnectedPromise();
                    this.log('Resolved connectedPromise');
                    this.connectedPromise = null;
                    this.#dispatchEvent('videoStreamState', { info: 'Connected, waiting for stream...', isLoading: true });
                }
                if (timeOut) {
                    this.log(`WebRTCPeer event - ICEconnectionstatechange=${connectionState}, canceling autorecover.`);
                    clearTimeout(timeOut);
                    timeOut = undefined;
                }
            }
        });

        this.#webRTCPeer.peerConnection.addEventListener('icecandidateerror', (event) => {
            this.log(`WebRTCPeer event - [icecandidateerror] -> ${event.errorText} [url] -> ${event.url}`, 'warn');
        });

        this.mediaStreamPromise = new Promise((res) => {
            this.#webRTCPeer.peerConnection.onaddstream = (e) => {
                this.log(`WebRTCPeer event - created media stream`);
                this.#onMediaStream(e.stream);
                res(e.stream);
            };
        });
    }

    #setWebRTCEndpointListeners() {
        this.webRTCEndpoint.on('IceComponentStateChange', (e) => this.log(`WebRTCEndpoint event - IceComponentStateChange ${e.state}`));

        this.webRTCEndpoint.on('IceGatheringDone', () => {
            this.log(`WebRTCEndpoint event - IceGatheringDone`);
        });

        this.webRTCEndpoint.on('NewCandidatePairSelected', ({ candidatePair: { localCandidate, remoteCandidate } }) => {
            this.#extractIceCandidateIp(remoteCandidate); //client ICE candidate, this is from KMS perspective
            this.log(`WebRTCEndpoint event - NewCandidatePairSelected: WebRTCEndpoint ${localCandidate} | WebRTCPeer ${remoteCandidate} `);
        });
    }

    #setIceCandidateCallbacks() {
        this.#webRTCPeer.on('icecandidate', (candidate) => {
            this.log(`New LocalIceCandidate found, ${candidate?.candidate}`);
            candidate = KurentoClient.register.complexTypes.IceCandidate(candidate);
            this.webRTCEndpoint.addIceCandidate(candidate, (err) => this.#errorCallback(err, 'WebRTCEndpoint: Error adding ICE candidate'));
        });

        this.webRTCEndpoint.on('OnIceCandidate', (event) => {
            this.#webRTCPeer.addIceCandidate(event.candidate, (err) => this.#errorCallback(err, 'WebRTCPeer: Error adding ICE candidate'));
        });
    }

    async #processOffer(offer) {
        try {
            const answer = await this.webRTCEndpoint.processOffer(offer);
            this.webRTCEndpoint.gatherCandidates((err) => this.#errorCallback(err, 'WebRTCEndpoint: Error gathering ICE candidates'));
            this.#webRTCPeer.processAnswer(answer);
        } catch (err) {
            this.handle(err, '#processOffer()');
        }
    }

    #onForceStop() {
        this.#forceStop = false;
        this.release();
    }

    //ERROR HANDLER
    #errorCallback(err, message, renderMessage) {
        if (err) {
            this.#rejectConnectedPromise && this.#rejectConnectedPromise();
            this.handle(err, message, false);
            this.#dispatchEvent('videoStreamState', { info: '', error: renderMessage || message, isLoading: false });
        }
    }

    handle(err, message, rethrow = true) {
        if (err) {
            this.log(`${message}: ${err?.message ?? err}`, 'error');

            if (this.#forceStop) {
                this.#onForceStop();
                return;
            }

            if (rethrow) {
                const rethrowMessage = typeof rethrow === 'string' ? rethrow : (err?.message ?? err);
                throw new Error(rethrowMessage);
            }
        }
    }

    #extractIceCandidateIp(candidate) {
        const hostnameMatch = /(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/;
        const ipMatch = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;

        const hostnames = candidate.match(hostnameMatch);
        const ips = candidate.match(ipMatch);

        ips?.forEach((ip) => this.localIceCandidatesUsed.push(ip));
        hostnames?.forEach((hostname) => this.localIceCandidatesUsed.push(hostname));
    }
}

const VIDEO_CONFIG = {
    MAIN: {
        bitrates: {
            // minVideoKbps: 448,
            minVideoKbps: 1024,
            maxVideoKbps: 2048,
            minAudioKbps: 48,
            maxAudioKbps: 64,
        },
    },

    SUB: {
        bitrates: {
            minVideoKbps: 448,
            maxVideoKbps: 560,
            minAudioKbps: 48,
            maxAudioKbps: 64,
        },
    },
};
