import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import CameraStream from 'components/transaction/components/panels/transaction/cameras/cameraStream/CameraStream';
import { StreamManager } from 'hooks/useStreams/StreamsManager';
import { streamsReducer } from './streamReducer';
export let streamsUseReducerDispatch = [];

const { sampleStreamsTabId, sampleStreamType, liveStreamType, replayPOC } = StreamManager.config;

function useStreams({
    initialState,
    transactionId,
    liveStreamsTabId,
    defaultStreamType,
    defaultStreamsPerPage,
    getStreamsUrl,
    stompBrokerTopic,
    enableDemoAudioFile,
    cameraStreamFooterMode,
}) {
    const reduxDispatch = useDispatch();

    const stateReducer = useMemo(() => streamsReducer.bind({ liveStreamsTabId, sampleStreamsTabId }), [liveStreamsTabId]);

    const [streamManager, setStreamManager] = useState(null);
    const [state, dispatch] = useReducer(stateReducer, initialState);

    const {
        tabsAudio,
        page: { pageNum },
        videosFailedToLoad,
        liveStreams,
        sampleStreams,
        streamType,
        dispatchQueue,
    } = state;

    useLayoutEffect(() => {
        kurentoManager.registerEventHandler('playbackError', streamManager?.addVideoFailedToLoad);
        return () => {
            kurentoManager.stopAllStreams();
            streamsUseReducerDispatch = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dispatchQueue.length) {
            dispatchQueue.forEach((actionObj) => {
                dispatch(actionObj);
            });
            dispatch({ type: 'setDispatchQueue', payload: [] });
        }
    }, [dispatchQueue]);

    useEffect(() => {
        if (!streamManager) {
            const streamManager = new StreamManager(
                dispatch,
                reduxDispatch,
                liveStreamsTabId,
                defaultStreamType,
                defaultStreamsPerPage,
                getStreamsUrl
            );
            setStreamManager(streamManager);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamManager]);

    useEffect(() => {
        kurentoManager.createKurentoClient();
        kurentoManager.setBeforeUnloadListener();
        return () => kurentoManager.removeBeforeUnloadListener();
    }, []);

    useEffect(() => {
        //done like this in case hook is used in multiple components simulataniously
        dispatch && !streamsUseReducerDispatch.includes(dispatch) && streamsUseReducerDispatch.push(dispatch);
    }, [dispatch]);

    useEffect(() => {
        stompBrokerTopic && reduxDispatch(transactionActions.subscribeToStompBrokerTopic({ topic: transactionId, url: stompBrokerTopic }));
        return () => {
            reduxDispatch(transactionActions.unsubscribeFromStompBrokerTopic(transactionId));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reduxDispatch]);

    const setCameraStreamComponents = useCallback(
        function (camConfig) {
            const videoOutputId = kurentoManager.getVideoElementId(this, camConfig.id);
            const videoFailedToLoad = videosFailedToLoad.includes(videoOutputId);
            return (
                <CameraStream
                    key={camConfig.id}
                    tabId={this}
                    level1
                    isTabActive
                    camConfig={camConfig}
                    tabAudioConfig={tabsAudio[this]}
                    toggleAudio={streamManager?.enableStreamAudio}
                    videoFailedToLoad={videoFailedToLoad}
                    handleRestartVideoStream={streamManager?.handleRestartVideoStream}
                    handleVideoStarted={streamManager?.handleRestartVideoStream}
                    transactionInfo={{ id: transactionId }}
                    videoOutputId={videoOutputId}
                    enableDemoAudioFile={enableDemoAudioFile}
                    footerMode={cameraStreamFooterMode}
                />
            );
        },
        [streamManager, tabsAudio, transactionId, videosFailedToLoad, enableDemoAudioFile, cameraStreamFooterMode]
    );

    const areSampleStreamsActive = streamType === sampleStreamType;
    const areLiveStreamsActive = streamType === liveStreamType;
    const isReplayPocActive = streamType === replayPOC;

    const sampleStreamCameras = useMemo(
        () => areSampleStreamsActive && sampleStreams?.map(setCameraStreamComponents.bind(sampleStreamsTabId)),
        [areSampleStreamsActive, sampleStreams, setCameraStreamComponents]
    );

    const liveStreamCameras = useMemo(
        () => (areLiveStreamsActive && liveStreams?.[pageNum]?.map(setCameraStreamComponents.bind(liveStreamsTabId))) || [],
        [areLiveStreamsActive, liveStreams, pageNum, liveStreamsTabId, setCameraStreamComponents]
    );

    return {
        state,
        streamManager,
        reduxDispatch,
        sampleStreamCameras,
        liveStreamCameras,
        areSampleStreamsActive,
        areLiveStreamsActive,
        isReplayPocActive,
    };
}

export default useStreams;
