import { all, call, put, takeLatest } from 'redux-saga/effects';
import { featureFlagActions } from './featureFlagSlice';
import { fetchFeatureFlag } from 'services/featureFlagService';
import { notificationHandler } from 'utils/utils';

function* getFeatureFlag() {
    try {
        const featureFlagConfig = yield call(fetchFeatureFlag);
        yield put(featureFlagActions.setFeatureFlagData(featureFlagConfig || {}));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            title: 'Error getting enabled appoint data!',
        });
    }
}

function* onGetFeatureFlagData() {
    yield takeLatest(featureFlagActions.getFeatureFlagData.type, getFeatureFlag);
}

export function* featureFlagSaga() {
    yield all([call(onGetFeatureFlagData)]);
}
