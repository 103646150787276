import { useMemo } from 'react';
import styles from './ProcessedSnapshots.module.css';
import ProcessedSnapshotsActions from './ProcessedSnapshotsActions/ProcessedSnapshotsActions';

function ProcessedSnapshots({
    processedSnapshots,
    onSnapshotLoaded,
    processedSnapshotsLoaded,
    onConfirmProcessedSnapshot,
    sendingConfirmation,
    vinTransactionId,
    locationId,
}) {
    const snapshotsArr = useMemo(
        () =>
            processedSnapshots.map((url, i) => (
                <img
                    key={url + i}
                    className={`${styles.snapshot}`}
                    src={`data:image/jpeg;base64,${url}`}
                    alt="Processed snapshot"
                    onLoad={onSnapshotLoaded}
                />
            )),
        [processedSnapshots, onSnapshotLoaded]
    );

    const containerClasses = `${styles.snapshots} ${!processedSnapshotsLoaded ? `${styles.hidden}` : ''}`;

    return (
        <>
            <div className={containerClasses}>{snapshotsArr}</div>

            <ProcessedSnapshotsActions
                vinTransactionId={vinTransactionId}
                locationId={locationId}
                onConfirmProcessedSnapshot={onConfirmProcessedSnapshot}
                sendingConfirmation={sendingConfirmation}
                processedSnapshotsLoaded={processedSnapshotsLoaded}
            />
        </>
    );
}

export default ProcessedSnapshots;
