import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#FFF',
                    color: 'rgba(0, 0, 0, 0.87)',
                    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, 0.087)',
                },
            },
        },
    },
});

export default theme;
