import { useCallback, useEffect, useState } from 'react';
import styles from './VinCheck.module.css';
import LocationsDropdown from 'components/streamTest/LocationsDropdown';
import useStreams from 'hooks/useStreams/useStreams';
import VinCheckControler from 'components/vinCheck/VinCheckController';
import ProcessingSection from 'components/vinCheck/processingSection/ProcessingSection';
import { vinCheckConfig } from 'components/vinCheck/VinCheckConfig';
import CameraSections from 'components/vinCheck/cameraSections/CameraSections';
import { StreamManager } from 'hooks/useStreams/StreamsManager';
import TransactionsTable from 'components/vinCheck/transactionsTable/TransactionsTable';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';

const {
    locationsEndpoint,
    maxNumberOfSnapshots,
    defaultStreamsPerPage,
    defaultStreamType = StreamManager.config.liveStreamType,
    liveStreamsTabId,
} = vinCheckConfig;

const initialState = StreamManager.getInitialState(liveStreamsTabId, defaultStreamType, defaultStreamsPerPage);

//TODO  disabling eslint warining for now, export function to separate file
// eslint-disable-next-line react-refresh/only-export-components
export const vinCheckPageStateUpdateFns = [];

function VinCheck() {
    // streams state
    const { state, streamManager, reduxDispatch, liveStreamCameras } = useStreams({ ...vinCheckConfig, initialState, defaultStreamType });

    const activeCameraId = liveStreamCameras?.[0]?.props?.camConfig?.camId; // with change of this we know that new location is "actiavated"
    const {
        fetchingStreams: { inProgress: fetchingStreamsInProgress, error: fetchingStreamsError },
        selectedLocation,
        liveStreams,
        page: { pageNum },
    } = state;

    const [locationId, setLocationId] = useState();

    //vinCheck state
    const [vinCheckController, setVinCheckController] = useState(null);

    const [snapshotsState, setSnapshotsState] = useState(VinCheckControler.snapshotsInitialState);
    const { snapshots, warnings, outputs } = snapshotsState;

    const [processingActions, setProcessingActions] = useState(VinCheckControler.processingActionsInitialState);
    const {
        requestInProgress: { sendingSnapshots, sendingConfirmation },
        isTransactionProcessed,
        transactionId,
        processedSnapshots,
        processedSnapshotsLoaded,
    } = processingActions;

    //effects
    useEffect(() => {
        if (!vinCheckController)
            setVinCheckController(new VinCheckControler(reduxDispatch, setSnapshotsState, setProcessingActions, maxNumberOfSnapshots));

        return () => setVinCheckController(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!processedSnapshotsLoaded) return;
        const element = document.getElementById('processing-container');
        element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [processedSnapshotsLoaded]);

    useEffect(() => {
        setProcessingActions && !vinCheckPageStateUpdateFns.includes(setProcessingActions) && vinCheckPageStateUpdateFns.push(setProcessingActions);
    }, [setProcessingActions]);

    useEffect(() => {
        const locationId = selectedLocation?.id;
        locationId &&
            reduxDispatch(transactionActions.subscribeToStompBrokerTopic({ topic: 'vinCheckLocation', url: `/topic/vin-locations.${locationId}` }));
        setLocationId(locationId);
        return () => reduxDispatch(transactionActions.unsubscribeFromStompBrokerTopic('vinCheckLocation'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCameraId]);

    useEffect(() => {
        isTransactionProcessed && vinCheckController?.getProcessedSnapshots(transactionId, locationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTransactionProcessed]);

    const activeStreams = liveStreams?.[pageNum];
    const snapshotsCameraIds = Object.keys(snapshots);

    const isSendingSnapshotsDisabled =
        snapshotsCameraIds.length < 2 || sendingSnapshots || !hasSnapshotsFromBothCameraTypes(activeStreams, snapshotsCameraIds);

    const sendSnapshotsHandler = useCallback(
        () => vinCheckController?.sendSnapshotsHandler(locationId, activeStreams, snapshots),
        [vinCheckController, locationId, activeStreams, snapshots]
    );

    return (
        <>
            <div className={styles['vin-check']}>
                <LocationsDropdown
                    onGetCameras={streamManager?.fetchLocationCameras}
                    onSelectedLocation={streamManager?.setSelectedLocation}
                    fetchingStreamsInProgress={fetchingStreamsInProgress}
                    fetchingStreamsError={fetchingStreamsError}
                    locationsUrl={locationsEndpoint}
                />
                <CameraSections
                    onTakeSnapshot={vinCheckController?.takeSnapshotHandler}
                    onRemoveSnapshot={vinCheckController?.removeSnapshotHandler}
                    snapshots={snapshots}
                    warnings={warnings}
                    sendingSnapshots={sendingSnapshots}
                    cameras={liveStreamCameras}
                />
                <ProcessingSection
                    isTransactionProcessed={isTransactionProcessed}
                    hasActiveCamera={!!liveStreamCameras.length}
                    processedSnapshots={processedSnapshots}
                    sendingSnapshots={sendingSnapshots}
                    sendingConfirmation={sendingConfirmation}
                    snapshots={snapshots}
                    sendSnapshotDisabled={isSendingSnapshotsDisabled}
                    onSendSnapshots={sendSnapshotsHandler}
                    onSnapshotLoaded={vinCheckController?.processedSnaphotLoadedHanler}
                    onConfirmProcessedSnapshot={vinCheckController?.confirmDiscardProcessedSnapshotsHandler}
                    processedSnapshotsLoaded={processedSnapshotsLoaded}
                    vinTransactionId={transactionId}
                    locationId={locationId}
                />

                <TransactionsTable
                    locationId={locationId}
                    activeCameraId={activeCameraId}
                    onFetchTableData={vinCheckController?.fetchTableData}
                    onRemoveTableData={vinCheckController?.removeTableData}
                    onConfirmProcessedSnapshot={vinCheckController?.confirmDiscardProcessedSnapshotsHandler}
                    sendingConfirmation={sendingConfirmation}
                />
            </div>

            <div style={{ height: '1600px', width: '200px' }}></div>
            {outputs.map(({ snapshot }) => snapshot)}
        </>
    );
}

export default VinCheck;

const hasSnapshotsFromBothCameraTypes = function (activeStreams, snapshotsCameraIds) {
    const snapshotsCameraTypes = activeStreams?.reduce((hasBothCameraTypes, activeStream) => {
        if (snapshotsCameraIds.includes(activeStream.id) && !hasBothCameraTypes.includes(activeStream.type))
            hasBothCameraTypes.push(activeStream.type);
        return hasBothCameraTypes;
    }, []);

    return snapshotsCameraTypes.length >= 2;
};
