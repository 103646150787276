class TransactionOpenPerformance {
    markNames = {
        received: '-received',
        putToBuffer: '-putToBuffer',
        takenFromBuffer: '-takenFromBuffer',
        startingToHandle: '-startingToHandle',
        handled: '-handled',
        initializeNewFormStart: 'initializeNewFormStart',
        initializeNewFormEnd: 'initializeNewFormEnd',
        fetchFormConfigStart: '-fetchFormConfigStart',
        fetchFormConfigEnd: '-fetchFormConfigEnd',
        fetchEncodedImagesStart: '-encodedImagesStart',
        fetchEncodedImagesEnd: '-encodedImagesEnd',
        fetchRelaysStart: '-fetchRelaysStart',
        fetchRelaysEnd: '-fetchRelaysEnd',
        updateTransactionStart: '-updateTransactionStart',
        updateTransactionEnd: '-updateTransactionEnd',
        updateCamerasStart: '-updateCamerasStart',
        updateCamerasEnd: '-updateCamerasEnd',
    };

    #versionUndefined = [
        this.markNames.fetchFormConfigStart,
        this.markNames.fetchFormConfigEnd,
        this.markNames.fetchEncodedImagesStart,
        this.markNames.fetchEncodedImagesEnd,
    ];

    measures = {
        untilBufferedTime: { name: 'untilBufferedTime', start: this.markNames.received, end: this.markNames.putToBuffer },
        bufferTime: { name: 'bufferTime', start: this.markNames.putToBuffer, end: this.markNames.takenFromBuffer },
        formConfigImagesDataTime: {
            name: 'formConfigImagesDataTime',
            start: this.markNames.initializeNewFormStart,
            end: this.markNames.initializeNewFormEnd,
        },
        formConfigTime: {
            name: 'formConfigTime',
            start: this.markNames.fetchFormConfigStart,
            end: this.markNames.fetchFormConfigEnd,
            versionUndefined: true,
        },
        encodedImagesTime: {
            name: 'encodedImagesTime',
            start: this.markNames.fetchEncodedImagesStart,
            end: this.markNames.fetchEncodedImagesEnd,
            versionUndefined: true,
        },
        relaysTime: { name: 'relaysTime', start: this.markNames.fetchRelaysStart, end: this.markNames.fetchRelaysEnd },
        updateTransactionTime: {
            name: 'updateTransactionTime',
            start: this.markNames.updateTransactionStart,
            end: this.markNames.updateTransactionEnd,
        },
        updateCamerasTime: { name: 'updateCamerasTime', start: this.markNames.updateCamerasStart, end: this.markNames.updateCamerasEnd },
        handlingTime: { name: 'handlingTime', start: this.markNames.startingToHandle, end: this.markNames.handled },
        overallTime: { name: 'overallTime', start: this.markNames.received, end: this.markNames.handled },
    };

    mark(data, mark) {
        performance.mark(this.#getName(data, mark));
    }

    measure(data, measureType) {
        try {
            const { name, start, end, versionUndefined } = this.measures[measureType];
            const performanceMeasure = performance.measure(
                name,
                this.#getName(data, start, versionUndefined),
                this.#getName(data, end, versionUndefined)
            );
            return { name, duration: performanceMeasure.duration };
        } catch {
            return {};
        }
    }

    clear(data) {
        for (let key of Object.keys(this.measures)) {
            performance.clearMeasures(this.measures[key].name);
        }

        for (let key of Object.keys(this.markNames)) {
            const versionUndefined = this.#versionUndefined.includes(this.markNames[key]);
            performance.clearMarks(this.#getName(data, this.markNames[key], versionUndefined));
        }
    }

    getMeasurements(data) {
        const times = [];
        for (let key of Object.keys(this.measures)) {
            const result = this.measure(data, this.measures[key].name);

            result.name && times.push(result);
        }
        this.clear(data);

        console.log(`${this.getHeader(data)} ${times.map(({ name, duration }) => ` ${name}=${duration}`)}`);
    }

    #getName(data, action, versionUndefined) {
        return `transactionId=${data?.truck_transaction_id || data}, version=${versionUndefined ? undefined : data?.version}${action ? action : ''},`;
    }

    getHeader(data) {
        return `TransactionOpen ${this.#getName(data)}`;
    }
}

export const perf = new TransactionOpenPerformance();
