import { Button } from '@mui/material';
import styles from './ProcessingSection.module.css';
import LinearLoader from 'components/UI/Loaders/LinearLoader';
import ProcessedSnapshots from 'components/vinCheck/processingSection/processedSnapshots/ProcessedSnapshots';

function ProcessingSection({
    processedSnapshots,
    sendingSnapshots,
    sendingConfirmation,
    sendSnapshotDisabled,
    onSendSnapshots,
    onSnapshotLoaded,
    onConfirmProcessedSnapshot,
    hasActiveCamera,
    processedSnapshotsLoaded,
    vinTransactionId,
    isTransactionProcessed,
    locationId,
}) {
    const hasProcessedSnapshots = !!processedSnapshots.length;

    const containerClasses = `${styles['processing-container']} ${
        styles[`processing-container--${processedSnapshotsLoaded ? 'expanded' : 'shrinked'}`]
    }`;

    return (
        <div className={styles.wrapper} id="processing-container">
            {hasActiveCamera && (
                <div className={containerClasses}>
                    {!hasProcessedSnapshots && !sendingSnapshots && !isTransactionProcessed && (
                        <Button
                            variant="contained"
                            color="success"
                            size="large"
                            sx={{ visibility: sendingSnapshots ? 'hidden' : 'visible' }}
                            disabled={sendSnapshotDisabled}
                            onClick={onSendSnapshots}
                        >
                            Compare BOL to Scanner
                        </Button>
                    )}
                    {sendingSnapshots && <LinearLoader containerStyles={{ width: '80%', left: '10%' }} />}
                    {hasProcessedSnapshots && (
                        <ProcessedSnapshots
                            vinTransactionId={vinTransactionId}
                            processedSnapshots={processedSnapshots}
                            onSnapshotLoaded={onSnapshotLoaded}
                            onConfirmProcessedSnapshot={onConfirmProcessedSnapshot}
                            processedSnapshotsLoaded={processedSnapshotsLoaded}
                            sendingConfirmation={sendingConfirmation}
                            locationId={locationId}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default ProcessingSection;
