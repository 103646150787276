import { kurentoManager } from './KurentoManager';

import CameraStreamConnection from './CameraStreamConnection';
import { CameraStreamPlayer } from './CameraStreamPlayer';
import { TRANSACTION_PAGE_TABS } from 'config';

import { axiosAiq } from 'config';
import { notificationHandlerSync } from 'utils/utils';
const videoWallTransactioId = TRANSACTION_PAGE_TABS.VIDEO_WALL_TAB.DUMMY_TRANSACTION_ID;

export default class KurentoRtspSource {
    aot;
    #kurentoClient;
    #user;
    cameraId;

    #cameraStreamConnection;
    #cameraStreamPlayerByStreamType = new Map();

    #activeMediaPiplineId;
    activeStreamType = '';
    #activateOnUpdateStreamType = '';

    activatingStreamType = '';
    #initialState = { info: 'Initiating...', error: '', freezed: false, isLoading: true };
    streamState = this.#initialState;

    #mediaStream;
    #freezeEventTrasholdMilis = 6000;
    #freezeTimeoutId;

    #videoOutputs = new Map();
    #eventHandlers = new Map();
    #eventListeners = new Map();
    #forceStop;

    #fullHeader;

    #updatingConfigurationPromise;
    #updatingConfiguration;

    constructor(kurentoClient, user, cameraId) {
        this.#kurentoClient = kurentoClient;
        this.#user = user;
        this.cameraId = cameraId;
        this.#setLogHeaders();
    }

    async addCameraStreamConfiguration(camConfig, updateConfig, predefining) {
        try {
            //if block bellow intentionaly is not in separate fn
            if (this.#updatingConfiguration) {
                await this.#updatingConfigurationPromise;
                this.#log(`addCameraStreamConfiguration():awaited cameraStreamConfiguration update to finish`);
            }

            const playerEndpintId = camConfig?.videoData?.playerEndpointId;
            const streamType = camConfig?.videoData?.streamType;
            if (!playerEndpintId || !streamType) {
                this.#log(
                    `addCameraStreamConfiguration(): No PlayerEndpoint ID or stream type provided, playerEndpointId=${playerEndpintId}, streamType=${streamType} | exiting `
                );
                if (!playerEndpintId) throw new Error('PlayerEndpointId not provided');
                if (!streamType) throw new Error('StreamType not provided');
            }

            if (this.#isConfigured(camConfig)) {
                this.#log(`addCameraStreamConfiguration(): CameraStream configured | exiting`);
                return;
            }

            this.#log(
                `addCameraStreamConfiguration(): New cameraConfiguration received, streamType=${streamType} PlayerEndpointId=${playerEndpintId}`
            );

            if (this.activeStreamType === streamType) this.streamState = { info: '', error: '', freezed: false, isLoading: false };
            else this.streamState = this.#initialState;

            if (updateConfig) {
                this.#log(`addCameraStreamConfiguration(): Initiating cameraStreamConfig update streamType=${streamType}`);
                await (this.#updatingConfigurationPromise = this.#updateStreamConfig(camConfig));
                return;
            }

            this.#addNewStream(camConfig, predefining);
            if (predefining) {
                const promises = [];
                const connectionPromise = this.#cameraStreamConnection?.connectedPromise;
                const playerEndpointPromise = this.playerByStreamType(streamType)?.activationPromise;
                if (connectionPromise) promises.push(connectionPromise);
                if (playerEndpointPromise) promises.push(playerEndpintId);
                await Promise.all(promises);
                this.streamState = { ...this.#initialState, info: 'Activating...' };
            }
        } catch (err) {
            this.#handle(err, 'addCameraStreamConfiguration()', false);
        }
    }

    #reactivateStream = async () => {
        try {
            const streamType = this.activeStreamType;
            if (streamType) {
                this.#log(`reactivateStream(): Reactivating stream type=${streamType}`);
                await this.activateStreamType(streamType, true);
            } else {
                this.#log(`reactivateStream(): No active stream type, exiting.`);
            }
        } catch (err) {
            this.#handle(err, 'reactivateStream()', false);
        }
    };

    async activateStreamType(streamType, forceActivation) {
        try {
            if (this.#updatingConfiguration) {
                await this.#updatingConfigurationPromise;
                this.#log(`activateStreamType():awaited cameraStreamConfiguration to finish`);
            }

            if (!streamType || ((this.activeStreamType === streamType || this.activatingStreamType === streamType) && !forceActivation)) {
                this.#log(`activateStreamType(): exiting without changing streamType`);
                this.#activateOnUpdateStreamType = '';
                return;
            }

            this.activatingStreamType = streamType;

            let oppositeStreamType;

            const currentPlayer = this.playerByStreamType(this.activeStreamType);
            let playerToActivate = this.playerByStreamType(streamType);
            const connection = this.#cameraStreamConnection;

            ({ oppositeStreamType, playerToActivate } = this.#getPlayerToActivate(streamType, playerToActivate, connection) || {});

            if (!connection) {
                this.#log(`activateStreamType(): No CameraStreamConnection | exiting`);
                this.activatingStreamType = '';
                throw new Error('No CameraStreamConnection object [#1]');
            }
            if (
                forceActivation === 'updateConfig' &&
                playerToActivate === currentPlayer &&
                playerToActivate.playerEndpointId.startsWith(this.#activeMediaPiplineId)
            ) {
                this.activatingStreamType = '';
                this.#log(`activateStreamType(): already activated stream type | exiting`);
                return;
            }

            if (!playerToActivate.live || !connection.live) {
                await Promise.all([playerToActivate.activationPromise, connection.activationPromise]);
                this.#log(`activateStreamType(): awaited for Player or Connection activation proces to finish before proceeding`);
            }

            const activatedStreamType = oppositeStreamType || streamType;
            await this.#connectCameraStreamPlayerToConnection(currentPlayer, playerToActivate, connection, activatedStreamType);

            if (oppositeStreamType) {
                this.#log(`activateStreamType():start listening for streamType=${streamType} configUpdate`);
                this.#activateOnUpdateStreamType = streamType;
            } else if (this.#activateOnUpdateStreamType) {
                this.#log(`activateStreamType():stopping listening for configUpdate`);
                this.#activateOnUpdateStreamType = '';
            }

            this.activeStreamType = activatedStreamType;
            this.activatingStreamType = '';
            this.#log(`activateStreamType(): activated streamType=${activatedStreamType}`);
        } catch (err) {
            this.activatingStreamType = '';
            this.#handle(err, 'activateStreamType()', false);
        }
    }

    async release(forceStop) {
        try {
            this.streamState = this.#initialState;
            this.#videoOutputs = new Map();
            await this.#cameraStreamConnection?.release(forceStop);
            this.#log(`release(): released CameraStreamConnection`);
            this.#cameraStreamConnection = null;
            this.#cameraStreamPlayerByStreamType = new Map();
            this.activeStreamType = '';
        } catch (err) {
            this.#handle(err, 'release()', false);
        }
    }

    async restart(streamType) {
        try {
            this.#dispatchEvent('videoStreamState', { info: 'Soft refresh in progress...', error: '', freeze: '' });
            if (this.#updatingConfiguration) {
                await this.#updatingConfigurationPromise;
                this.#log(`restart(): awaited cameraStreamConfiguration update to finish`);
            }
            if (!this.#cameraStreamConnection) throw new Error('No CameraStreamConnection object [#2]');
            if (this.#cameraStreamConnection?.recreating) {
                console.log('Already recreating exiting');
                return;
            }
            await this.#cameraStreamConnection.recreate();
            await this.activateStreamType(streamType || this.activatingStreamType, true);
        } catch (err) {
            this.#handle(err, 'restart()', false);
        }
    }

    async hardRefresh() {
        try {
            this.#dispatchEvent('videoStreamState', { info: 'Requesting hard refresh, please wait...', error: '', freeze: '' });
            await axiosAiq.post(`/camera-stream/camera/${this.cameraId}/refresh`);
        } catch (err) {
            notificationHandlerSync({ err, title: `Error sending request for stream hard refresh, cameraId=${this.cameraId}` });
            this.#dispatchEvent('videoStreamState', { info: '', error: '', freeze: '', isLoading: false });
        }
    }

    #addNewStream(camConfig) {
        try {
            const streamType = camConfig.videoData?.streamType;
            let cameraStreamPlayer = this.playerByStreamType(streamType);
            const cameraStreamConnection = this.#cameraStreamConnection;

            if (!cameraStreamConnection) this.#createCameraStreamConnection(camConfig);
            if (!cameraStreamPlayer) this.#createCameraStreamPlayer(camConfig);
        } catch (err) {
            this.#handle(err, '#addNewStream()');
        }
    }

    #createCameraStreamConnection(camConfig) {
        try {
            this.#log(`#createCameraStreamConnection(): creating new CameraStreamConnection`);
            const streamType = camConfig.videoData?.streamType;
            this.#cameraStreamConnection = new CameraStreamConnection(
                this.#user,
                this.#kurentoClient,
                this.#setMediaStream,
                this.#reactivateStream,
                this.#dispatchEvent,
                camConfig
            );
            return (this.#cameraStreamConnection.activationPromise = this.#cameraStreamConnection.create(streamType));
        } catch (err) {
            this.#handle(err, '#createCameraStreamConnection()');
        }
    }

    #createCameraStreamPlayer(camConfig) {
        try {
            this.#log(`#createCameraStreamPlayer(): creating new CameraStreamPlayer`);
            const streamType = camConfig.videoData?.streamType;
            const cameraStreamPlayer = new CameraStreamPlayer(this.#kurentoClient, camConfig);
            this.#cameraStreamPlayerByStreamType.set(streamType, cameraStreamPlayer);
            return (cameraStreamPlayer.activationPromise = cameraStreamPlayer.activate());
        } catch (err) {
            this.#handle(err, '#createCameraStreamPlayer');
        }
    }

    async #updateStreamConfig(camConfig) {
        try {
            this.#updatingConfiguration = true;
            const {
                videoData: { playerEndpointId, streamType },
            } = camConfig;

            const mediaPipelineId = playerEndpointId ? playerEndpointId.split('/').at(0) : undefined;
            let cameraStreamPlayer = this.playerByStreamType(streamType);
            const cameraStreamConnection = this.#cameraStreamConnection;

            const promises = [];
            let oldWebRTCEndpoint;
            if (cameraStreamConnection) {
                if (!cameraStreamConnection.live) await cameraStreamConnection.activationPromise;
                oldWebRTCEndpoint = await cameraStreamConnection.getWebRTCEndpoint();
                promises.push(cameraStreamConnection.update(mediaPipelineId));
            } else {
                promises.push(this.#createCameraStreamConnection(camConfig));
            }

            if (cameraStreamPlayer) {
                if (!cameraStreamPlayer.live) await cameraStreamPlayer.activationPromise;
                else if (this.activeStreamType === streamType && oldWebRTCEndpoint) {
                    this.#log(`#updateStreamConfig(): Update of active PlayerEndpoint in progress, disconnecting WebRTCEndpoint`);
                    await cameraStreamPlayer.disconnect(oldWebRTCEndpoint);
                }
                promises.push(cameraStreamPlayer.update(playerEndpointId));
            } else {
                promises.push(this.#createCameraStreamPlayer(camConfig));
            }

            await Promise.all(promises);

            this.#log(`#updateStreamConfig(): Updated CameraStreamConnection/Player config`);

            this.#updatingConfiguration = false;
            this.#updatingConfigurationPromise = null;

            const streamTypeToActivate =
                this.#activateOnUpdateStreamType || this.activeStreamType || (this.#getVideoOutputIds().length && streamType) || '';

            this.#log(`#updateStreamConfig(): Initiating activation of streamType=${streamTypeToActivate}`);
            await this.activateStreamType(streamTypeToActivate, 'updateConfig');

            this.#activeMediaPiplineId = mediaPipelineId;
        } catch (err) {
            this.#updatingConfiguration = false;
            this.#updatingConfigurationPromise = null;
            this.#handle(err, '#updateStreamConfig()');
        }
    }

    #getPlayerToActivate(streamType, playerToActivate, connection) {
        try {
            let oppositeStreamType;

            if (!playerToActivate) {
                // try with opposite stream type
                oppositeStreamType = streamType === 'SUB' ? 'MAIN' : 'SUB';
                this.#log(`#getPlayerToActivate(): No player for StreamType=${streamType}, changing to streamType=${oppositeStreamType}`);
                playerToActivate = this.playerByStreamType(oppositeStreamType);
                if (!playerToActivate) {
                    this.#log(`#getPlayerToActivate():No player for StreamType=${oppositeStreamType} | exiting`);
                    this.activatingStreamType = '';
                    throw new Error(`No CameraStreamPlayer object`);
                }
            }

            //check if playerEndpoint and webRtcEndpoint are in same mediaPipeline (problem may occur during hardrefresh)
            if (!playerToActivate.playerEndpointId.startsWith(connection.mediaPipelineId)) {
                //if not try to connect to opposite streamType
                oppositeStreamType = streamType === 'SUB' ? 'MAIN' : 'SUB';
                this.#log(
                    `#getPlayerToActivate(): PlayerEndpoint and WebRTCEndpoint are not in same pipeline, changing streamType to ${oppositeStreamType}`
                );
                playerToActivate = this.playerByStreamType(oppositeStreamType);
                if (!playerToActivate || !playerToActivate?.playerEndpointId?.startsWith(connection?.mediaPipelineId)) {
                    this.activatingStreamType = '';
                    throw new Error(`Player and WebRTCEndpoint don't match. Hard refresh required!`);
                }
            }

            return { playerToActivate, oppositeStreamType };
        } catch (err) {
            this.#handle(err, '#getPlayerToActivate()');
        }
    }

    async #connectCameraStreamPlayerToConnection(currentPlayer, playerToActivate, connection, streamType) {
        try {
            const webRTCEndpoint = await connection.getWebRTCEndpoint();
            if (!webRTCEndpoint) throw new Error('No WebRTCEndpoint. Try hard refresh!');
            if (currentPlayer && currentPlayer.playerEndpointId.startsWith(connection.mediaPipelineId)) {
                await currentPlayer.disconnect(webRTCEndpoint);
            }
            await Promise.all([playerToActivate.connect(webRTCEndpoint), connection.configureWebRTCEndpointForStreamType(streamType)]);
        } catch (err) {
            this.#handle(err, '#connectCameraStreamPlayerToConnection()');
        }
    }

    #isConfigured(camConfig) {
        const { playerEndpointId, streamType } = camConfig.videoData;

        const cameraStreamPlayer = this.playerByStreamType(streamType);
        const mediaPipelineId = playerEndpointId ? playerEndpointId.split('/').at(0) : undefined;
        const cameraStreamConnection = this.#cameraStreamConnection;

        const isConfigured = cameraStreamPlayer?.playerEndpointId === playerEndpointId && cameraStreamConnection?.mediaPipelineId === mediaPipelineId;

        return isConfigured;
    }

    playerByStreamType(streamType) {
        return this.#cameraStreamPlayerByStreamType.get(streamType);
    }

    #setMediaStream = (mediaStream) => {
        this.#mediaStream = mediaStream;
        if (mediaStream) {
            const soundActiveOnCamera = kurentoManager.getSourceWithAudio()?.cameraId;
            if (soundActiveOnCamera !== this.cameraId) this.audio(false);
            this.#setMediaStreamListeners(mediaStream);
            // this is called once on CameraStreamConnection create/update
            this.#editWebRtcEndpointTransactionTag();
        }
        this.#setMediaStreamOnVideoOutputs(mediaStream);
    };

    #setMediaStreamListeners(mediaStream) {
        const videoTrack = mediaStream.getVideoTracks().at(0);
        videoTrack.onunmute = this.#onMediaStreamTrackUnmute;
        videoTrack.onmute = this.#onMediaStreamTrackMute;
    }

    #onMediaStreamTrackMute = () => {
        this.#log('Video track STOPPED');
        if (this.#freezeTimeoutId) return;
        this.#log(`Scheduling Freezed event in ${this.#freezeEventTrasholdMilis} ms`);
        this.#freezeTimeoutId = setTimeout(() => {
            this.#log('Dispatching Freezed event to video element');
            this.#dispatchEvent('videoStreamState', {
                freezed: true,
            });
            this.#freezeTimeoutId = 0;
        }, this.#freezeEventTrasholdMilis);
    };

    #onMediaStreamTrackUnmute = () => {
        this.#log('Video track ACTIVE');
        if (this.#freezeTimeoutId) {
            this.#log('Removing scheduled Freezed event');
            clearTimeout(this.#freezeTimeoutId);
            this.#freezeTimeoutId = 0;
            return;
        }
        this.#dispatchEvent('videoStreamState', {
            freezed: false,
        });
    };

    async deactivate() {
        if (this.aot) return;
        try {
            this.streamState = { ...this.#initialState, info: 'Activating...' };
            const currentPlayer = this.playerByStreamType(this.activeStreamType);
            const webRtcEndPoint = await this.#cameraStreamConnection.getWebRTCEndpoint();
            if (webRtcEndPoint) {
                currentPlayer && (await currentPlayer.disconnect(webRtcEndPoint));
                this.#cameraStreamConnection.addWebRtcEndpointTag('activeStream', 'IDLE');
                this.#log(`deactivate():paused cameraId=${this.cameraId}`);
            } else {
                this.#log(`deactivate(): No webrtcEndpoint`);
            }
            this.activeStreamType = '';
        } catch (err) {
            this.#handle(err, 'deactivate()', false);
        }
    }

    ///Events
    addEventListener(type, handler, videoOutputId = this.videoOutputId) {
        //ensure same  handler reference is used across video elements (so removeEventListener can work as expected)
        const handlerPresent = this.#eventHandlers.get(type);
        if (!handlerPresent) {
            this.#eventHandlers.set(type, handler);
        }
        const eventHandler = handlerPresent || handler;

        const registeredListeners = this.#eventListeners.get(videoOutputId);

        if (registeredListeners && registeredListeners.includes(type)) return;

        registeredListeners && registeredListeners.push(type);

        const videoOutput = document.getElementById(videoOutputId);
        if (videoOutput) {
            videoOutput.addEventListener(type, eventHandler);
            console.log(`[KurentoRtspSource videoOutputId=${videoOutputId} addEventListener(): For '${type}' registered: ${handler}`);
        }

        const types = registeredListeners || [type];
        this.#eventListeners.set(videoOutputId, types);
    }

    #removeEventListeners(videoOutputId) {
        const registeredListeners = this.#eventListeners.get(videoOutputId);
        if (!registeredListeners) return;

        registeredListeners.forEach((event) => {
            const handler = this.#eventHandlers.get(event);
            const videoOutput = document.getElementById(videoOutputId);
            if (videoOutput) {
                videoOutput.removeEventListener(event, handler);
                console.log(`[KurentoRtspSource videoOutputId=${videoOutputId} removeEventListeners(): For '${event}' regstered: ${handler}`);
            }
        });

        this.#eventListeners.delete(videoOutputId);
    }

    #dispatchEvent = (type, detail, videoOutputId) => {
        if (type === 'videoStreamState') {
            const event = new CustomEvent(type, {
                detail: {
                    ...this.#updateStreamState(detail),
                    updateSourceState: (detail) => this.#dispatchEvent('videoStreamState', detail),
                },
            });

            let dispatching;

            const videoOutputIds = videoOutputId ? [videoOutputId] : this.#getVideoOutputIds();

            if (videoOutputIds.length) {
                this.#getVideoOutputIds().forEach((videoOutputId) => {
                    const videoOutput = document.getElementById(videoOutputId);
                    if (!videoOutput) return;
                    videoOutput.dispatchEvent(event);
                    if (type === 'videoStreamState')
                        dispatching = Object.keys(this.streamState)
                            .map((key) => ` [${key}:${this.streamState[key]}]`)
                            .toString();

                    this.#log(`#dispatchEvent(): Target "${videoOutputId}" type="${type}" detail:{${dispatching}}`);
                });
            }
        }
    };

    #updateStreamState(detail) {
        return (this.streamState = { ...(this.streamState && { ...this.streamState }), ...detail });
    }

    ///Managing video outputs
    addVideoOutput(videoOutputId, transactionId) {
        if (this.#videoOutputs.get(videoOutputId)) return;
        this.#videoOutputs.set(videoOutputId, transactionId);
        this.#setLogHeaders();
        this.#editWebRtcEndpointTransactionTag();
        this.#log(`addVideoOutput(): Added new video output: ${videoOutputId}`);
        this.#dispatchEvent('videoStreamState', this.streamState, videoOutputId);
        if (this.#mediaStream) this.#setMediaStreamOnVideoOutput(videoOutputId, this.#mediaStream);
        else
            this.#cameraStreamConnection?.mediaStreamPromise
                ?.then((mediaStream) => this.#setMediaStreamOnVideoOutput(videoOutputId, mediaStream))
                .catch((err) => this.#handle(err, 'audio()', false));
    }

    async removeVideoOutput(videoOutputId) {
        try {
            this.#setMediaStreamOnVideoOutput(videoOutputId, null);
            this.#log(`Removing video output: ${videoOutputId}`);
            this.#removeEventListeners(videoOutputId);
            this.#videoOutputs.delete(videoOutputId);
            if (!this.#videoOutputs.size) await this.deactivate();
            await this.#editWebRtcEndpointTransactionTag();
            this.#setLogHeaders();
        } catch (err) {
            this.#handle(err, 'removeVideoOutput', false);
        }
    }

    async makeExclusiveForVideoOutput(videoOutputId) {
        for (let vOutputId of [...this.#videoOutputs.keys()]) {
            if (vOutputId !== videoOutputId) await this.removeVideoOutput(vOutputId);
        }
    }

    #getVideoOutputIds() {
        return Array.from(this.#videoOutputs.keys());
    }

    getTransactionIds() {
        return [...new Set(Array.from(this.#videoOutputs.values()))];
    }

    isActiveOnVideoWall() {
        return this.getTransactionIds().includes(videoWallTransactioId);
    }

    #getTransactionTagValue() {
        const transactionIds = this.getTransactionIds();
        if (transactionIds.length) return transactionIds.length === 1 ? transactionIds[0] : `[${transactionIds.join(', ')}]`;
        else return 'noActiveTransaction';
    }

    #setLogHeaders() {
        const videoOutputIdsString = this.#getVideoOutputIds().join(', ');
        this.#fullHeader = `[KurentoRtspSource cameraId=${this.cameraId}, ${
            videoOutputIdsString ? `videoOutputIds=[${videoOutputIdsString}], ` : ''
        }transactionId=${this.#getTransactionTagValue()}]`;
    }

    hasVideoOutputId(videoOutputId) {
        return this.#getVideoOutputIds().includes(videoOutputId);
    }

    isExclusivelyUsedForTransactionId(transactionId) {
        const transactions = this.getTransactionIds();
        if (transactions.length > 1) return false;
        return transactions[0] === transactionId;
    }

    ////Main source controls
    audio(enabled) {
        if (!this.#mediaStream)
            this.#cameraStreamConnection?.mediaStreamPromise
                ?.then((mediaStream) => this.#setAudio(mediaStream, enabled))
                .catch((err) => this.#handle(err, 'audio()', false));
        else this.#setAudio(this.#mediaStream, enabled);
    }

    #setAudio = (mediaStream, enabled) => {
        this.#log(`#setAudio(): Setting audio track enabled = ${enabled}`);
        mediaStream.getAudioTracks().at(0).enabled = enabled;
    };

    ///////////

    async #editWebRtcEndpointTransactionTag() {
        try {
            const cameraStreamConnection = this.#cameraStreamConnection;
            cameraStreamConnection && (await cameraStreamConnection.addWebRtcEndpointTag('transactionId', `${this.#getTransactionTagValue()}`));
        } catch (err) {
            this.#handle(err, '#editWebRtcEndpointTransactionTag()');
        }
    }

    // Video/audio status

    #setMediaStreamOnVideoOutputs(mediaStream) {
        const videoOutputIds = this.#getVideoOutputIds();
        videoOutputIds.forEach((videoOutputId) => {
            this.#setMediaStreamOnVideoOutput(videoOutputId, mediaStream);
        });
    }

    #setMediaStreamOnVideoOutput(videoOutputId, mediaStream) {
        const videoOutput = document.getElementById(videoOutputId);
        if (videoOutput) {
            videoOutput.srcObject = mediaStream;
            this.#log(
                `#setMediaStreamOnVideoOutput(): ${mediaStream ? 'Attached' : 'Removed'} mediaStream ${
                    mediaStream ? 'to' : 'from'
                } videoOutputId=${videoOutputId}`
            );
        }
    }

    // Debugging information

    getInfo() {
        const activeStreamType = this.activeStreamType || 'not available';
        const activePlayer = activeStreamType ? this.#cameraStreamPlayerByStreamType.get(activeStreamType) : null;

        const url = activePlayer?.maskedUrl || 'not available';
        const mediaPipelineId = this.#cameraStreamConnection?.mediaPipelineId || 'not available';
        const playerEndpointId = activePlayer?.playerEndpointId?.split('/')?.at(1) || 'not available';
        const webRTCEndpointId = this.#cameraStreamConnection?.webRTCEndpoint?.id?.split('/')?.at(1) || 'not available';

        let info = `<strong>CameraId: </strong> ${this.cameraId}`;
        info += `&nbsp;&nbsp;<strong>StreamType:</strong> ${activeStreamType}<br/><br/>`;
        info += `<strong>URL: </strong> ${url} <br/><br/>`;

        info += `<strong>MediaPipeline:</strong> ${mediaPipelineId}<br/><br/>`;
        info += `<strong>PlayerEndpoint: </strong> ${playerEndpointId}<br/><br/>`;
        info += `<strong>WebRTCEndpoint: </strong> ${webRTCEndpointId}<br/><br/>`;

        info += '<strong>Media:</strong>&nbsp;';

        const mediaStream = this.#mediaStream;
        if (!mediaStream) {
            info += 'not available';
        } else {
            const [videoTrack] = mediaStream.getVideoTracks();
            const videoTrackSettings = videoTrack.getSettings();
            info += `\n[id=${videoTrack.id},\n`;
            info += `\treadyState=${videoTrack.readyState}, frameRate=${videoTrackSettings.frameRate},\n`;
            info += `\tfacingMode=${videoTrackSettings.facingMode}, h=${videoTrackSettings.height}, w=${videoTrackSettings.width}]\n`;

            const [audioTrack] = mediaStream.getAudioTracks();
            if (!audioTrack) return info;

            info += '<br/><br/><strong>Audio:</strong>';
            const audioTrackSettings = audioTrack.getSettings();
            info += `\n[id=${audioTrack.id},\n`;
            info += `\treadyState=${audioTrack.readyState}, volume=${audioTrackSettings.volume},\n`;
            info += `\tchannelCount=${audioTrackSettings.channelCount}, sampleRate=${audioTrackSettings.sampleRate}]`;
        }

        return info;
    }

    get webRtcEndpointId() {
        return this.#cameraStreamConnection?.webRTCEndpoint?.id;
    }

    get localICECandidatesUsed() {
        return this.#cameraStreamConnection?.localIceCandidatesUsed;
    }

    #log(message, mode = 'log') {
        console[mode](`${this.#fullHeader} ${message}`);
    }

    //ERROR HANDLERS

    #handle(err, message, rethrow = true) {
        if (err) {
            this.#log(`${message}: ${err?.message || err}`, 'error');
            this.#handlePlaybackError(err, message);
            if (this.#forceStop) {
                this.#forceStop = false;
                this.release();
                return;
            }
            if (rethrow) throw new Error(err?.message || err);
        }
    }

    #handlePlaybackError(err) {
        this.#dispatchEvent('videoStreamState', { info: '', error: err.message, isLoading: false });
    }

    // String representation

    toString() {
        return this.#fullHeader;
    }
}
