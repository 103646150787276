import styles from './Alert.module.css';
import { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import Note from './Note/Note';

function Alert({ text, color, note, name, mode, accessPeriod }) {
    const alertRef = useRef();
    const [hovered, setHovered] = useState(false);

    const backgroundColor = color || '#DCDCDC';
    const isHoverMode = mode === 'hover';

    const handlers = useMemo(() => {
        return {
            onMouseEnter: () => setHovered(true),
            onMouseLeave: () => setHovered(false),
        };
    }, []);

    useEffect(() => {
        const { current: alertElement } = alertRef;
        alertElement.style.backgroundColor = backgroundColor;
        return () => {
            alertElement.removeAttribute('style');
        };
    }, [backgroundColor]);

    const isVisible = (isHoverMode && hovered) || !isHoverMode;
    const arrowStyles = `${styles.arrow}${!isVisible ? ` ${styles['hidden']}` : ''}`;
    const firstLetter = text || name?.[0];

    return (
        <>
            <div className={`${styles['alert-container']} ${styles[`alert-container__${mode}`]}`} {...(isHoverMode && handlers)}>
                <p className={`${styles['alert']}${!text ? ` ${styles['alert--rounded']}` : ''}`} ref={alertRef}>
                    {firstLetter}
                </p>
                {isHoverMode && <div className={arrowStyles} style={{ borderTopColor: backgroundColor }}></div>}
            </div>
            <Note mode={mode} hovered={hovered} name={name} note={note} color={backgroundColor} accessPeriod={accessPeriod} />
        </>
    );
}

export default Alert;
