import { Button } from '@mui/material';
import styles from './ProcessedSnapshotsActions.module.css';
import LinearLoader from 'components/UI/Loaders/LinearLoader';

function ProcessedSnapshotsActions({
    sendingConfirmation,
    processedSnapshotsLoaded,
    onConfirmProcessedSnapshot,
    vinTransactionId,
    locationId,
    tablePageIndex,
    clearCurrentTransaction,
}) {
    const confirmHandler = function () {
        onConfirmProcessedSnapshot({ ...this, vinTransactionId, locationId, pageNum: tablePageIndex, clearCurrentTransaction });
    };
    return (
        <div className={`${styles['snapshots__actions']}${sendingConfirmation || !processedSnapshotsLoaded ? ` ${styles.hidden}` : ''}`}>
            <Button variant="contained" color="success" onClick={confirmHandler.bind({ confirmed: true })}>
                Confirm
            </Button>
            <Button variant="contained" color="error" onClick={confirmHandler.bind({ confirmed: false })}>
                Discard
            </Button>
            {sendingConfirmation && <LinearLoader containerStyles={{ visibility: 'visible' }} />}
        </div>
    );
}

export default ProcessedSnapshotsActions;
