import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { getAvailableRoutes } from '../NavigationDrawer';

const textColor = { color: 'var(--color-blue)' };

function DrawerListItem({ listItemComponent, listItemAttributes, onClick, text, textStyles, Icon }) {
    const additionalAttributes = { ...(listItemAttributes && { ...listItemAttributes }) };
    return (
        <ListItem disablePadding button component={listItemComponent} onClick={onClick} {...additionalAttributes}>
            <ListItemButton>
                <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
                <ListItemText primary={text} sx={{ ...textColor, ...textStyles }} />
            </ListItemButton>
        </ListItem>
    );
}

export function FoldableDrawerListItem({ text, textStyles, childListItems, user, onClick }) {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);

    const children = getAvailableRoutes(childListItems, user, onClick);
    return (
        <>
            <ListItem disablePadding button onClick={toggleOpen}>
                <ListItemButton>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={text} sx={{ ...textColor, ...textStyles }} />
                </ListItemButton>
                {open ? <ExpandLess sx={textColor} /> : <ExpandMore sx={textColor} />}
            </ListItem>
            <Collapse in={open} timeout="auto" sx={{ paddingLeft: '1rem' }}>
                {children}
            </Collapse>
        </>
    );
}

export default DrawerListItem;
