import { createSelector } from '@reduxjs/toolkit';

export const selectDeskSlice = (state) => state.desks;

export const selectAllDesks = createSelector([selectDeskSlice], (desksSlice) => desksSlice.allDesks);

export const selectHasAvailableDesks = createSelector([selectAllDesks], (allDesks) => !!allDesks.length);

export const selectCurrentDesk = createSelector([selectDeskSlice], (desksSlice) => desksSlice.currentDesk);

export const selectFetchingDesksInProgress = createSelector([selectDeskSlice], (desksSlice) => desksSlice.fetchingDesksInProgress);

export const selectFetchingDesksError = createSelector([selectDeskSlice], (desksSlice) => desksSlice.fetchingDesksError);

export const selectMaxDeskTransactions = createSelector([selectDeskSlice], (deskSlice) => deskSlice.maxDeskTransactions);

export const selectIsActiveDeskConfirmed = createSelector([selectDeskSlice], (deskSlice) => deskSlice.isActiveDeskConfirmed);

export const selectExpectedFinishedTransactionMillis = createSelector([selectDeskSlice], (deskSlice) => deskSlice.expectedFinishedTransactionMillis);

export const selectDeskWarnings = createSelector([selectDeskSlice], (deskslice) => deskslice.deskWarnings);

export const selectIsDeskLoading = createSelector([selectDeskSlice], (deskslice) => deskslice.isDeskLoading);

export const selectPauseRequestSent = createSelector([selectDeskSlice], (desksSlice) => desksSlice.pauseRequestSent);
