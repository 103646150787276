import { useEffect, useRef } from 'react';
import styles from './TakeSnapshot.module.css';

function TakeSnapshot({ videoElementId, snapshotFileType, onSnapshot, id, imageQuality = 1 }) {
    const canvasRef = useRef();

    const videoElement = document.getElementById(videoElementId);
    const container = videoElement.closest(`div[id="video-container__${videoElementId}"]`);

    const intrinsicWidth = videoElement.videoWidth;
    const intrinsicHeight = videoElement.videoHeight;

    const { x: videoX, y: videoY, height: videoHeight } = videoElement.getBoundingClientRect();
    const { width: containerWidth, height: containerHeight, x: containerX, y: containerY } = container.getBoundingClientRect();
    const scale = intrinsicHeight / videoHeight;

    const x = Math.abs(videoX - containerX) * scale || 0;
    const y = Math.abs(videoY - containerY) * scale || 0;
    const width = containerWidth * scale || intrinsicWidth;
    const height = containerHeight * scale || intrinsicHeight;

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.drawImage(videoElement, x, y, width, height, 0, 0, canvas.width, canvas.height);
        const base64 = canvas.toDataURL(snapshotFileType || 'image/jpeg', imageQuality);
        onSnapshot(id, base64);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoElement, intrinsicWidth, intrinsicHeight]);

    return <canvas className={styles.canvas} ref={canvasRef} width={intrinsicWidth} height={intrinsicHeight}></canvas>;
}

export default TakeSnapshot;
