import { BaseCameraStream } from './BaseCameraStream';

export class CameraStreamPlayer extends BaseCameraStream {
    maskedUrl;
    playerEndpointId;
    #playerEndpoint;

    //promise (controlled outside)
    activationPromise;

    //state
    live;
    #activating;

    constructor(kurentoClient, camConfig) {
        super();
        const {
            camId,
            videoData: { playerEndpointId, streamType, url },
        } = camConfig;

        this.playerEndpointId = playerEndpointId;
        this.#setVideoUrl(url);
        this.fullHeader = `[CameraStreamPlayer cameraId=${camId} streamType=${streamType}]`;
        this.kurentoClient = kurentoClient;
    }

    async activate() {
        try {
            if (!this.playerEndpointId || this.#activating) return;
            this.#activating = true;
            this.#playerEndpoint = await this.getMediaObjectById(this.playerEndpointId);
            this.log('activate(): activated PlayerEndpoint');
            this.#activating = false;
            this.live = true;
        } catch (err) {
            this.#activating = false;
            this.handle(err, 'activate()');
        }
    }
    async connect(webRTCEndpoint, action = 'connect') {
        try {
            await this.connectMediaElements(this.#playerEndpoint, webRTCEndpoint, action);
            this.log(`${action}(): ${action}ed WebRTCEndpoint id=${webRTCEndpoint.id}`);
        } catch (err) {
            this.handle(err, `${action}()`);
        }
    }
    async disconnect(webRTCEndpoint) {
        try {
            await this.connect(webRTCEndpoint, 'disconnect');
        } catch (err) {
            this.handle(err, 'disconnect()');
        }
    }
    async update(playerEndpointId) {
        try {
            this.playerEndpointId = playerEndpointId;
            this.live = false;
            await (this.activationPromise = this.activate());
            this.log(`update(): updated CameraStreamPlayer to new config. New PlayerEndpointId=${playerEndpointId}`);
        } catch (err) {
            this.handle(err, 'update()');
        }
    }

    #setVideoUrl(videoUrl) {
        this.videoUrl = videoUrl;
        this.maskedUrl = videoUrl?.replaceAll(/(\w+):\/\/(\w+):(\S+)@(\S+)/gi, '$1://$2:xxx@$4') || 'NoURL';
    }
}
