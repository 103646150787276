export const formConfig = {
    formId: 'cameraConfiguration',

    validation: {
        deleteForbidden: ['mainUrl', 'subUrl', 'externalId'],
        returnNullIfNoValue: ['Level 1', 'Level 2'],
    },

    fields: [
        {
            label: 'Name',
            fieldName: 'name',
            type: 'text',
            initialValue: '',
            required: false,
            disabled: true,
        },
        {
            label: 'Camera type',
            fieldName: 'cameraType',
            type: 'text',
            initialValue: '',
            required: false,
            disabled: true,
        },
        {
            label: 'Location',
            fieldName: 'location.name',
            type: 'text',
            required: false,
            initialValue: null,
            disabled: true,
        },
        {
            label: 'Gate',
            fieldName: 'gate.name',
            type: 'text',
            required: false,
            initialValue: null,
            disabled: true,
        },

        {
            label: 'Direction',
            fieldName: 'direction',
            type: 'text',
            initialValue: '',
            required: false,
            disabled: true,
        },
        {
            label: 'TWA configuration',
            fieldName: 'twaConfig',
            type: 'textarea',
            initialValue: '',
            required: false,
            disabled: true,
            inputProps: { rows: 5, columns: 6, style: { width: '100%', minHeight: '7rem' } },
        },
        {
            label: 'External Id',
            fieldName: 'externalId',
            type: 'text',
            initialValue: '',
            required: false,
            disabled: false,
        },

        {
            label: 'Level 1',
            fieldName: 'level1',
            type: 'number',
            initialValue: '',
            required: false,
            disabled: false,
        },

        {
            label: 'Level 2',
            fieldName: 'level2',
            type: 'number',
            initialValue: '',
            required: false,
            disabled: false,
        },
        {
            label: 'Talk URL',
            fieldName: 'talkUrl',
            type: 'text',
            initialValue: '',
            required: false,
            disabled: false,
        },
        {
            label: 'Main stream URL',
            fieldName: 'mainUrl',
            type: 'text',
            initialValue: '',
            required: false,
            disabled: true,
        },
        {
            label: 'Sub stream URL',
            fieldName: 'subUrl',
            type: 'text',
            initialValue: '',
            required: false,
            disabled: true,
        },
    ],
};
