import * as beaver from 'beaver-logger';
import * as aiqConfig from 'config';
import { store } from 'store/store';

const aiqLoggerConfig = {
    url: `${aiqConfig.GENERAL.ADDRESSES.HTTP.MAIN}/logs`,
    logLevel: -1,
    flushInterval: 1 * 60 * 1000,
    enableSendBeacon: true,
};

class AIQLogger {
    #logger;
    #config;
    #windowUid;
    constructor(config) {
        this.#config = config;
        this.#logger = beaver.Logger(config);
        this.#setTracking();
        this.#registerEventListeners();
        this.#wireWindowConsole();
    }

    /**
     *
     * @param {object} content
     * @param {string} event any string is acceptable, default='log'
     * @param {string} level posible options [debug, info, warn, error]  ||  default='info'
     */
    log(content, event = 'log', level = 'info') {
        this.#logger[level](event, content);
    }

    #logError(error) {
        this.log(
            {
                message: error.message || error?.reason?.message || error.reason,
                type: error.type,
                code: error.code,
            },
            error.type,
            'error'
        );
    }

    #setTracking() {
        this.#logger.addTrackingBuilder(() => ({ uid: this.#getWindowUid() }));
        this.#logger.addMetaBuilder(() => this.#logger.track());
    }

    #getWindowUid() {
        if (this.#windowUid) return this.#windowUid;
        return (this.#windowUid = store?.getState?.()?.transactions?.sharedWorker?.uid);
    }

    #wireWindowConsole() {
        const consoleLog = console.log;
        const consoleError = console.error;
        const consoleWarn = console.warn;

        const newLog = function ({ logToConsole, logEvent, level }, ...contentToLog) {
            logToConsole(...contentToLog);
            this.log(contentToLog, logEvent, level);
        };

        console.log = newLog.bind(this, {
            logToConsole: consoleLog,
            logEvent: 'console.log',
            level: 'info',
        });

        console.error = newLog.bind(this, {
            logToConsole: consoleError,
            logEvent: 'console.error',
            level: 'warn',
        });

        console.warn = newLog.bind(this, {
            logToConsole: consoleWarn,
            logEvent: 'console.warn',
            level: 'warn',
        });
    }

    #registerEventListeners() {
        window.addEventListener('error', this.#logError.bind(this));
        window.addEventListener('unhandledrejection', this.#logError.bind(this));
    }

    removeEventListeners() {
        window.removeEventListener('error', this.#logError.bind(this));
        window.removeEventListener('unhandledrejection', this.#logError.bind(this));
    }

    resetLogger() {
        this.#logger.flush();
        this.#logger = beaver.Logger(this.#config);
    }
}

export const aiqLogger = new AIQLogger(aiqLoggerConfig);
