export class BaseCameraStream {
    async getMediaObjectById(id) {
        const mediaObjectType = this.#getMediaObjectType(id);
        try {
            this.log(`getMediaObjectById(): getting ${mediaObjectType}Id=${id}`);
            const mediaObject = await this.kurentoClient.getMediaobjectById(id, this.#callback);
            this.log(`getMediaObjectById(): returning ${mediaObjectType}`);
            return mediaObject;
        } catch (err) {
            this.handle(err, `getMediaObjectById(): id=${id}`, `${mediaObjectType} not found`);
        }
    }

    async addTagToMediaObject(mediaObject, key, value) {
        try {
            if (!mediaObject) throw new Error('No MediaObject');
            await mediaObject.addTag(key, value, this.#callback);
            this.log(`addTagToMediaObject(): Added tag ${key}=${value}`);
        } catch (err) {
            const id = mediaObject?.id;
            this.handle(err, `addTagToMediaObject(): id=${id}`, `Adding tag to ${this.#getMediaObjectType(id)} failed`);
        }
    }

    async getMediaObjectTags(mediaObject) {
        try {
            if (!mediaObject) throw new Error('No MediaObject');
            return await mediaObject.getTags(this.#callback);
        } catch (err) {
            const id = mediaObject?.id;
            this.handle(err, `getMediaObjectTags(): id=${id}`, `Getting tags for ${this.#getMediaObjectType(id)} failed`);
        }
    }

    async getMediaObjectCreationTime(mediaObject) {
        try {
            if (!mediaObject) throw new Error('No MediaObject');
            return await mediaObject.getCreationTime(this.#callback);
        } catch (err) {
            const id = mediaObject?.id;
            this.handle(err, `getMediaObjectCreationTime(): id=${id}`, `Getting ${this.#getMediaObjectType(id)} creation time failed`);
        }
    }

    async configureMediaObject(mediaObject, action, value) {
        const id = mediaObject?.id;
        const mediaObjectType = this.#getMediaObjectType(id);
        try {
            if (!mediaObject) throw new Error('No MediaObject');
            this.log(`configureMediaObject():Setting ${action}=${value} on ${mediaObjectType} id=${id}`);
            await mediaObject[action](value, this.#callback);
        } catch (err) {
            this.handle(err, `configureMediaObject(): action=${action} id=${id} `, `Configuring ${mediaObjectType} failed`);
        }
    }

    async connectMediaElements(source, sink, action = 'connect') {
        try {
            if (!source) throw new Error('No Source Element');
            if (!sink) throw new Error('No Sink Element');
            const callback = action === 'connect' ? this.#callback : () => {};
            await source[action](sink, callback);
        } catch (err) {
            this.handle(err, `connectMediaElements():`, `Connecting media elements failed`);
        }
    }

    //mediaPipeline
    async createMediaPipelineElement(mediaPipeline, mediaElement = 'WebRtcEndpoint') {
        try {
            return await mediaPipeline.create(mediaElement);
        } catch (err) {
            this.handle(err, `createMediaPipelineElement()`, `Error creating MediaPipeline element`);
        }
    }

    //webRTCEndpoint

    #getMediaObjectType(mediaObjectId) {
        return mediaObjectId?.split('.')?.at(-1);
    }

    #callback(err, result) {
        if (err) throw err;
        return result;
    }

    log(message, mode = 'log') {
        console[mode](`${this.fullHeader} ${message}`);
    }

    handle(err, message, rethrow = true) {
        if (err) {
            this.log(`${message}: ${err.message ?? err}`, 'error'); // log real error so we can use it do debug in logs, and render our custom error
            if (rethrow) {
                const rethrowMessage = typeof rethrow === 'string' ? rethrow : (err?.message ?? err);
                throw new Error(rethrowMessage);
            }
        }
    }
}
