import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allDesks: [],
    currentDesk: {},
    isActiveDeskConfirmed: true,
    fetchingDesksError: false,
    fetchingDesksInProgress: undefined,
    isDeskLoading: false,
    maxDeskTransactions: 0,
    expectedFinishedTransactionMillis: 0,
    deskWarnings: { warnings: [] },
    pauseRequestSent: false,
};

const desksSlice = createSlice({
    name: 'desks',
    initialState,
    reducers: {
        fetchDesksStart(state) {
            state.fetchingDesksInProgress = true;
            state.fetchingDesksError = false;
        },

        fetchDesksSuccess(state, { payload }) {
            state.fetchingDesksInProgress = false;
            state.allDesks = payload;
        },

        fetchDesksFailed(state, { payload }) {
            state.fetchingDesksInProgress = false;
            state.fetchingDesksError = payload;
        },

        setCurrentDeskInactive(state) {
            state.allDesks.forEach((desk) => {
                if (desk.id === state.currentDesk.id) desk.status = 'INACTIVE';
            });
        },

        setCurrentDesk(state, { payload: desk }) {
            state.currentDesk = desk;
            if (desk) state.pauseRequestSent = false;
            if (!Object.keys(desk).length) state.isActiveDeskConfirmed = true;
        },

        selectDesk() {},

        setMaxDeskTransactions(state, { payload }) {
            state.maxDeskTransactions = payload;
        },

        setExpectedFinishedTransactionMillis(state, { payload }) {
            state.expectedFinishedTransactionMillis = payload;
        },

        replaceDesk(state, { payload: deskToReplace }) {
            state.allDesks = state.allDesks.map((desk) =>
                desk.id === deskToReplace.id ? { ...deskToReplace, ...(desk.locationNames && { locationNames: desk.locationNames }) } : desk
            );
        },
        setIsActiveDeskConfirmed(state, { payload: isActiveDeskConfirmed }) {
            state.isActiveDeskConfirmed = isActiveDeskConfirmed;
        },

        setDeskWarnings(state, { payload: { deskWarnings, lastChecked } }) {
            state.deskWarnings.warnings = deskWarnings.reduce((warnings, { args, code }) => {
                if (code === 'INACTIVE_DESK_WITH_TRANSACTIONS') {
                    warnings.push(`${args[0]} (${args[1]})`);
                }
                state.deskWarnings.lastChecked = lastChecked;
                return warnings;
            }, []);
        },

        setIsDeskLoading(state, { payload: isLoading }) {
            state.isDeskLoading = isLoading;
        },
        setPauseRequestSent(state, { payload: pauseRequestSent }) {
            state.pauseRequestSent = pauseRequestSent;
        },
        checkDeskWarnings() {},
    },
});

export const desksReducer = desksSlice.reducer;
export const desksActions = desksSlice.actions;
