import { createSelector } from '@reduxjs/toolkit';
import { store } from 'store/store';
import { readFromObj } from 'utils/utils';
import { RELATION_TYPE_DROPDOWN_OPTION, INDUSTRY_TYPE_DROPDOWN_OPTION } from 'utils/constants';

const emptyArray = [];
const emptyObj = {};

export const selectFormsSlice = (state) => state.forms;

export const selectFormsObject = createSelector([selectFormsSlice], (formsSlice) => formsSlice.forms);
export const selectSubmitFormInProgress = createSelector([selectFormsSlice], (formsSlice) => formsSlice.submitFormInProgress);

export const selectDiscardTransactionInProgress = createSelector([selectFormsSlice], (formsSlice) => formsSlice.discardTransactionInProgress);

export const selectIsTransactionInProcessingStateById = (id) =>
    createSelector(
        [selectSubmitFormInProgress, selectDiscardTransactionInProgress, (_, formId = id) => formId],
        (submittingTransactionId, discardingTransactionId, transactionId) =>
            submittingTransactionId === transactionId || discardingTransactionId === transactionId
    );

export const selectTruckTransactionFormConfig = createSelector([selectFormsSlice], (formsSlice) => formsSlice.formsConfig.truckTransactionForm);

export const selectFormById = (id) => createSelector([selectFormsObject, (_, formId = id) => formId], (forms, id) => forms[id]);

export const selectTransactionDataByTransactionId = (id) =>
    createSelector([selectFormsObject, (_, tranasctionId = id) => tranasctionId], (forms, id) => forms[id]?.transactionData);

export const selectRelaysConfigByTransactionId = (id) =>
    createSelector([selectFormsObject, (_, tranasctionId = id) => tranasctionId], (forms, id) => forms[id]?.relaysConfig || emptyArray);

export const selectFormConfigByTransactionId = (id) =>
    createSelector([selectFormsObject, (_, tranasctionId = id) => tranasctionId], (forms, id) => forms[id]?.formConfig);

export const selectActiveSelectionTableNameByTransactionId = (id) =>
    createSelector([selectFormsObject, (_, transactionId = id) => transactionId], (forms, id) => forms[id]?.activeSelectionTableName);

export const selectTransactionFormStateByTransactionId = (transactionId) =>
    createSelector([selectFormsObject, (_, id = transactionId) => id], (forms, id) => forms[id]?.form || emptyObj);

export const createFormStateByTransactionIdSelector = (id) =>
    createSelector([selectTransactionFormStateByTransactionId(id)], (formState) => formState);

export const createTransactionEncodedImagesByTransactionIdSelector = (id) =>
    createSelector([selectTransactionFormStateByTransactionId(id)], (formState) => {
        let encodedImages = emptyArray;
        if (formState?.encodedImages) encodedImages = formState?.encodedImages;
        if (formState?.cameraSnapshots) encodedImages = [...encodedImages, ...(formState?.cameraSnapshots || emptyArray)];
        return encodedImages;
    });

export const createFormFieldsByTransactionIdSelector = (id) => {
    return createSelector(
        [selectFormConfigByTransactionId(id), selectRelaysConfigByTransactionId(id)],

        (formConfig, relaysConfig) => {
            if (!formConfig) return { relaysConfig };

            let formFields = [];
            let automationFields = [];
            const { name, fields } = formConfig;
            const transactionData = store.getState().forms?.forms[id]?.transactionData;

            fields.forEach((oldField) => {
                const field = structuredClone(oldField);
                const { lookup } = field;
                const initialValue = transactionData ? readFromObj(transactionData, field.dataField) : 'aiq.fieldNotExist';
                const referenceField = field.referenceField;
                if (referenceField) {
                    const initialReferenceValue = transactionData ? readFromObj(transactionData, field.referenceField) : 'aiq.fieldNotExist';
                    initialReferenceValue !== 'aiq.fieldNotExist' && (field.initialReferenceValue = initialReferenceValue);
                }

                if (initialValue !== 'aiq.fieldNotExist') {
                    field.initialValue = initialValue;

                    if (field.automationField) automationFields.push(field.dataField);
                    if (lookup && lookup.dataSource) {
                        const displayExpr = lookup.displayExpr;

                        if (
                            field.dataField === 'person.fullName' ||
                            (field.dataField === 'person.companyName' && Array.isArray(lookup?.dataSource))
                        ) {
                            const idField = field.lookup.referenceExpr;
                            field.lookup.dataSource = field.lookup.dataSource.reduce((newLookup, entry) => {
                                const registered = newLookup.find(({ [idField]: id }) => id === entry[idField]);

                                const alert = {
                                    color: entry.color,
                                    note: entry.note,
                                    name: entry.name,
                                    accessPeriod: {
                                        accessType: entry.accessType,
                                        periodType: entry.periodType,
                                        weekdays: entry.weekdays,
                                        startTime: entry.startTime,
                                        endTime: entry.endTime,
                                    },
                                };
                                if (registered) {
                                    if (!alert.color) registered.alerts.push(alert);
                                    else registered.alerts.unshift(alert);
                                } else {
                                    // eslint-disable-next-line no-unused-vars
                                    const { color, note, name, ...newEntry } = entry;
                                    newEntry.alerts = [alert];
                                    newEntry.relationType = RELATION_TYPE_DROPDOWN_OPTION[entry.relationTypeId]?.label || '';
                                    newEntry.industryType = INDUSTRY_TYPE_DROPDOWN_OPTION[entry.industryType]?.label || '';
                                    newLookup.push(newEntry);
                                }
                                return newLookup;
                            }, []);
                        }

                        if (!displayExpr) field.lookup.dataSource = lookup.dataSource.map(({ value }) => value ?? '').sort();
                        else if (field.dataField !== 'vehicle.transportType' && field.dataField !== 'announcement.transportType') {
                            let duplicates = {};
                            field.lookup.dataSource.sort((first, second) => {
                                const b = second[displayExpr];
                                const a = first[displayExpr];
                                if (a === b) {
                                    duplicates[a] ?? (duplicates[a] = 0);
                                    !first.duplicate && (first.duplicate = ++duplicates[a]);
                                    !second.duplicate && (second.duplicate = ++duplicates[a]);
                                }
                                return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
                            });
                        }
                    }

                    formFields.push(field);
                }
            });
            return { name, formFields, relaysConfig, automationFields };
        }
    );
};
