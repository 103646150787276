import { Divider, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { focusNextInputField } from '../Options';
import AdditionalInfo from './AdditionalInfo';
import AlertInfo from './AlertInfo';
import styles from './Option.module.css';

function Option({
    option,
    onChange,
    setDropdownOpened,
    setInputValue,
    activeOption,
    setHoveredOption,
    hoveredOption,
    selectedOption,
    setSelectedOptionIndex,
    optionIndex,
    getInputValue,
    hasAlerts,
    referenceExpr,
    setReferenceValue,
    onSelect,
}) {
    const optionLabel = getInputValue(option);
    const optionRef = useRef();

    useEffect(() => {
        if (activeOption) setSelectedOptionIndex(optionIndex);
    }, [activeOption, optionIndex, setSelectedOptionIndex]);

    useEffect(() => {
        if (selectedOption)
            optionRef.current.scrollIntoView({
                block: 'nearest',
                inline: 'start',
            });
    }, [selectedOption]);

    const baseStyles = `${styles['option']}`;
    const stateStyles = activeOption
        ? styles['option--active']
        : hoveredOption
          ? styles['option--highlighted']
          : selectedOption
            ? styles['option--selected']
            : '';

    const optionStyles = `${styles['option-container']} ${baseStyles} ${stateStyles}`;
    const optionStylesWithAlerts = `${baseStyles} ${styles['option-container--alerts']} ${stateStyles}`;

    const mouseDownHandler = (e) => {
        setInputValue(getInputValue(option));
        onSelect?.(option);
        onChange(e, option);
        setDropdownOpened(false);
        const autocompleteContainer = optionRef.current.closest('div[class*="AIQAutocomplete"]');
        const currentInputFieldId = +autocompleteContainer.querySelector('input').id;
        referenceExpr && setReferenceValue(option?.[referenceExpr]);
        focusNextInputField(currentInputFieldId);
    };

    const mouseOverHandler = () => {
        setHoveredOption(option);
        setSelectedOptionIndex(optionIndex);
    };
    const alerts = option.alerts || null;

    if (hasAlerts) {
        return (
            <div className={optionStylesWithAlerts} onMouseDown={mouseDownHandler} onMouseOver={mouseOverHandler}>
                <Typography fontWeight={600} ref={optionRef}>
                    {optionLabel}
                </Typography>
                <Divider />
                <AdditionalInfo option={option} hasAlerts={hasAlerts} />
                <Divider />
                <AlertInfo alerts={alerts} />
            </div>
        );
    }

    return (
        <div className={optionStyles} onMouseDown={mouseDownHandler} onMouseOver={mouseOverHandler}>
            <p className={styles[`option-text}`]} ref={optionRef}>
                {optionLabel}
            </p>
        </div>
    );
}

export default Option;
