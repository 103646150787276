import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';
import { GENERAL } from 'config';
import { PROFILE } from 'config';
import { getCookie } from 'utils/utils';
import { store } from 'store/store';

const webSocketGeneralConfig = {
    timesToTryReconnect: 500,
    reconnectDelay: 1000,
};

const stompClientConfig = {
    brokerURL: GENERAL.ADDRESSES.ALERT,
    connectHeaders: {},
    debug: function (str) {
        if (str !== 'Received data' && str !== '<<< PONG' && str !== '>>> PING') console.log(str);
    },

    reconnectDelay: webSocketGeneralConfig.reconnectDelay, //in case ws connection fails or disconected, recconection will be tried [reconnectDelay]ms. Seems to be infinite loop of retries
    connectionTimeout: 30000, //it may occur that connection is succesful, but we never get back response on 'CONNECT' frame sent, in that case after [connectionTimeout]ms ws connection is closed and  new reconnect will be scheduled in [reconnectDelay]ms. Otherwise (without this property) initial ws connection remains open and waits forever.
    heartbeatIncoming: 60000,
    heartbeatOutgoing: 60000,
};

class AlertService {
    constructor() {
        this.counter = 0;
        //this.alertClient = new Client(config);
    }

    showChromeNotification(alert) {
        const time = `${alert.startsAt?.slice(0, 10)} ${alert.startsAt?.slice(11, 16)}`;
        // eslint-disable-next-line no-unused-vars
        const notification = new Notification(
            `${alert.name} service is ${alert.status === 'firing' ? 'down' : 'up'}, instance ${alert.instance} ${
                alert.startsAt ? `, since ${time}` : ''
            }`
        );
    }

    showNotifications(message, alertCookie) {
        const newAlert = JSON.parse(message.body);
        const time = `${newAlert.startsAt?.slice(0, 10)} ${newAlert.startsAt?.slice(11, 16)}`;
        store.dispatch(
            snackbarActions.enqueueSnackbar({
                message: `${newAlert.name} service is ${newAlert.status === 'firing' ? 'down' : 'up'}, instance ${newAlert.instance}${
                    newAlert.startsAt ? `, since ${time}` : ''
                }`,
                options: { variant: `${newAlert.status === 'firing' ? 'error' : 'success'}` },
            })
        );
        Notification.requestPermission();
        if (Notification.permission === 'granted' && alertCookie) {
            this.showChromeNotification(newAlert);
            return;
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted' && alertCookie) {
                    this.showChromeNotification(newAlert);
                }
            });
        }
    }

    activate() {
        let cookieAiq;

        switch (PROFILE) {
            case 'development':
                cookieAiq = getCookie('aiqD');
                break;
            case 'staging':
                cookieAiq = getCookie('aiqS');
                break;
            case 'production':
                cookieAiq = getCookie('aiqP');
                break;
            default:
                cookieAiq = getCookie('aiq');
        }

        if (!this.alertClient) {
            return;
        }

        this.alertClient.activate();
        this.alertClient.onConnect = () => {
            this.alertClient.subscribe('/user/topic/all', (message) => {
                this.showNotifications(message, cookieAiq);
            });

            this.alertClient.subscribe('/topic/all', (message) => {
                this.showNotifications(message, cookieAiq);
            });
        };
    }

    deactivate() {
        if (!this.alertClient) {
            return;
        }
        this.alertClient.deactivate();
    }

    onWebSocketError() {
        this.alertClient.onWebSocketError = function () {
            this.counter++;

            if (this.counter === webSocketGeneralConfig.timesToTryReconnect) {
                store.dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: `WebSocket Alert Service error`,
                        options: { variant: 'error' },
                    })
                );
                this.counter = 0;
            }
        };
    }

    onStompError() {
        this.alertClient.onStompError = function () {
            store.dispatch(
                snackbarActions.enqueueSnackbar({
                    message: `Stomp Alert Service error`,
                    options: { variant: 'error' },
                })
            );
        };
    }
}

export const alertService = new AlertService(stompClientConfig);
