import styles from './Alerts.module.css';

import Alert from './Alert/Alert';

function Alerts({ alerts, mode, containerClasses }) {
    const classes = `${styles['alert-container']}${containerClasses ? ` ${containerClasses}` : ''}`;

    return (
        <>
            {alerts && !!alerts.length && (
                <div className={classes}>
                    {alerts.map(({ text, color, note, name, accessPeriod }) => {
                        return (
                            <Alert
                                key={`${text}${color}`}
                                text={text}
                                name={name}
                                note={note}
                                color={color}
                                mode={mode}
                                accessPeriod={accessPeriod}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
}

export default Alerts;
