import { useCallback, useState } from 'react';
import styles from './Snapshots.module.css';
import Snapshot from './snapshot/Snapshot';

function Snapshots({ cameraId, cameraType, onRemoveSnapshot, snapshots }) {
    const [enlargedSnapshot, setEnlargedSnapshotId] = useState('');

    const enlargeSnapshotHandler = useCallback(
        (id) => {
            let idToSet = !enlargedSnapshot || enlargedSnapshot !== id ? id : '';
            setEnlargedSnapshotId(idToSet);
        },
        [enlargedSnapshot]
    );

    const snapshotsArr =
        !!snapshots &&
        !!snapshots.length &&
        snapshots.map(({ snapshot, id }, index) => (
            <Snapshot
                key={id}
                gridPosition={index + 1}
                src={snapshot}
                cameraId={cameraId}
                cameraType={cameraType}
                snapshotId={id}
                onRemoveSnapshot={onRemoveSnapshot}
                onEnlargeSnapshot={enlargeSnapshotHandler}
                enlargedSnapshotId={enlargedSnapshot}
            />
        ));

    return (
        <div className={styles['camera-section__snapshots']}>
            <p>Snapshots</p>
            {snapshotsArr && <div className={styles['snapshots']}>{snapshotsArr}</div>}
        </div>
    );
}

export default Snapshots;
