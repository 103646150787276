import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { tableControlActions } from './tableControlSlice';
import * as aiqConfig from 'config';
import { notificationHandler } from 'utils/utils';
import { selectTableConfigFetchingInProgress, selectTableConfigByConfigName } from './tableControlSelectors';
import { axiosAiq } from 'config';
import { transactionTableConfig } from './transactionTablesConfig';

function* fetchTableData({
    payload: {
        tableId,
        locationId,
        urlPath,
        pageNum = 0,
        size = aiqConfig.locationTransactionsTableRowsPerPage,
        append = false,
        fetchColumnsConfig = true,
        fetchLocationAnnouncements,
    },
}) {
    try {
        let dataUrlPath;
        let configUrlPath;
        let configName;
        let getLocationAnnouncements = false;

        if (!urlPath) {
            ({ dataUrlPath, configUrlPath, configName, getLocationAnnouncements } = yield call(readTableConfig, tableId, fetchLocationAnnouncements));
            dataUrlPath = dataUrlPath(pageNum, size, locationId);
        } else {
            dataUrlPath = urlPath;
        }

        const response = yield axiosAiq.get(dataUrlPath);
        const { data } = response;

        fetchColumnsConfig && (yield call(fetchColumnsConfigStart, configUrlPath, configName));

        yield put(
            tableControlActions.fetchTableDataSuccess({
                data,
                tableId,
                pageNum,
                append,
            })
        );

        if (getLocationAnnouncements) {
            yield put(
                tableControlActions.fetchTableDataStart({
                    tableId: `${locationId}_manualAnnouncements`,
                    locationId,
                    pageNum,
                    size,
                    append,
                })
            );
        }
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: tableControlActions.fetchTableDataFailed,
            title: 'Error fetching table data',
        });
    }
}

function* fetchColumnsConfigStart(tableConfigUrlPath, configName) {
    try {
        const [configNameFetchingInProgress, tableConfig] = yield all([
            select(selectTableConfigFetchingInProgress),
            select(selectTableConfigByConfigName(configName)),
        ]);
        if (tableConfig || configNameFetchingInProgress === configName) return;

        yield put(tableControlActions.fetchColumnsConfigStart(configName));
        const response = yield axiosAiq.get(tableConfigUrlPath);
        const { data } = response;
        yield put(tableControlActions.fetchColumnsConfigSuccess(data));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: tableControlActions.fetchColumnsConfigFailed,
            title: 'Error fetching table configuration',
        });
    }
}

function readTableConfig(tableId, fetchLocationAnnouncements) {
    let dataUrlPath;
    let configUrlPath;
    let configName;
    let getLocationAnnouncements = false;
    let tableType;

    switch (true) {
        case tableId === transactionTableConfig.allLocationsTransactions.tableId:
            tableType = 'allLocationsTransactions';
            break;
        case tableId === transactionTableConfig.allLocationsAnnouncements.tableId:
            tableType = 'allLocationsAnnouncements';
            break;
        case typeof tableId === 'string' && tableId.endsWith('_manualAnnouncements'):
            tableType = 'singleLocationAnnouncements';
            break;
        default:
            tableType = 'singleLocationTransactions';
            fetchLocationAnnouncements && (getLocationAnnouncements = true);
    }

    ({ dataUrlPath, configUrlPath, configName } = transactionTableConfig[tableType]);

    return { dataUrlPath, configUrlPath, configName, getLocationAnnouncements };
}

function* onFetchTableDataStart() {
    yield takeEvery(tableControlActions.fetchTableDataStart.type, fetchTableData);
}
export function* tableControlSaga() {
    yield all([call(onFetchTableDataStart)]);
}
