import { useState, memo, useEffect } from 'react';
import styles from './Checkbox.module.css';
import { useDispatch } from 'react-redux';
import { formControlActions } from 'store/formControlSlice/formControlSlice';

function CheckBox({ label, id, initialState, onChange, disabled, formId, fieldName, forceFieldRegistration, required, value }) {
    const [checked, setChecked] = useState(initialState || false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!formId || value !== undefined) return;
        dispatch(
            formControlActions.registerNewFormField({
                formId,
                fieldName,
                initialValue: initialState ?? '',
                disabled: disabled && !forceFieldRegistration,
                required,
            })
        );

        if (required && initialState !== undefined) {
            dispatch(
                formControlActions.setFieldError({
                    formId,
                    fieldName,
                    error: false,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fieldName, formId, initialState, required, forceFieldRegistration, value]);

    const changeHandler = (e) => {
        const checked = e.currentTarget.checked;
        setChecked(checked);
        onChange(checked, e);
    };

    const checkedState = value !== undefined ? value : checked;

    const classes = `${styles['checkbox--container']}${disabled ? ` ${styles['checkbox--disabled']}` : ''}`;
    return (
        <div className={classes}>
            <input checked={checkedState} onChange={changeHandler} type="checkbox" id={id} disabled={disabled} />
            <label htmlFor={id}>{label}</label>
        </div>
    );
}

export default memo(CheckBox, compareProps);

function compareProps(oldProps, newProps) {
    return (
        oldProps.label === newProps.label &&
        oldProps.id === newProps.id &&
        oldProps.disabled === newProps.disabled &&
        oldProps.value === newProps.value
    );
}
