import { store } from 'store/store';
import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import PCMPlayer from './PCMPlayer';
import { talkConfig } from './TalkConfig';
import { axiosAiq } from 'config';
import { saveAs } from 'file-saver';
import { kurentoManager } from 'services/Kurento/KurentoManager';

export class TalkSession {
    cameraId;
    #RECORD_AUDIO = false;
    #PCM_WAV_HEADER_SIZE = 44;

    #audioSettings;

    #demoSession;
    #sessionId;
    #userStreamAudioTrack;
    #userStream;
    #userStreamPromise;
    #recorder;
    #audioContext;
    #gainNode;
    #webSocket;
    #player;

    #stopSession;
    #numPacketsFailed;
    #stopSessionInitiated;
    #initiatingSessionPromise;

    #packets = [];
    #initialPacket;
    #ignoreLeadingPackets = 20;

    #userStreamTracks;
    #recordedChunks = [];
    #onSessionEnd;

    #talkUrl;

    #iceCandidates = [];

    constructor(onSessionEnd) {
        this.#onSessionEnd = onSessionEnd;
        window.candidate = this.#iceCandidates;
    }

    // gatherIPs() {
    //     return new Promise((resolve) => {
    //         let peerConnection = new RTCPeerConnection(kurentoConfig.options); // use BE provided stun servers to gather all possible candidates
    //         peerConnection.createDataChannel('someChannel'); //need thist to start gathering candidates
    //         peerConnection.onicecandidate = (e) => {
    //             if (e.candidate) {
    //                 const candidate = e.candidate.candidate;

    //                 const hostnameMatch = /(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/;
    //                 const ipMatch = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;

    //                 const hostnames = candidate.match(hostnameMatch);
    //                 const ips = candidate.match(ipMatch);

    //                 ips?.forEach((ip) => this.#iceCandidates.push(ip));
    //                 hostnames?.forEach((hostname) => this.#iceCandidates.push(hostname));
    //             }
    //         };
    //         peerConnection.onicegatheringstatechange = (e) => {
    //             const state = e.target.iceGatheringState;
    //             if (state === 'complete') {
    //                 resolve(); //waiting to gather all candidates before resolving a promise
    //                 peerConnection.close(); //close peerconnection as we do not need it any more
    //                 peerConnection = null;
    //             }
    //         };
    //         peerConnection.createOffer().then(function (offer) {
    //             peerConnection.setLocalDescription(offer); // also needed to start gathering candidates
    //         });
    //     });
    // }

    ///////////////////userStream

    async #createUserStream() {
        try {
            this.#audioContext = new AudioContext({ sinkId: { type: 'none' }, latencyHint: 'interactive' });
            this.#gainNode = this.#audioContext.createGain();
            const destination = this.#audioContext.createMediaStreamDestination();
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    noiseSuppression: true,
                    echoCancellation: true,
                    autoGainControl: false,
                    // volume: 0,
                    channelCount: 1,
                },
            });
            this.#log(`#createUserStream(): Created user stream`);
            const source = this.#audioContext.createMediaStreamSource(stream);
            source.connect(this.#gainNode);

            this.#gainNode.connect(destination);

            this.#userStreamTracks = stream?.getTracks();
            this.#userStreamAudioTrack = stream?.getAudioTracks()?.[0];
            return destination.stream;
        } catch (error) {
            this.#handleError({
                message: 'ERROR GETTING USER AUDIO STREAM',
                error,
            });
        }
    }

    //

    //////////////////////////// media Recorder

    #createMediaRecorder(stream) {
        try {
            return new RecordRTCPromisesHandler(stream, {
                ...talkConfig.recorderConfig,
                recorderType: StereoAudioRecorder,
                ondataavailable: this.#manageRecordedBlob.bind(this),
            });
        } catch (error) {
            this.#handleError({
                message: 'ERROR CREATING MEDIA RECORDER',
                error,
            });
        }
    }

    #manageRecordedBlob(blob) {
        if (this.#initialPacket) {
            this.#initialPacket = false;
            this.#sendData(blob.slice(this.#PCM_WAV_HEADER_SIZE));
        } else {
            this.#packets.push(blob.slice(this.#PCM_WAV_HEADER_SIZE));
        }
        this.#RECORD_AUDIO && this.#recordedChunks.push(blob);
    }

    async #sendData(blob) {
        let payload;
        if (this.#stopSession && !this.#packets.length) return;

        if (blob) {
            payload = blob;
        } else {
            if (!this.#packets.length) await this.#waitForConditionFn(() => this.#packets.length || this.#stopSession);
            if (this.#packets.length) payload = new Blob(this.#packets.splice(0));
        }

        try {
            if (payload) this.#webSocket?.send(payload);
            await this.#sendData();
        } catch (error) {
            this.#log(error.message, 'error'); //in case sending of some packet fail  just log it to grafana

            ++this.#numPacketsFailed;

            if (this.#numPacketsFailed === talkConfig.terminateAfterNumOfPacketFailed) {
                this.#handleError({
                    message: 'ERROR SENDING VOICE DATA, TERMINATING TALK...',
                    reThrow: false,
                    dispatchErrorEvent: true,
                });

                await this.stop();
            } else {
                await this.#sendData();
            }
        }
    }

    async #waitForConditionFn(conditionFn) {
        await this.#wait(Math.round(talkConfig.timeSlice / 10));
        if (conditionFn()) return;
        await this.#waitForConditionFn(conditionFn);
    }

    ///

    //////////////////// Player

    #createPlayer() {
        const audioSettings = this.#audioSettings?.settings;
        this.#player = new PCMPlayer({
            encoding: '16bitInt',
            channels: 1,
            sampleRate: 8000,
            flushingTime: 2000,
            initialGain: audioSettings?.soundGain,
            filteringEnabled: audioSettings?.filterEnabled,
            filterType: audioSettings?.filterType,
            filterFrequency: audioSettings?.filterFrequency,
            filterQ: audioSettings?.filterQ,
            filterGain: audioSettings?.filterGain,
        });
    }
    ///

    ///////////////////// websocket

    async #createWebSocket(talkUrlObj) {
        const ws = new WebSocket(`${talkConfig.serverUrl}/talk`);
        this.#webSocket = ws;
        let initial = true;
        let resolve;
        let reject;
        let counter = 0;

        ws.onclose = (e) => {
            this.#log(`webSocket closed reason=${e.reason || 'unknown'}`);
            if (e.reason !== 'sessionStopped') {
                this.#showNotification('TALK MANAGER - WEB SOCKET CLOSED, TERMINATING TALK SESSION');
                reject();
                this.stop();
            }
        };
        ws.onmessage = (e) => {
            if (typeof e.data === 'string') {
                resolve();
                const { sessionId, gain, denoiser, filter } = JSON.parse(e.data);
                !this.#sessionId && (this.#sessionId = sessionId);
                initial && this.#log(`Received sessionId=${this.#sessionId} filterEnabled=${filter}, denoiserEnabled=${denoiser} gain=${gain}`);

                const audioSettings = this.#audioSettings?.settings;
                const changeDetected =
                    gain !== audioSettings?.backendGain ||
                    denoiser !== audioSettings?.backendDenoiserEnabled ||
                    filter !== audioSettings?.backendFilterEnabled;

                if (!changeDetected || (initial && audioSettings.hasPresetValues)) {
                    initial = false;
                    return;
                }

                if (this.#audioSettings) {
                    this.#audioSettings.backendFilterEnabled = filter;
                    this.#audioSettings.backendDenoiserEnabled = denoiser;
                    this.#audioSettings.backendGain = gain;
                    this.#audioSettings?.publish();
                    this.#audioSettings?.savePreset();
                }

                return;
            }

            if (++counter <= this.#ignoreLeadingPackets) {
                this.#log(`Dropping package #${counter}`);
                return;
            }

            e.data.arrayBuffer().then((arrayBuffer) => {
                this.#player?.feed(arrayBuffer);
            });
        };
        ws.onerror = (e) => {
            this.#log(`webSocket error ${e.message}`);
            this.#showNotification('TALK MANAGER - WEB SOCKET ERROR, TERMINATING TALK SESSION');
            reject();
            this.stop();
        };

        ws.onopen = () => {
            ws.send(JSON.stringify(talkUrlObj));
            const audioSettings = this.#audioSettings?.settings;
            if (audioSettings?.hasPresetValues) {
                const gain = audioSettings?.backendGain;
                const filter = audioSettings?.backendFilterEnabled;
                const denoiser = audioSettings?.backendDenoiserEnabled;
                this.#log(`ws-onOpen: Sending talkConfig preset gain=${gain}, filter=${filter}, denoiser=${denoiser}`);
                ws.send(JSON.stringify({ gain, filter, denoiser }));
            } else this.#log(`ws-onOpen: No preset available, talkService defaults will be used.`);
        };
        return new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });
    }

    //////

    changeTalkServiceSetting(setting, log = true) {
        const data = JSON.stringify(setting);
        log && this.#log(`sendBackendAudioSetting(): sendingBackendAudioSetting = ${data}`);
        if (data) this.#webSocket?.send(data);
    }

    flush() {
        this.#player?.flush();
        this.changeTalkServiceSetting({ flush: true });
    }

    async start({ talkUrl: cameraUrl }, cameraName) {
        this.#setStoreTalkUrl(cameraUrl);
        try {
            if (!this.#iceCandidates.length) {
                const ips = kurentoManager.getLocalIceCandidatesUsed();
                if (ips.length) this.#iceCandidates = ips;
                else throw new Error();
            }
            if (!this.#iceCandidates.length) await this.gatherIPs();
        } catch (error) {
            this.#handleError({
                message: 'ERROR GATHERING CANDIDATES FOR INITIATING AIQ TALK STANDALONE',
                error,
                reThrow: false,
                dispatchErrorEvent: true,
            });
            this.#setStoreTalkUrl('');
            return;
        }
        try {
            this.#log(
                `Sending start talkSession signal for cameraName="${cameraName}", cameraUrl="${cameraUrl}", webRtcIps=[${this.#iceCandidates}]`
            );
            await axiosAiq.post('/talk-integration/camera-start', { cameraName, cameraUrl, webRtcIps: this.#iceCandidates });
        } catch (error) {
            this.#handleError({
                message: 'ERROR SENDING REQUEST TO AIQ TALK STANDALONE',
                error,
                reThrow: false,
                dispatchErrorEvent: true,
            });
        }
        setTimeout(this.#setStoreTalkUrl, 2500, '');
    }

    #setStoreTalkUrl(talkUrl) {
        store.dispatch(
            transactionActions.setActiveTalkUrl({
                activeTalkUrl: talkUrl,
            })
        );
    }

    async stop() {}

    async legacyStart(talkUrlObj, audioSettings) {
        try {
            const talkUrl = talkUrlObj.talkUrl;
            if (talkUrl === this.#talkUrl) return;
            this.#talkUrl = talkUrl;
            store.dispatch(
                transactionActions.setActiveTalkUrl({
                    activeTalkUrl: talkUrlObj.talkUrl,
                })
            );
            if (this.isActive()) {
                this.#log(`startSession(): Stoping active session before proceeding`);
                await this.stop(false);
            }

            this.cameraId = audioSettings.cameraId;
            this.#audioSettings = audioSettings;
            this.#packets = [];
            this.#numPacketsFailed = 0;
            this.#stopSession = false;
            this.#initialPacket = true;
            this.#log(`startSession(): Starting session for talkUrl=${talkUrl}`);
            this.#createPlayer();
            await (this.#initiatingSessionPromise = this.#createWebSocket(talkUrlObj));
            this.#initiatingSessionPromise = null;
            this.#log(`startSession(): Creating userStream`);
            this.#userStream = await (this.#userStreamPromise = this.#createUserStream());
            this.#userStreamPromise = null;
            this.#log(`startSession(): Creating MediaRecorder`);
            this.#recorder = this.#createMediaRecorder(this.#userStream);
            this.#recorder.startRecording();
            store.dispatch(transactionActions.setTalkSessionStarting(false));
            this.#log(`startSession(): Session started, talkUrl=${this.#talkUrl}`);
        } catch (error) {
            this.#handleError({
                message: 'ERROR STARTING TALK SESSION',
                error,
                reThrow: false,
                dispatchErrorEvent: true,
            });
            await this.stop();
        }
    }

    async legacyStop(removeActiveTalkUrl = true) {
        try {
            if (this.#stopSessionInitiated) return; // in case of multiple clicks while session is in starting phase (to prevent running multiple instancces of stopSesssion method)
            this.#stopSessionInitiated = true;

            if (this.#initiatingSessionPromise || this.#userStreamPromise) {
                //in case stopSession() is called before session actualy started
                try {
                    this.#log(`stopSession(): Awaiting initiating promises`);
                    this.#initiatingSessionPromise && (await this.#initiatingSessionPromise);
                    this.#userStreamPromise && (await this.#userStreamPromise);
                    this.#log(`stopSession(): Awaited initiating promises`);
                    // eslint-disable-next-line
                } catch {} // rejection will already be logged from startSession() catch
            }
            this.#initiatingSessionPromise = null;
            this.#userStreamPromise = null;
            await this.#wait(talkConfig.timeSlice); //to ensure that last chunk is retreived from recorder

            this.#closeWebsocket(1000, 'sessionStopped');
            this.#destroyPlayer();
            let blob = await this.#stopRecorder();
            this.#stopUserStream();
            await this.#closeAudioContext();

            if (this.#demoSession) {
                await this.stopDemoFile();
                this.#log(`stopSession(): Stopped demo file`);
            }

            if (this.#RECORD_AUDIO) {
                await this.#getRecordedFiles(blob, {
                    mainFile: true,
                    packets: false,
                });
                this.#log(`stopSession():Recorded file(s) saved`);
                this.#recordedChunks = [];
            }

            if (removeActiveTalkUrl) {
                store.dispatch(
                    transactionActions.setActiveTalkUrl({
                        activeTalkUrl: '',
                    })
                );
                this.#talkUrl = '';
            }
            this.cameraId && this.#onSessionEnd(this.cameraId);

            this.#sessionId = '';
            this.#audioSettings = null;
            this.cameraId = '';
            this.#log(`stopSession(): Success`);
        } catch (error) {
            this.#handleError({
                message: 'ERROR STOPING TALK SESSION',
                error,
                reThrow: false,
            });
        }
        this.#stopSessionInitiated = false;
    }

    apply(setting, value) {
        const { micGain } = this.#audioSettings.settingNames;

        if (setting === micGain && this.#gainNode && this.#audioContext) {
            this.#log(`apply(): ${micGain}=${value}`);
            this.#gainNode.gain.setValueAtTime(value, this.#audioContext.currentTime);
        } else if (this.#player?.[setting]) {
            this.#log(`apply(): ${setting}=${value}`);
            this.#player?.[setting]?.(value);
        }
    }

    #closeWebsocket(code, reason) {
        if (this.#webSocket) {
            this.#webSocket.close(code, reason);
            this.#webSocket = null;
            this.#log(`#closeWebsocket(): Closed WS`);
        }
    }

    #destroyPlayer() {
        if (this.#player) {
            this.#player.destroy();
            this.#player = null;
            this.#log(`#destroyPlayer(): Player destroyed`);
        }
    }

    async #stopRecorder() {
        if (this.#recorder) {
            let blob;
            await this.#recorder.stopRecording();
            if (this.#RECORD_AUDIO) blob = await this.#recorder.getBlob();
            await this.#recorder.destroy();
            this.#recorder = null;
            this.#log(`#stopRecorder(): StoppedMediaRecorder`);
            return blob;
        }
    }

    #stopUserStream() {
        this.#userStreamTracks &&
            this.#userStreamTracks.forEach((track) => {
                track.stop();
            });

        this.#userStreamTracks = null;
        this.#userStream = null;
        this.#userStreamAudioTrack = null;
    }

    async #closeAudioContext() {
        this.#gainNode = null;
        if (this.#audioContext) {
            await this.#audioContext.close();
            this.#audioContext = null;
            this.#log(`stopSession(): Stopped Audio context`);
        }
    }

    async #getRecordedFiles(blob, { mainFile, packets } = {}) {
        for (let i = 0; i < this.#recordedChunks.length; i++) {
            if (i === 0 && mainFile) {
                const url = URL.createObjectURL(blob);
                saveAs(url, `main`);
                URL.revokeObjectURL(url);
            }
            if (!packets) break;
            await this.#wait(100);
            const url = URL.createObjectURL(this.#recordedChunks[i]);
            saveAs(url, `test${String(i + 1).padStart(3, '0')}`);
            URL.revokeObjectURL(url);
        }
    }

    async playDemoFile(talkUrl, sessionId) {
        try {
            store.dispatch(transactionActions.setActiveDemoTalkUrl({ talkUrl }));
            this.#sessionId = sessionId;
            this.#demoSession = true;
            let url = talkConfig.serverUrl.replace(/^.{2}/g, 'http');
            await axiosAiq.post(`${url}/test/start/${sessionId}`, { talkUrl });
        } catch (error) {
            this.#handleError({
                message: 'ERROR PLAYING DEMO AUDIO FILE',
                error,
                reThrow: false,
            });
        }
        this.#sessionId = '';
        this.#demoSession = false;
        store.dispatch(transactionActions.setActiveDemoTalkUrl({ talkUrl: '' }));
    }

    async stopDemoFile() {
        try {
            const sessionId = this.#sessionId;
            if (!sessionId) return;
            let url = talkConfig.serverUrl.replace(/^.{2}/g, 'http');
            await axiosAiq.post(`${url}/test/stop/${sessionId}`);
            this.#sessionId = '';
            this.#demoSession = false;
            store.dispatch(transactionActions.setActiveDemoTalkUrl({ talkUrl: '' }));
        } catch (error) {
            this.#handleError({
                message: 'ERROR STOPPING DEMO AUDIO FILE',
                error,
                reThrow: false,
            });
        }
    }

    toggleOutgoingAudioActive() {
        if (!this.#userStreamAudioTrack) return;
        const enabled = !this.#userStreamAudioTrack.enabled;
        this.#userStreamAudioTrack.enabled = enabled;
        store.dispatch(transactionActions.setTalkSessionPaused(!enabled));
    }

    isActive() {
        return this.#userStream || this.#recorder || this.#sessionId || this.#audioContext || this.#gainNode || this.#webSocket || this.#player;
    }

    #log(message, mode = 'log') {
        console[mode](`[TalkSession${this.#sessionId ? ` sessionId=${this.#sessionId}` : ''}] ${message}`);
    }

    async #wait(ms) {
        return new Promise((res) => setTimeout(res, ms));
    }

    #handleError({ message, error, reThrow = true, variant = 'error', dispatchErrorEvent = false }) {
        const messageToDisplay = `${message}${error?.message ? `: [${error.message.toUpperCase()}]` : ''}`;

        //send error log to grafana
        this.#log(`${messageToDisplay}`, 'error');

        //display error to user
        !reThrow && this.#showNotification(messageToDisplay, variant);

        if (reThrow) throw new Error(message);

        dispatchErrorEvent && store.dispatch(transactionActions.setActiveTalkUrl({ activeTalkUrl: '' }));
    }
    #showNotification(message, variant = 'error') {
        store.dispatch(
            snackbarActions.enqueueSnackbar({
                message,
                options: { variant },
            })
        );
    }
}
