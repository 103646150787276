import { useDispatch } from 'react-redux';
import { formControlActions } from 'store/formControlSlice/formControlSlice';
import React from 'react';
import { useEffect } from 'react';
import AIQAutocomplete from '../AIQAutocomplete/AIQAutocomplete';
import AIQInput from '../AIQInput/AIQInput';
import { useCallback } from 'react';
function Select({
    fieldName,
    initialValue = null,
    initialReferenceValue,
    value,
    label,
    touched,
    type,
    error,
    formId,
    required,
    lookup,
    disabled,
    blurHandler = () => {},
    changeHandler = () => {},
    inputValueChangeHandler = () => {},
    automation,
    validationField,
    referenceField,
    referenceValue,
    validationStyles,
    containerClasses,
    inputContainerClasses,
    forceFieldRegistration,
    helperText,
    isOptionsLoadingInProgress,
    optionsLoadingText,
    forceAutomationStyles,
    issues,
    transactionId,
    camInfo,
    onSelect,
}) {
    const backgroundColor = validationStyles?.color ? { backgroundColor: validationStyles?.color } : null;

    const dispatch = useDispatch();
    useEffect(() => {
        if (!formId || value !== undefined) return;
        dispatch(
            formControlActions.registerNewFormField({
                formId,
                fieldName,
                initialValue,
                disabled: disabled && !forceFieldRegistration,
                required,
            })
        );
        if (referenceField && initialReferenceValue) {
            dispatch(
                formControlActions.registerNewFormField({
                    formId,
                    fieldName: referenceField,
                    initialValue: initialReferenceValue,
                })
            );
        }

        const hasInitialValue = initialValue || initialValue === false || initialValue === 0;
        if (required && hasInitialValue) {
            dispatch(
                formControlActions.setFieldError({
                    formId,
                    fieldName,
                    error: false,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fieldName, formId, initialValue, required, forceFieldRegistration, value]);

    const getOptionLabel = useCallback(
        function (option) {
            if (lookup?.displayExpr) return option?.[lookup.displayExpr];
        },
        [lookup?.displayExpr]
    );

    const hasError = touched && error; // && required;
    const acValue =
        lookup?.dataSource.find((data) => {
            let activeOption = false;
            if (Object.hasOwn(data, lookup.referenceExpr) && referenceValue) {
                activeOption = data?.[lookup.referenceExpr] === referenceValue;
                if (activeOption) return activeOption;
            } else {
                if (Object.hasOwn(data, lookup.displayExpr)) activeOption = data?.[lookup.displayExpr] === value;
                if (Object.hasOwn(data, lookup.valueExpr)) activeOption = data?.[lookup.valueExpr] === value;
            }

            return activeOption;
        }) ?? value;

    return (
        <AIQAutocomplete
            valueExpr={lookup?.valueExpr}
            getOptionLabel={lookup?.displayExpr && getOptionLabel}
            value={acValue || null}
            {...(lookup?.referenceExpr && { referenceExpr: lookup?.referenceExpr, initialReferenceValue })}
            onInputChange={inputValueChangeHandler.bind({
                lookup,
                formId,
                fieldName,
                type,
                error,
                required,
                referenceField,
                referenceValue,
            })}
            onChange={changeHandler.bind({
                lookup,
                formId,
                fieldName,
                type,
                error,
                required,
                referenceField,
            })}
            onBlur={blurHandler.bind({
                required,
                touched,
                error,
                formId,
                fieldName,
                validationField,
            })}
            freeSolo={!lookup?.displayExpr || referenceField}
            isOptionsLoadingInProgress={isOptionsLoadingInProgress}
            optionsLoadingText={optionsLoadingText}
            options={lookup?.dataSource}
            containerClasses={containerClasses}
            fieldName={fieldName}
            renderInput={(params) => {
                return (
                    <AIQInput
                        {...params}
                        helperText={`${helperText && hasError ? helperText : ''} `}
                        label={label}
                        type={type}
                        error={hasError}
                        name={fieldName}
                        InputLabelProps={{ shrink: true }}
                        automation={automation}
                        disabled={disabled}
                        {...backgroundColor}
                        forceAutomationStyles={forceAutomationStyles}
                        issues={issues}
                        transactionId={transactionId}
                        inputContainerClasses={inputContainerClasses}
                        camInfo={camInfo}
                    />
                );
            }}
            onSelect={onSelect}
        />
    );
}

export default React.memo(Select);
