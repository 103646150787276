import { useEffect, useMemo } from 'react';
import Table from 'components/UI/table/Table';
import { useSelector } from 'react-redux';
import { selectTableByIdAndConfigName } from 'store/tableControlSlice/tableControlSelectors';
import MasterDetail from './masterDetail/MasterDetail';
import { tableConfig } from '../VinCheckConfig';

function TransactionsTable({
    locationId,
    onFetchTableData = () => {},
    activeCameraId,
    onRemoveTableData,
    onConfirmProcessedSnapshot,
    sendingConfirmation,
}) {
    const { rowsPerPage, tableId, columnsConfig } = tableConfig;
    const selectTableDataAndConfigSelector = useMemo(() => selectTableByIdAndConfigName(tableId), [tableId]);
    const { tableData, locationTransactions } = useSelector(selectTableDataAndConfigSelector);

    function optionChangedHandler(e) {
        if (e.name === 'paging') {
            if (!e.value) return;
            e.component.pageIndex(e.value);
            !locationTransactions[e.value] && onFetchTableData({ tableId, locationId, pageNum: e.value, size: rowsPerPage, append: true });
        }
    }

    useEffect(() => {
        if (!activeCameraId) return;
        tableData?.length && onRemoveTableData(tableId);
        return () => onRemoveTableData(tableId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCameraId, onRemoveTableData]);

    useEffect(() => {
        if (!activeCameraId) return;

        activeCameraId && locationId && onFetchTableData({ tableId, locationId, pageNum: 0, size: rowsPerPage });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCameraId, locationId]);

    return (
        !!activeCameraId && (
            <Table
                data={tableData}
                columnsConfig={columnsConfig}
                masterDetailComponent={MasterDetail.bind({ onConfirmProcessedSnapshot, sendingConfirmation })}
                keyExpr="vin_transaction_id"
                rowsPerPage={rowsPerPage}
                optionChangedHandler={optionChangedHandler}
            />
        )
    );
}

export default TransactionsTable;
