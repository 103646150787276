import React, { Suspense, useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import NavigationDrawer from './components/navigation/NavigationDrawer';
import styles from './App.module.css';
import { aiqLogger } from 'services/aiqLogger';
import { useDispatch, useSelector } from 'react-redux';
import * as authSelectors from 'store/authSlice/authSelectors';
import { featureFlagActions } from 'store/featureFlag/featureFlagSlice';

import { Routes, Route } from 'react-router-dom';
import Notifier from 'components/UI/Notifications/Notifier';
import { sendLocalStorageSignal, sessionStorageTransferHandler } from 'utils/sessionStorageHandlers';
import { authActions } from 'store/authSlice/authSlice';
import { getKurentoConfig, getCookie } from 'utils/utils';
import { PROFILE } from 'config';

import { alertService } from 'services/AlertService';
import { kurentoConfig } from 'services/Kurento/KurentoConfig';
import ProtectedRoute from 'components/protectedRoute/ProtectedRoute';

//pages
import Home from 'pages/home';
import NoPage from 'pages/no_page';
import LinearLoader from 'components/UI/Loaders/LinearLoader';
import Redirect from 'pages/Redirect';
import { transactionTableConfig } from 'store/tableControlSlice/transactionTablesConfig';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import { sharedWorkerActions, sharedWorker } from 'workers/sharedWorker/utils/utils';

const StreamTest = React.lazy(() => import('pages/StreamTest.jsx'));
const Transaction = React.lazy(() => import('pages/TransactionPage'));
const AllTransactionsPage = React.lazy(() => import('pages/AllTransactionsPage'));
const CameraConfiguration = React.lazy(() => import('pages/CameraConfiguration'));
const DeskConfiguration = React.lazy(() => import('pages/DeskConfiguration'));
const DeskAssignLocation = React.lazy(() => import('pages/DeskAssignLocation'));
const VinCheck = React.lazy(() => import('pages/VinCheck'));
const IssueTracker = React.lazy(() => import('pages/IssueTracker/IssueTracker'));
const TrackStreaming = React.lazy(() => import('pages/TrackStreaming/TrackStreaming'));

const removeCookie = () => {
    //logic related to cookies should be moved out of component (to separate file)
    sharedWorkerActions.removeWindow({ id: sharedWorker.id });
    switch (PROFILE) {
        case 'development':
            document.cookie = 'aiqD=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            break;
        case 'staging':
            document.cookie = 'aiqS=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            break;
        case 'production':
            document.cookie = 'aiqP=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            break;
        default:
            document.cookie = 'aiq=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
};

function App() {
    const dispatch = useDispatch();
    const user = useSelector(authSelectors.selectUser);
    const isUserDataRequested = useSelector(authSelectors.selectIsUserDataRequested);
    const [kurentoConfigPresent, setKurentoConfigPresent] = useState(false);

    useEffect(() => {
        window.addEventListener('storage', sessionStorageTransferHandler);
        window.addEventListener('beforeunload', removeCookie);
        let cookieLasta;
        let cookieSso;
        let cookieAiq;
        let cookieAlert;
        let cookieGu;

        switch (PROFILE) {
            case 'development':
                cookieLasta = getCookie('lastaD');
                cookieSso = getCookie('ssoD');
                cookieAiq = getCookie('aiqD');
                cookieAlert = getCookie('alertD');
                cookieGu = getCookie('guD');
                break;
            case 'staging':
                cookieLasta = getCookie('lastaS');
                cookieSso = getCookie('ssoS');
                cookieAiq = getCookie('aiqS');
                cookieAlert = getCookie('alertS');
                cookieGu = getCookie('guS');
                break;
            case 'production':
                cookieLasta = getCookie('lastaP');
                cookieSso = getCookie('ssoP');
                cookieAiq = getCookie('aiqP');
                cookieAlert = getCookie('alertP');
                cookieGu = getCookie('guP');
                break;
            default:
                cookieLasta = getCookie('lasta');
                cookieSso = getCookie('sso');
                cookieAiq = getCookie('aiq');
                cookieAlert = getCookie('alert');
                cookieGu = getCookie('gu');
        }

        if (cookieSso || cookieAiq || cookieAlert || cookieGu || cookieLasta) {
            alertService.activate();
            return;
        } else {
            switch (PROFILE) {
                case 'development':
                    document.cookie = 'aiqD=AlertsON; path=/; ';
                    break;
                case 'staging':
                    document.cookie = 'aiqS=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                case 'production':
                    document.cookie = 'aiqP=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                default:
                    document.cookie = 'aiq=AlertsON; path=/; ';
            }
            alertService.activate();
        }

        return () => {
            alertService.deactivate();
            aiqLogger.removeEventListeners();
            window.removeEventListener('beforeunload', removeCookie);
            window.removeEventListener('storage', sessionStorageTransferHandler);
        };
    }, []);

    useEffect(() => {
        user && dispatch(transactionActions.registerSharedWorker());
    }, [dispatch, user]);

    useEffect(() => {
        dispatch(featureFlagActions.getFeatureFlagData());
    }, [dispatch]);

    useEffect(() => {
        if (!user) {
            !sessionStorage?.length && sendLocalStorageSignal('AIQ_GetSessionStorage');
        } else if (!kurentoConfigPresent) {
            getKurentoConfig().then((isConfigurationSet) => {
                if (isConfigurationSet) {
                    setKurentoConfigPresent(true);
                }
            });
        }

        return () => {
            if (kurentoConfigPresent) {
                kurentoConfig.removeConfig();
                setKurentoConfigPresent(false);
            }
        };
    }, [dispatch, user, kurentoConfigPresent]);

    useEffect(() => {
        !user && dispatch(authActions.getUserData());
    }, [user, dispatch]);

    if (!isUserDataRequested)
        return (
            <div className={styles['cheking-permission']}>
                <p>Checking permissions...</p>
                <LinearLoader containerStyles={{ position: 'relative' }} />
            </div>
        );

    return (
        <>
            {user && <NavigationDrawer user={user} />}
            <Suspense fallback={<LinearLoader containerStyles={{ width: '70%', top: '30vh', left: '15vw' }} />}>
                <Routes>
                    <Route path="/redirect" element={<Redirect user={user} />} />
                    <Route element={<ProtectedRoute isAllowed={!!user} redirectPath="/redirect" />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/stream-test" element={kurentoConfigPresent && <StreamTest />} />
                        <Route path="/transaction" element={kurentoConfigPresent && <Transaction />} />
                        <Route path="/desk-assign" element={<DeskAssignLocation />} />
                        <Route
                            path="/all-transactions"
                            element={
                                <AllTransactionsPage
                                    tableId={transactionTableConfig.allLocationsTransactions.tableId}
                                    tableConfigName={transactionTableConfig.allLocationsTransactions.configName}
                                />
                            }
                        />
                        <Route
                            path="/all-announcements"
                            element={
                                <AllTransactionsPage
                                    tableId={transactionTableConfig.allLocationsAnnouncements.tableId}
                                    tableConfigName={transactionTableConfig.allLocationsAnnouncements.configName}
                                />
                            }
                        />
                        <Route path="/vin-check" element={kurentoConfigPresent && <VinCheck />} />
                        <Route path="/issue-tracker" element={kurentoConfigPresent && <IssueTracker />} />
                        <Route path="/track-streaming" element={kurentoConfigPresent && <TrackStreaming />} />
                    </Route>

                    <Route element={<ProtectedRoute isAllowed={!!user && user.roles.includes('admin')} />}>
                        <Route path="/camera-configuration" element={<CameraConfiguration />} />
                        <Route path="/desk-configuration" element={<DeskConfiguration />} />
                    </Route>

                    <Route path="*" element={<NoPage />} />
                </Routes>
            </Suspense>
            <Notifier />
        </>
    );
}

export default App;
