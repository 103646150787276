import styles from './CameraStreamButtons.module.css';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';
import { axiosAiq } from 'config';
import { Button } from '@mui/material';

function CameraStreamButtons({ cameraId, className, cameraHasTransaction }) {
    const dispatch = useDispatch();

    const addToQueueHandler = async () => {
        try {
            const response = await axiosAiq.post(`/camera/manual`, { gate_camera_id: cameraId, assign_to_me: false });
            if (response.status === 200) {
                dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: `Successfuly added to queue transaction from camera with id ${cameraId}`,
                        options: { variant: 'success' },
                    })
                );
            } else {
                dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: `${cameraId} ${response.error}`,
                        options: { variant: 'error' },
                    })
                );
            }
        } catch (error) {
            dispatch(
                snackbarActions.enqueueSnackbar({
                    message: error.message,
                    options: { variant: 'error' },
                })
            );
        }
    };

    const assingToMeHandler = async () => {
        try {
            const response = await axiosAiq.post(`/camera/manual`, { gate_camera_id: cameraId, assign_to_me: true });
            if (response.status === 200) {
                dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: `Successfuly assigned to you transaction from camera with id ${cameraId}`,
                        options: { variant: 'success' },
                    })
                );
            } else {
                dispatch(
                    snackbarActions.enqueueSnackbar({
                        message: `${cameraId} ${response.error}`,
                        options: { variant: 'error' },
                    })
                );
            }
        } catch (error) {
            const string = error.response.data.error;
            let message = string.substring(string.indexOf('[') + 1, string.indexOf(']'));

            if (message === 'DESK_INACTIVE') {
                message = 'Transaction mode has to be enabled on Division tab, in order for you to process the transaction';
            }

            dispatch(
                snackbarActions.enqueueSnackbar({
                    message: message,
                    options: { variant: 'error' },
                })
            );
        }
    };

    return (
        <div className={`${className} ${styles['camera-stream']}`}>
            <Button
                disabled={cameraHasTransaction}
                variant="contained"
                color="primary"
                onClick={addToQueueHandler}
                className={styles['camera-stream-buttons']}
            >
                Add to Queue
            </Button>
            <Button
                disabled={cameraHasTransaction}
                variant="contained"
                color="success"
                onClick={assingToMeHandler}
                className={styles['camera-stream-buttons']}
            >
                Assign to Me
            </Button>
        </div>
    );
}

export default CameraStreamButtons;
