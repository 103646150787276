import styles from './Dropdown.module.css';
import Options from './Options/Options';

function Dropdown({ dropdownOpened, noOptionsText, isOptionsLoadingInProgress, optionsLoadingText, ...props }) {
    const { filteredOptions, freeSolo } = props;
    return (
        <div className={styles['dropdown']}>
            {dropdownOpened && !!filteredOptions.length && <Options {...props} />}
            {dropdownOpened && !filteredOptions.length && !freeSolo && (
                <p className={styles['no-option']}>{isOptionsLoadingInProgress ? optionsLoadingText : noOptionsText}</p>
            )}
        </div>
    );
}

export default Dropdown;
