import KurentoRtspSource from './KurentoRtspSource';
import { KurentoClient } from 'kurento-client';
import { kurentoConfig } from './KurentoConfig';
import { GENERAL } from 'config';
import MediaMtxSource from './MediaMtxSource';
import { notificationHandlerSync } from 'utils/utils';

class KurentoManager {
    constructor() {
        this.#sources = new Map();
        this.#sourcesByVideoOutputId = new Map();
        this.#sourcesByTab = new Map();
        this.#eventHandlers = new Map();
    }
    preconfigeCamerasPromise;
    #eventHandlers;
    #sources;
    #sourcesByVideoOutputId;
    #sourcesByTab;
    #kurentoClientPromise;
    #kurentoClient;
    #tempWebRTCEndpointIdList = [];

    // out of all sources, a single one, on a single tab, will be enabled for audio
    #sourceWithAudio;
    async create(user, camConfig, preconfigure) {
        try {
            const cameraId = camConfig.camId;
            const streamType = camConfig?.videoData?.streamType;
            let source = this.#sources.get(cameraId);
            let mtxDirect = camConfig.mtxDirect;
            if (!source) {
                if (mtxDirect) {
                    source = new MediaMtxSource(camConfig);
                    this.#sources.set(cameraId, source);
                    source.activateStreamType();
                } else {
                    if (!this.#kurentoClient) {
                        if (!this.#kurentoClientPromise) this.createKurentoClient();
                        await this.#kurentoClientPromise;
                        this.#log(`create(): Awaited for KurentoClient`);
                    }
                    source = new KurentoRtspSource(this.#kurentoClient, user, cameraId);
                    this.#sources.set(cameraId, source);

                    if (preconfigure) this.#log(`create(): Preconfiguring camId=${cameraId} streamType=${streamType}`);
                    return source.addCameraStreamConfiguration(camConfig, false, preconfigure);
                }
            } else if (!source.playerByStreamType(camConfig.videoData.streamType)) {
                if (preconfigure) this.#log(`create(): Preconfiguring camId=${cameraId} streamType=${streamType}`);
                return source.addCameraStreamConfiguration(camConfig, false, preconfigure);
            }
        } catch (err) {
            this.#handle(err, `create()`);
        }
    }

    async activate(cameraId, streamType, videoOutputId, tabId, transactionId, isTabActive) {
        try {
            if (!this.#kurentoClient) {
                if (!this.#kurentoClientPromise) this.createKurentoClient();
                await this.#kurentoClientPromise;
                this.#log(`activate(): Awaited for KurentoClient`);
            }
            const source = this.#sources.get(cameraId);
            this.#add(tabId, videoOutputId, source);
            source.addVideoOutput(videoOutputId, transactionId);
            if (isTabActive || (!source.activeStreamType && !source.activatingStreamType)) await source.activateStreamType(streamType);
        } catch (err) {
            this.#handle(err, `activate()`);
        }
    }

    async playStreamType(streamType, videoOutputId) {
        try {
            const source = this.#get(videoOutputId);
            if (source.playerByStreamType(streamType)) await source.activateStreamType(streamType);
        } catch (err) {
            this.#handle(err, `playStreamType()`);
        }
    }

    async deactivate(videoOutputId) {
        try {
            const source = this.#get(videoOutputId);
            await source.deactivate();
        } catch (err) {
            this.#handle(err, `playStreamType()`);
        }
    }

    async removeVideoOutput(videoOutputId, force = true) {
        try {
            const source = this.#get(videoOutputId, force);
            source && (await source.removeVideoOutput(videoOutputId));
        } catch (err) {
            this.#handle(err, `removeVideoOutput()`);
        }
    }

    createKurentoClient() {
        this.#getMediaDevicesPermissions();

        if (this.#kurentoClient) return;
        this.#kurentoClientPromise = new Promise((res, rej) => {
            KurentoClient(kurentoConfig.wsUri, (err, client) => {
                if (err) rej(err);
                else res(client);
            });
        });

        this.#kurentoClientPromise
            .then((client) => {
                this.#log(`KurentoClient created`);
                this.#kurentoClient = client;
            })
            .catch((err) => {
                this.#log(`Error creating KurentoClient message=${err?.message}`);
            })
            .finally(() => (this.#kurentoClientPromise = null));
    }

    async #getMediaDevicesPermissions(constraints = { audio: true }, isRetry = false) {
        if (!isRetry) {
            const permisionsGranted = await this.#checkMediaDevicesPermissions(); //check if permision is already granted
            if (permisionsGranted) return;
        }
        try {
            let mediaStream = await navigator.mediaDevices.getUserMedia(constraints); //request permissions
            //stop user media streams
            mediaStream.getTracks().forEach((track) => {
                track.stop();
            });
            mediaStream = null;
        } catch (err) {
            switch (err?.name) {
                case 'NotAllowedError': // render message to user if permision is not granted
                    notificationHandlerSync({ title: 'Please enable media devices (Mic and/or Camera) manually', variant: 'info' });
                    break;
                case 'NotFoundError':
                    if (!isRetry) {
                        this.#log(`#getMediaDevicesPermissions(): Retrying with video constraints`);
                        this.#getMediaDevicesPermissions({ video: true }, true); // try with another constraints if there is no audio device
                    } else this.#log(`#getMediaDevicesPermissions(): Error getting media devices permissions, AFTER retry ${err?.message}`);
                    break;

                default:
                    this.#log(`#getMediaDevicesPermissions(): Error getting media devices permissions ${err?.message}`);
            }
        }
    }

    async #checkMediaDevicesPermissions() {
        let mediaDevicePermisionsGranted = false;
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            for (let device of devices) {
                if (device?.label) {
                    mediaDevicePermisionsGranted = true;
                    break;
                }
            }
        } catch (err) {
            this.#log(`#checkMediaDevicesPermisions(): Error reading media devices permissions, ${err?.message}`);
        }

        return mediaDevicePermisionsGranted;
    }

    getTransactionIdsByCameraId(cameraId) {
        return this.#sources.get(cameraId)?.getTransactionIds() || [];
    }

    isActiveOnVideoWall(cameraId) {
        return this.#sources.get(cameraId)?.isActiveOnVideoWall();
    }

    registerEventHandler(eventType, handler) {
        const handlerPresent = this.#eventHandlers.get(eventType);
        if (handlerPresent) return;
        this.#eventHandlers.set(eventType, handler);
    }

    setBeforeUnloadListener() {
        window.addEventListener('beforeunload', this.beforeUnloadHandler);
    }

    removeBeforeUnloadListener() {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    }

    beforeUnloadHandler = (e) => {
        e?.preventDefault();

        const webRtcEndpointIds = kurentoManager.getWebRTCEndpointIds();

        if (webRtcEndpointIds.length) {
            this.#log(`beforeUnloadHandler(): Sending WebRTCEndpointId list for releasing ids=[${webRtcEndpointIds}]`);
            const data = { webRtcEndpointIds };
            const sent = navigator.sendBeacon(`${GENERAL.ADDRESSES.HTTP.MAIN}/camera-stream/release-endpoints`, JSON.stringify(data));
            this.#log(`beforeUnloadHandler():WebRTCEndpointIds ${sent ? '' : 'NOT '}sent!`);
        } else {
            this.#log(`beforeUnloadHandler(): No WebRTCEndpointIds for release ${webRtcEndpointIds}`);
        }
    };

    #add(tabId, videoOutputId, source) {
        this.#sourcesByVideoOutputId.set(videoOutputId, source.cameraId);
        let byTab = this.#sourcesByTab.get(tabId) || [];
        if (!byTab.length) this.#sourcesByTab.set(tabId, byTab);
        byTab.push(videoOutputId);
        this.#log(`Added source: tabId=${tabId}, videoOutputId=${videoOutputId}`);
    }

    // #addListener(source, type, videoOutputId) {
    //     const eventHandler = this.#eventHandlers.get(type);
    //     eventHandler && source.addEventListener(type, eventHandler, videoOutputId);
    // }

    #get(id, required = true) {
        try {
            const cameraId = typeof id === 'number' ? id : this.#sourcesByVideoOutputId.get(id);
            const result = cameraId && this.#sources.get(cameraId);

            if (required && !result) {
                throw new Error(`Invalid source id="${id}"`);
            }

            return result;
        } catch (err) {
            this.#handle(err, `#get()`, true);
        }
    }

    getWebRTCEndpointIds() {
        const sources = [...this.#sources.values()];
        const ids = sources.reduce((ids, source) => {
            const id = source.webRtcEndpointId;
            id && ids.push(id);
            return ids;
        }, []);
        return [...ids, ...this.#tempWebRTCEndpointIdList];
    }

    getLocalIceCandidatesUsed() {
        const sources = [...this.#sources.values()];
        const ips = sources.reduce((ips, source) => {
            const ipArray = source.localICECandidatesUsed;
            ipArray && ipArray.forEach((element) => ips.push(element));
            return ips;
        }, []);
        return [...new Set(ips)];
    }

    // Access by tab and id

    getIds() {
        return Array.from(this.#sourcesByVideoOutputId.keys());
    }

    getTabs() {
        return Array.from(this.#sourcesByTab.keys());
    }

    getIdsByTab(tabId) {
        const byTab = this.#sourcesByTab.get(tabId) || [];
        return byTab;
    }

    // Actions on videos per id

    async restart(id, streamType) {
        try {
            const source = this.#get(id);
            await source.restart(streamType);
        } catch (err) {
            this.#handle(err, 'restart()');
        }
    }

    async hardRefresh(id) {
        try {
            const source = this.#get(id);
            await source.hardRefresh();
        } catch (err) {
            this.#handle(err, 'hardRefresh()');
        }
    }

    restartAll() {
        if (!this.#sources.size) return;
        this.#log(`restartAll(): Restarting all streams`);
        this.#sources?.forEach(({ activeStreamType }, key) => {
            this.restart(key, activeStreamType);
        });
    }

    restartTab(tabId, hardRefresh = false) {
        if (!this.#sourcesByTab.get(tabId)?.length) return;
        this.#log(`Refreshing streams for tabId=${tabId} refreshType=${hardRefresh ? 'HARD' : 'SOFT'}`);
        this.#sourcesByTab.get(tabId)?.forEach((videoOutputId) => {
            if (hardRefresh) this.hardRefresh(videoOutputId);
            else {
                const source = this.#get(videoOutputId);
                this.restart(videoOutputId, source?.activeStreamType);
            }
        });
    }

    async updateCameraConfig(camConfig) {
        try {
            const source = this.#sources.get(camConfig.camId);
            if (source) {
                await source.addCameraStreamConfiguration(camConfig, true);
            }
        } catch (err) {
            this.#handle(err, 'updateCameraConfig()');
        }
    }

    // Statuses of videos per id

    getInfo(id) {
        try {
            const source = this.#get(id, false);
            return source ? source.getInfo() : '<loading>';
        } catch (err) {
            this.#handle(err, `getInfo(${id})`);
        }
    }

    // Actions on videos per tab

    activateTab(tabId) {
        const sourceIds = this.getIdsByTab(tabId);
        if (sourceIds.length > 0) {
            this.#log(`Activated tabId=${tabId} with audio videoOutputId=${sourceIds[0]}`);
            this.enableAudio(sourceIds[0]);
        }
    }

    async closeTab(tabId, transactionId) {
        try {
            const sourceIds = this.getIdsByTab(tabId);
            if (!sourceIds.length) return;

            for (const id of sourceIds) {
                const source = this.#get(id);
                source.removeVideoOutput(id);

                this.#sourcesByVideoOutputId.delete(id);
            }

            this.#sourcesByTab.delete(tabId);

            const transactionsInfo = transactionId ? ` transactionId=${transactionId}` : '';
            this.#log(`Removed streams from video elements for tabId=${tabId}${transactionsInfo}: ${sourceIds}`);
        } catch (err) {
            this.#handle(err, `closeTab(${tabId},${transactionId})`);
        }
    }

    async stopAllStreams(closeKurentoClient) {
        try {
            if (this.preconfigeCamerasPromise) await this.preconfigeCamerasPromise;

            const sources = this.#sources;
            this.#tempWebRTCEndpointIdList = this.getWebRTCEndpointIds();

            this.#sources = new Map();
            this.#sourcesByVideoOutputId = new Map();
            this.#sourcesByTab = new Map();
            this.#sourceWithAudio = null;

            for (const source of [...sources.values()]) {
                await source.release(true);
            }
            this.#tempWebRTCEndpointIdList = [];

            if (this.#kurentoClient && closeKurentoClient) {
                try {
                    this.#kurentoClient.close();
                    this.#kurentoClient = null;
                    this.#eventHandlers = new Map();
                } catch (err) {
                    throw new Error(`Error closing KurentoClient error=${err.message}`);
                }
            }

            this.#log(`Stopped and removed all videos`);
        } catch (err) {
            this.#handle(err, `stopAllStreams()`);
        }
    }

    async stopStreamsForTab(tabId, transactionId) {
        try {
            const sources = this.#sources;
            const cameraIdsToRemove = [];
            for (const [cameraId, source] of [...sources.entries()]) {
                if (source.isExclusivelyUsedForTransactionId(transactionId)) {
                    await source.release(true);
                    cameraIdsToRemove.push(cameraId);
                }
            }

            for (const videoOutputId of this.#sourcesByTab.get(tabId) || []) {
                const cameraId = this.#sourcesByVideoOutputId.get(videoOutputId);
                this.#sourcesByVideoOutputId.delete(videoOutputId);
                if (!cameraIdsToRemove.includes(cameraId)) {
                    const source = this.#sources.get(cameraId);
                    source.removeVideoOutput(videoOutputId);
                    continue;
                }
                this.#sources.delete(cameraId);
            }
            this.#sourcesByTab.delete(tabId);

            const transactionInfo = transactionId ? ` transactionId=${transactionId}` : '';
            this.#log(`Closed WebRTC connections for streams exclusively used on tabId=${tabId}${transactionInfo}`);
        } catch (err) {
            this.#handle(err, `stopStreamsForTab(${tabId},${transactionId})`);
        }
    }

    async stopAllStreamsExcludeTab(tabId) {
        try {
            const videoOutputIds = this.#sourcesByTab.get(tabId);

            if (videoOutputIds === undefined) {
                await this.stopAllStreams();
                return;
            }

            const sources = new Map();
            const sourcesByTab = new Map();
            const sourcesByVideoOutputId = new Map();

            sourcesByTab.set(tabId, videoOutputIds);
            const sourcesToKeep = [];

            for (let videoOutputId of videoOutputIds) {
                const sourceId = this.#sourcesByVideoOutputId.get(videoOutputId);
                const source = this.#sources.get(sourceId);
                sourcesToKeep.push(source);
                await source.makeExclusiveForVideoOutput(videoOutputId);
                sourcesByVideoOutputId.set(videoOutputId, sourceId);
                sources.set(sourceId, source);
            }

            for (let source of [...this.#sources.values()]) {
                if (!sourcesToKeep.includes(source)) {
                    await source.release(true);
                }
            }

            this.#sources = sources;
            this.#sourcesByTab = sourcesByTab;
            this.#sourcesByVideoOutputId = sourcesByVideoOutputId;

            this.#log(`Closed WebRTC connections for all streams except those used on tabId=${tabId}`);
        } catch (err) {
            this.#handle(err, `stopAllStreamsExcludeTab(${tabId})`);
        }
    }

    //AoT

    async activateAot(user, camConfig) {
        try {
            await this.create(user, camConfig, true);
            const cameraId = camConfig.camId;
            const streamType = camConfig?.videoData?.streamType;
            let source = this.#sources.get(cameraId);
            source.aot = true;
            await this.playStreamType(streamType, cameraId);
        } catch (err) {
            this.#handle(err, `activateAot()`);
        }
    }

    async cancelAot(_, camConfig) {
        try {
            const cameraId = camConfig.camId;
            let source = this.#sources.get(cameraId);
            if (!source) return;
            source.aot = false;
            const isActivelyUsed = !!source.getTransactionIds().length;
            if (!isActivelyUsed) this.deactivate(cameraId);
        } catch (err) {
            this.#handle(err, `cancelAot()`);
        }
    }

    // Audio

    enableAudio(id, muteAll = false) {
        if (!id) return;

        if (this.#sourceWithAudio) {
            if (this.#sourceWithAudio.hasVideoOutputId(id) || muteAll) {
                this.#sourceWithAudio.audio(false);
                this.#sourceWithAudio = null;
                return;
            }

            this.#sourceWithAudio.audio(false);
        }

        if (muteAll) return;

        this.#sourceWithAudio = this.#get(id);
        this.#sourceWithAudio.audio(true);
    }

    isAudioEnabled(id) {
        if (!id || !this.#sourceWithAudio) return;
        return this.#sourceWithAudio.hasVideoOutputId(id);
    }

    audioOff() {
        this.#sourceWithAudio?.audio(false);
        this.#sourceWithAudio = undefined;
    }

    getSourceWithAudio() {
        return this.#sourceWithAudio;
    }

    getVideoElementId(tabId, camId) {
        return 'tc-cs-cam-' + tabId + '-' + camId;
    }

    // String representation

    toString() {
        const arr = Array.from(this.#sourcesByVideoOutputId.values());
        return `{KurentoManager sources=[${arr}]}`;
    }

    #log(message, mode = 'log') {
        console[mode](`[KurentoManager] ${message}`);
    }

    #handle(err, message, rethrow = false) {
        if (err) {
            this.#log(`ERROR: ${message}: ${err?.message || err}`, 'error');
            if (rethrow) throw new Error(err?.message || err);
        }
    }
}

export const kurentoManager = new KurentoManager();

if (import.meta.env.VITE_ENV !== 'production') window.kurentoManager = kurentoManager;
