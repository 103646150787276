import styles from './CameraStreamHeader.module.css';

function CameraStreamHeader({
    isVideoLoading,
    videoFailedToLoad,
    camConfig,
    level1,
    expandedCamId,
    headerMode,
    cameraHasTransaction,
    transactionDetectionAssigned,
}) {
    const headerContainerStyles = `${styles['tc-cs-header']} ${styles['tc-cs-header--absolute']}${
        !isVideoLoading && !videoFailedToLoad ? ` ${styles['tc-cs-header--box-shadow']}` : ''
    }${expandedCamId && expandedCamId !== camConfig.id && !level1 ? ` ${styles['tc-cs-header--L2-shrinked']}` : ''} ${
        camConfig.direction === 'OUT' ? styles['header-color-out'] : styles['header-color-in']
    } ${cameraHasTransaction ? styles['header-color-transaction'] : ''}`;

    const title = camConfig.locationName ?? '';
    const gateName = camConfig.gateName ?? '';
    const direction = camConfig.direction ?? '';
    const assigne = transactionDetectionAssigned ?? '';

    return (
        <>
            {headerMode && (
                <div className={headerContainerStyles}>
                    <p>{title} |</p>
                    {gateName && getHeaderInfo(gateName, direction)}
                    {cameraHasTransaction && <p className={styles['header-assigned-name']}>{assigne}</p>}
                </div>
            )}
        </>
    );
}

export default CameraStreamHeader;

function getHeaderInfo(gateName, direction) {
    const common = (
        <>
            <p>{gateName} |</p>
            <p>{direction}</p>
        </>
    );

    return common;
}
