import { store } from 'store/store';
// import { tableControlActions } from 'store/tableControlSlice/tableControlSlice';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import { authActions } from '../store/authSlice/authSlice';
import * as aiqConfig from 'config';

export const sessionStorageTransferHandler = function (e) {
    if (!e.newValue) return;

    if (e.key === 'AIQ_GetSessionStorage') {
        sendLocalStorageSignal('AIQ_SessionStorage', sessionStorage);
    }

    if (e.key === 'AIQ_SessionStorage' && !sessionStorage.length) {
        const session = JSON.parse(e.newValue);
        for (let key in session) {
            if (aiqConfig.clientPersistentSessionStorageKeys.includes(key)) continue;
            sessionStorage.setItem(key, session[key]);
            if (key === 'user') store.dispatch(authActions.setUser(JSON.parse(session[key])));

            if (key === 'talkUrl')
                store.dispatch(
                    transactionActions.setActiveTalkUrl({
                        activeTalkUrl: JSON.parse(session[key]),
                        sendSignal: false,
                    })
                );

            if (key === 'demoTalkUrl')
                store.dispatch(
                    transactionActions.setActiveDemoTalkUrl({
                        talkUrl: JSON.parse(session[key]),
                        sendSignal: false,
                    })
                );
        }
    }

    if (e.key === 'AIQ_UserLoggedOut') {
        sessionStorage.removeItem('user');
        store.dispatch(authActions.logoutStart());
    }

    // if (e.key === 'transactionClosed') {
    //     const transactionId = JSON.parse(e.newValue);
    //     const transaction = store.getState().transactions.transactions.find((transaction) => transaction.id === JSON.parse(e.newValue));

    //     if (transaction) {
    //         const { tabId, locationId } = transaction;
    //         store.dispatch(
    //             transactionActions.closeTransactionPanel({
    //                 tabId,
    //                 transactionId,
    //                 locationId,
    //             })
    //         );
    //         store.dispatch(
    //             tableControlActions.fetchTableDataStart({
    //                 tableId: locationId,
    //                 locationId,
    //                 pageNum: 0,
    //             })
    //         );
    //     }
    // }

    if (e.key === 'talkUrlChanged') {
        const activeTalkUrl = JSON.parse(e.newValue);
        store.dispatch(
            transactionActions.setActiveTalkUrl({
                activeTalkUrl,
                sendSignal: false,
            })
        );
    }

    if (e.key === 'demoTalkUrlChanged') {
        const talkUrl = JSON.parse(e.newValue);
        store.dispatch(
            transactionActions.setActiveDemoTalkUrl({
                talkUrl,
                sendSignal: false,
            })
        );
    }
};

export function sendLocalStorageSignal(key, value = '_') {
    localStorage.setItem(key, JSON.stringify(value));
    setTimeout(() => localStorage.removeItem(key), 100);
}
