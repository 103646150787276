import { useMemo, useState } from 'react';
import styles from './Snapshot.module.css';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

function Snapshot({ src, snapshotId, onRemoveSnapshot, cameraId, cameraType, onEnlargeSnapshot, enlargedSnapshotId, gridPosition }) {
    const [hovered, setHovered] = useState(false);

    const { removeSnapshotHandler, enlargeSnapshotHandler } = useMemo(
        () => ({
            removeSnapshotHandler: () => onRemoveSnapshot(cameraId, snapshotId),
            enlargeSnapshotHandler: () => onEnlargeSnapshot(snapshotId),
        }),
        [onRemoveSnapshot, onEnlargeSnapshot, cameraId, snapshotId]
    );

    function hoverHandler() {
        setHovered(this);
    }

    const isEnlarged = enlargedSnapshotId === snapshotId;
    const isToTheEdgeOfScreen = !enlargedSnapshotId && gridPosition % 3 === 0;

    const { containerClasses, imageClasses, buttonClasses } = classes();

    return (
        <div
            className={containerClasses}
            onMouseEnter={hoverHandler.bind(true)}
            onMouseLeave={hoverHandler.bind(false)}
            onDoubleClick={enlargeSnapshotHandler}
        >
            <img className={imageClasses} alt={`${cameraType}-${snapshotId}`} src={src} />
            {hovered && (
                <div className={buttonClasses}>
                    <DisabledByDefaultIcon
                        color="error"
                        fontSize={isEnlarged ? 'large' : 'medium'}
                        sx={{ cursor: 'pointer' }}
                        onClick={removeSnapshotHandler}
                    />
                </div>
            )}
        </div>
    );

    //styles
    function classes() {
        const containerClasses = `${styles['snapshot']}${
            hovered && !isEnlarged
                ? ` ${styles['snapshot--hovered']} ${isToTheEdgeOfScreen ? ` ${styles['snapshot--hovered-edge']}` : ''}`
                : isEnlarged
                  ? ` ${styles['snapshot--enlarged']}`
                  : ''
        }`;

        const imageClasses = `${styles['snapshot__image']}${
            hovered ? ` ${styles['snapshot__image--hovered']}` : isEnlarged ? ` ${styles['snapshot__image--enlarged']}` : ''
        }`;

        const buttonClasses = `${styles['snapshot__remove']}${isEnlarged ? ` ${styles['snapshot__remove--enlarged']}` : ''}`;

        return { containerClasses, imageClasses, buttonClasses };
    }
}

export default Snapshot;
