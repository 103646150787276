// currently hardcoded

class TalkConfig {
    #serverUrl;
    #timeSlice = 40;
    #type = 'audio';
    #mimeType = 'audio/webm;codecs=pcm';
    #audioBitsPerSecond = 128000;
    #desiredSampRate = 8000;
    #bufferSize = 4096; //16384;
    #numberOfAudioChannels = 1;
    #disableLogs = true;
    #terminateAfterNumOfPacketFailed = 20;

    set serverUrl(serverUrl) {
        this.#serverUrl = serverUrl;
    }

    set timeSlice(timeSlice) {
        if (!timeSlice) return;
        this.#timeSlice = timeSlice;
    }

    set bufferSize(bufferSize) {
        if (!bufferSize) return;
        this.#bufferSize = bufferSize;
    }

    set configuration(configObj) {
        const { uri, recorderTimeSlice, recorderBufferSize } = configObj || {};
        this.serverUrl = uri;
        this.timeSlice = recorderTimeSlice;
        this.bufferSize = recorderBufferSize;
    }
    get serverUrl() {
        return this.#serverUrl;
    }

    get timeSlice() {
        return this.#timeSlice;
    }

    get terminateAfterNumOfPacketFailed() {
        return this.#terminateAfterNumOfPacketFailed;
    }

    get recorderConfig() {
        return {
            type: this.#type,
            mimeType: this.#mimeType,
            audioBitsPerSecond: this.#audioBitsPerSecond,
            desiredSampRate: this.#desiredSampRate,
            bufferSize: this.#bufferSize,
            timeSlice: this.#timeSlice,
            numberOfAudioChannels: this.#numberOfAudioChannels,
            disableLogs: this.#disableLogs,
        };
    }
}

export const talkConfig = new TalkConfig();

if (import.meta.env.VITE_ENV !== 'production') window.talkConfig = talkConfig;
