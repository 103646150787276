import styles from './MicrophoneIconButton.module.css';
import { IconButton, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { useState } from 'react';
import AudioSettings from './AudioSettings/AudioSettings';
import { talkManager } from 'services/Talk/TalkManager';

function MicrophoneIconButton({
    containerStyles,
    active,
    starting,
    paused,
    disabled,
    onClick,
    iconButtonProps,
    iconProps,
    showTooltip = false,
    cameraId,
}) {
    const classes = `${starting ? `${styles['transaction-talk--starting']}` : active ? `${styles['transaction-talk--active']}` : ''}${
        paused ? ` ${styles['transaction-talk--paused']}` : ''
    }`;

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [showAudioSettings, setShowAudioSettings] = useState(false);
    const [audioSettingsPosition, setAudioSettingsPosition] = useState('default');

    const MicIconTooltipTitle = `${active ? 'Stop' : 'Start'} 2 way communication`;

    const handleContexMenu = async (e) => {
        if (!showTooltip) return;
        const position = e.target.getBoundingClientRect()?.y >= 280 ? 'default' : 'right';
        setAudioSettingsPosition(position);
        e.preventDefault();
        try {
            await talkManager.fetchAudioSettings(cameraId);
            setShowAudioSettings((showAudioSettings) => !showAudioSettings);
            setTooltipVisible(false);
        } catch (err) {
            console.error(err);
        }
    };
    const hideAudioSettings = () => setShowAudioSettings(false);

    const tooltipHandlers = {
        onMouseLeave() {
            showTooltip && setTooltipVisible(false);
        },
        onMouseEnter() {
            showTooltip && setTooltipVisible(true);
        },
    };

    return (
        <div style={{ position: 'relative' }}>
            <IconButton className={classes} sx={containerStyles} onClick={onClick} {...iconButtonProps} disabled={disabled}>
                {!paused && (
                    <Tooltip title={MicIconTooltipTitle} open={tooltipVisible}>
                        <MicIcon {...iconProps} onContextMenu={handleContexMenu} {...tooltipHandlers} />
                    </Tooltip>
                )}
                {paused && (
                    <Tooltip title="Unmute microphone" open={tooltipVisible}>
                        <MicOffIcon {...iconProps} color="inherit" onContextMenu={handleContexMenu} {...tooltipHandlers} />
                    </Tooltip>
                )}
            </IconButton>

            {showAudioSettings && (
                <AudioSettings
                    cameraId={cameraId}
                    onClose={hideAudioSettings}
                    disabled={!(active && !starting)}
                    active={active || starting}
                    position={audioSettingsPosition}
                />
            )}
        </div>
    );
}

export default MicrophoneIconButton;
