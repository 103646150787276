import { CameraAudioSettings } from './CameraAudioSettings';
import { TalkSession } from './TalkSession';

class TalkManager {
    #audioSettingsByCameraId = new Map();
    #talkSession;

    constructor() {
        this.#talkSession = new TalkSession(this.#remove);
        if (import.meta.env.VITE_ENV !== 'production') window.TalkSession = this.#talkSession;
    }

    #hasActiveSession(cameraId) {
        return this.#talkSession.isActive() && this.#talkSession.cameraId === cameraId;
    }

    set(cameraId, setting, value) {
        const cameraAudioSettings = this.#get(cameraId);
        if (cameraAudioSettings) cameraAudioSettings[setting] = value;
        if (this.#hasActiveSession(cameraId)) {
            this.#talkSession.apply(setting, value);
        }
    }

    registerSubscriber(cameraId, subscriber) {
        const cameraAudioSettings = this.#get(cameraId);
        if (cameraAudioSettings) cameraAudioSettings.subscriber = subscriber;
    }

    removeSubscriber(cameraId, subscriber) {
        const cameraAudioSettings = this.#get(cameraId);
        if (cameraAudioSettings) {
            cameraAudioSettings.removeSubscriber(subscriber);
            this.#remove(cameraId);
        }
    }

    saveAudioSettings(cameraId) {
        const audioSettings = this.#get(cameraId);
        if (audioSettings) audioSettings.savePreset();
    }

    getCameraAudioSettings(cameraId) {
        const audioSettings = this.#get(cameraId);
        if (audioSettings) return audioSettings.settings;
        return {};
    }

    changeTalkServiceSetting(cameraId, setting) {
        if (this.#hasActiveSession(cameraId)) this.#talkSession.changeTalkServiceSetting(setting);
    }

    flush(cameraId) {
        if (this.#hasActiveSession(cameraId)) this.#talkSession.flush();
    }

    gatherIPs() {
        this.#talkSession.gatherIPs();
    }

    async startSession(talkUrlObj, cameraId, name) {
        // const cameraAudioSettings = await this.fetchAudioSettings(cameraId);
        // if (cameraAudioSettings) await this.#talkSession.start(talkUrlObj, cameraAudioSettings);
        await this.#talkSession.start(talkUrlObj, name);
    }

    async stopSession(removeActiveTalkUrl) {
        await this.#talkSession.stop(removeActiveTalkUrl);
    }

    toggleOutgoingAudioActive() {
        this.#talkSession.toggleOutgoingAudioActive();
    }
    async playDemoFile(talkUrl, sessionId) {
        await this.#talkSession.playDemoFile(talkUrl, sessionId);
    }
    async stopDemoFile() {
        await this.#talkSession.stopDemoFile();
    }

    async fetchAudioSettings(cameraId) {
        const audioSettings = this.#get(cameraId);
        if (audioSettings) return audioSettings.fetchingPreset ? null : audioSettings;
        const newAudioSettings = new CameraAudioSettings(cameraId);
        this.#audioSettingsByCameraId.set(cameraId, newAudioSettings);
        await newAudioSettings.fetchPreset();
        return newAudioSettings;
    }

    #get = (cameraId) => this.#audioSettingsByCameraId.get(cameraId);
    #remove = (cameraId) => {
        const cameraAudioSettings = this.#get(cameraId);
        if (cameraAudioSettings && !cameraAudioSettings?.subscribers?.length && !this.#hasActiveSession(cameraId)) {
            this.#log(`#remove():Removing CameraAudioSettings object, cameraId=${cameraId}`);
            this.#audioSettingsByCameraId.delete(cameraId);
        }
    };

    #log(message, mode = 'log') {
        console[mode](`[TalkManager] ${message}`);
    }

    get settingNames() {
        return CameraAudioSettings.settingNames;
    }
}

export const talkManager = new TalkManager();

if (import.meta.env.VITE_ENV !== 'production') window.talkManager = talkManager;
