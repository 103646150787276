export const vinCheckConfig = {
    transactionId: 'vinCheck', // will be attached as tag to WebRTCEndpoint
    liveStreamsTabId: 'vinCheck', //will be set as part of stream videoOutput id
    defaultStreamsPerPage: 2,
    stompBrokerTopic: `/topic/test-streams`,

    maxNumberOfSnapshots: 9,
    locationsEndpoint: '/locations/vin',
    getStreamsUrl: (locationId) => `/locations/${locationId}/cameras/vin?connect=true`,
};

export const tableConfig = {
    tableId: 'vinCheck',
    rowsPerPage: 15,
    columnsConfig: [
        {
            columns: [
                { caption: 'Location', dataField: 'location.name', dataType: 'string', editable: 'false' },
                { caption: 'Transaction id', dataField: 'vin_transaction_id', dataType: 'string', editable: 'false' },
                { caption: 'Status', dataField: 'status', dataType: 'string', editable: 'false' },
                { caption: 'Created', dataField: 'created', dataType: 'datetime', editable: 'false', sortOrder: 'desc' },
                { caption: 'Modified', dataField: 'modified', dataType: 'datetime', editable: 'false' },
            ],
        },
    ],
};
