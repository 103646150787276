import { createSelector } from '@reduxjs/toolkit';

export const selectTableControlSlice = (state) => state.tables;

export const selectAllTablesData = createSelector([selectTableControlSlice], (tableControlSlice) => tableControlSlice?.allTablesData);

export const selectTableIdFetchingDataInProgress = createSelector(
    [selectTableControlSlice],
    (tableControlSlice) => tableControlSlice?.tableIdFetchingDataInProgress
);

export const selectTableConfigFetchingInProgress = createSelector([selectTableControlSlice], (tableControlSlice) => {
    return tableControlSlice?.tableConfigFetchingInProgress;
});

export const selectTableConfigByConfigName = (configName) =>
    createSelector(
        [selectTableControlSlice, (_, name = configName) => name],
        (tableControlSlice, configName) => tableControlSlice.tableConfig?.[configName]
    );

export const selectTableDataById = (id) =>
    createSelector([selectAllTablesData, (_, tableId = id) => tableId], function (allTablesData, tableId) {
        return allTablesData?.[tableId];
    });

export const selectTableByIdAndConfigName = (id, configName) =>
    createSelector([selectTableDataById(id), selectTableConfigByConfigName(configName)], function (table, columnsConfig) {
        let tableData = [];
        if (table) {
            for (let page in table) {
                for (let transaction of table[page].data) {
                    tableData.push({ ...transaction });
                }
            }
        }

        return { tableData, columnsConfig, locationTransactions: table };
    });
