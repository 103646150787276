import styles from './AdditionalInfo.module.css';
import PropTypes from 'prop-types';

function AdditionalInfo({ option, hasAlerts }) {
    const isDuplicate = option.duplicate;
    const {
        identificationNumber = '',
        dateOfBirth = '',
        position = '',
        companyCardNumber = '',
        company = { fullName: '' },
        cdl = '',
        address = '',
        industryType = '',
        relationType = '',
    } = option;

    if (!hasAlerts && !isDuplicate) {
        return null;
    }

    return (
        <div className={styles.container}>
            {industryType && (
                <p>
                    <span>Industry Type: </span> {industryType}
                </p>
            )}
            {relationType && (
                <p>
                    <span>Relation Type: </span> {relationType}
                </p>
            )}
            {position && (
                <p>
                    <span>Title: </span> {position}
                </p>
            )}
            {dateOfBirth && (
                <p>
                    <span>D.o.B: </span> {dateOfBirth}
                </p>
            )}
            {address && (
                <p>
                    <span>Address: </span> {address}
                </p>
            )}
            {identificationNumber && (
                <p>
                    <span>DL: </span> {identificationNumber}
                </p>
            )}
            {cdl && (
                <p>
                    <span>CDL: </span> {cdl}
                </p>
            )}
            {companyCardNumber && (
                <p>
                    <span>Company Card #: </span> {companyCardNumber}
                </p>
            )}
            {company?.fullName && (
                <p>
                    <span>Organization: </span> {company.fullName}
                </p>
            )}
        </div>
    );
}

export default AdditionalInfo;

AdditionalInfo.propTypes = {
    option: PropTypes.shape({
        duplicate: PropTypes.number,
        id: PropTypes.number,
        identificationNumber: PropTypes.string,
        identificationType: PropTypes.string,
        dateOfBirth: PropTypes.string,
        position: PropTypes.string,
        companyCardNumber: PropTypes.string,
        company: PropTypes.shape({
            fullName: PropTypes.string,
        }),
        cdl: PropTypes.string,
        address: PropTypes.string,
        industryType: PropTypes.string,
        relationType: PropTypes.string,
    }),
    hasAlerts: PropTypes.bool,
};
