import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import { defaultTransformWrapperConfig } from '../config';
import { useEffect } from 'react';

function DigitalZoom({ children, reset }) {
    const { resetTransform } = useControls();

    useEffect(() => {
        if (reset) resetTransform();

        // eslint-disable-next-line
    }, [reset]);
    return <TransformComponent>{children}</TransformComponent>;
}

export default function DigitalZoomWraper({ children, config, ...rest }) {
    return (
        <TransformWrapper {...defaultTransformWrapperConfig} {...config}>
            <DigitalZoom {...rest}>{children}</DigitalZoom>
        </TransformWrapper>
    );
}
