import styles from './StreamStateNotification.module.css';

function StreamStateNotification({ text }) {
    return (
        <div className={styles.notification}>
            <span className="material-symbols-outlined" style={{ color: '#4F4F4F', fontSize: '1.8rem' }}>
                videocam_off
            </span>
            <p>{text}</p>
        </div>
    );
}

export default StreamStateNotification;
