import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Markup } from 'interweave';

export default function AiqDialog({ title, text, open, onClose, showDefaultActions = true, children }) {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& [class^="MuiPaper"]': {
                    maxWidth: '750px',
                    maxHeight: '95vh',
                    padding: '.5rem',
                },

                '& h2': {
                    textAlign: 'center',
                    marginBottom: '0.5rem',
                },
                '& p[class^="MuiDialogContentText"]': {
                    padding: '0 0 2rem 1rem',
                },
            }}
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            <DialogContent>
                {text && (
                    <DialogContentText id="alert-dialog-description">
                        <Markup content={text} />
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            {showDefaultActions && (
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
