export const PERIOD_ACCESS_TYPES = {
    ONE_TIME: 'One Time',
    PERMANENT: 'Ongoing',
};

export const INDUSTRY_TYPE = {
    Automotive: 'AUTOMOTIVE',
    Trucking: 'TRUCKING',
    Residental: 'RESIDENTAL',
};

export const INDUSTRY_TYPE_DROPDOWN_OPTION = {
    [INDUSTRY_TYPE.Automotive]: {
        id: INDUSTRY_TYPE.Automotive,
        label: 'Automotive',
    },
    [INDUSTRY_TYPE.Trucking]: {
        id: INDUSTRY_TYPE.Trucking,
        label: 'Trucking',
    },
    [INDUSTRY_TYPE.Residental]: {
        id: INDUSTRY_TYPE.Residental,
        label: 'Residental',
    },
};

export const RELATION_TYPE = {
    Tenant: 1,
    ThirdPartyCarrier: 2,
    Vendor: 3,
    Unknown: 4,
};

export const RELATION_TYPE_DROPDOWN_OPTION = {
    [RELATION_TYPE.Tenant]: {
        id: RELATION_TYPE.Tenant,
        label: 'Tenant',
    },
    [RELATION_TYPE.ThirdPartyCarrier]: {
        id: RELATION_TYPE.ThirdPartyCarrier,
        label: 'Third Party Carrier',
    },
    [RELATION_TYPE.Vendor]: {
        id: RELATION_TYPE.Vendor,
        label: 'Vendor',
    },
    [RELATION_TYPE.Unknown]: {
        id: RELATION_TYPE.Unknown,
        label: '',
    },
};
