import { formConfig } from 'components/cameraConfiguration/form/formConfig';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formControlActions } from 'store/formControlSlice/formControlSlice';
import styles from './AIQAutocomplete.module.css';
import Dropdown from './Dropdown/Dropdown';
import { checkIfValueIncludes, checkIfValueStartsWith, setOptionLabel } from './searchHelper';

function AIQAutocomplete({
    renderInput,
    options,
    getOptionLabel,
    onInputChange = () => {},
    onChange = () => {},
    onBlur = () => {},
    value,
    freeSolo,
    containerClasses,
    noOptionsText = 'No options...',
    isOptionsLoadingInProgress,
    optionsLoadingText = 'Loading options...',
    fieldName,
    referenceExpr,
    initialReferenceValue,
    onSelect,
}) {
    const getInputValue = useCallback(
        (value) => {
            return getOptionLabel ? setOptionLabel(value, getOptionLabel) : (value ?? '');
        },
        [getOptionLabel]
    );

    const getOption = useCallback(
        (optionLabel, referenceValue) => {
            if (referenceValue && referenceValue >= 0) return options.find((option) => option[referenceExpr] === referenceValue);
            if (getOptionLabel) return options.find((option) => getOptionLabel(option) === optionLabel);
            return optionLabel;
        },
        [getOptionLabel, referenceExpr, options]
    );
    const dispatch = useDispatch();
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [inputValue, setInputValue] = useState(getInputValue(value));
    const [referenceValue, setReferenceValue] = useState(initialReferenceValue);
    const [dropdownOpened, setDropdownOpened] = useState(false);

    useEffect(() => {
        setInputValue(getInputValue(value));
    }, [value, getOptionLabel, getInputValue]);

    function inputBlurHandler(e) {
        const inputText = e.target.value;
        if (inputValue === '' && inputText !== '' && getOptionLabel) {
            const option = getOption(inputText, referenceValue);

            if (option) {
                onChange(e, option);
                onBlur(e);
                return;
            }
        }
        const selectedOption = getOption(inputValue, referenceValue);
        if (!selectedOption) {
            setInputValue(getInputValue(value));
            !value && setFilteredOptions(options);
        }

        if (selectedOption) {
            onChange(e, selectedOption);
            setFilteredOptions(options);
        }

        if (typeof value === 'object') {
            setFilteredOptions(options);
        }
        if ((fieldName === 'vehicle.transportType' || fieldName === 'announcement.transportType') && !selectedOption) {
            setFilteredOptions(options);
            dispatch(
                formControlActions.setFieldValue({
                    formId: formConfig.formId,
                    fieldName,
                    value,
                })
            );
        }
        onBlur(e);
    }

    function focusHandler(focused) {
        setDropdownOpened(focused);
    }

    function inputChangeHandler(e) {
        let inputText = e.target.value;
        setInputValue(inputText);
        onInputChange(e, inputText);

        if (inputText === '') {
            setFilteredOptions(options);
            return;
        }

        setFilteredOptions(() => {
            let optionsStartingWithSearchedText = [];
            let optionsIncludesText = [];
            const inputTextStandardized = inputText.trim().toLowerCase();

            options.forEach((option) => {
                const startsWithMatch = checkIfValueStartsWith(option, inputTextStandardized);

                if (startsWithMatch) {
                    optionsStartingWithSearchedText.push(option);
                } else {
                    // Check all fields of the option object
                    const matches = checkIfValueIncludes(option, inputTextStandardized);
                    if (matches) {
                        optionsIncludesText.push(option);
                    }
                }
            });
            const filteredOptions = [...optionsStartingWithSearchedText, ...optionsIncludesText];
            return filteredOptions;
        });
    }

    useEffect(() => {
        setFilteredOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options?.length]);

    const hasAlerts = useMemo(() => !!options.find((option) => option.alerts), [options]);

    return (
        <div className={`${styles['autocomplete-container']}${containerClasses ? ` ${containerClasses}` : ''}`}>
            {renderInput({
                option: getOption(inputValue, referenceValue),
                value: inputValue,
                onChange: inputChangeHandler,
                onBlur: inputBlurHandler,
                onFocus: focusHandler,
                dropdownArrow: true,
            })}
            <Dropdown
                {...{
                    isOptionsLoadingInProgress,
                    optionsLoadingText,
                    dropdownOpened,
                    filteredOptions,
                    noOptionsText,
                    onChange,
                    setDropdownOpened,
                    setInputValue,
                    setReferenceValue,
                    freeSolo,
                    getInputValue,
                    value,
                    hasAlerts,
                    referenceExpr,
                    onSelect,
                }}
            />
        </div>
    );
}

export default AIQAutocomplete;
