import styles from './userNavigation.module.css';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import UserListDropDown from '../userList/UserListDropDown';
import { useSelector } from 'react-redux';
import { selectSharedWorker } from 'store/transactionsSlice/transactionsSelectors';
import Help from '../help/Help';

export default function UserNavigation({ user, isTransactionPageActive }) {
    const { windowId, windowCount } = useSelector(selectSharedWorker) || {};
    return (
        <>
            <div className={styles.userNavigation}>
                {windowId && (
                    <p>
                        {windowId}/{windowCount}
                    </p>
                )}
                <Help isTransactionPageActive={isTransactionPageActive} />
                <NotificationsNoneIcon color="disabled" />

                <div>
                    <p className={styles.userName}>{user?.username}</p>
                    <p className={styles.userRoles}>{user?.roles?.join(',')}</p>
                </div>
                <UserListDropDown />
            </div>
        </>
    );
}
