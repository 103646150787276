import { all, call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { formControlActions } from '../formControlSlice';
import { notificationHandler as notificationsHandler, readFromObj, writeInObj } from '../../../utils/utils';
import { tableControlActions } from 'store/tableControlSlice/tableControlSlice';
import { selectFormById } from '../formControlSelectors';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import { store } from 'store/store';
import { axiosAiq } from 'config';
import { sendLocalStorageSignal } from 'utils/sessionStorageHandlers';
import { populateEmptyTransactionFormFields } from '../fieldControllers';
import { getTransactionEncodedImages, leaveDesk, updateTransaction } from 'store/transactionsSlice/transactionsSagas';
import { sharedWorkerActions } from 'workers/sharedWorker/utils/utils';
import { perf } from 'store/transactionsSlice/performance';
import copyToForm from './CopyToForm';

const maxSubmitDiscardAttempts = 3;

export function* initializeNewForm(transactionId) {
    try {
        yield put(formControlActions.initializeNewTransactionFormStart());
        console.log(`initializeNewForm(): transactionId=${transactionId}, initialization of new form starts`);
        const response = yield axiosAiq.get(`/transactions/trucks/${transactionId}`);
        console.log(`initializeNewForm(): transactionId=${transactionId} transaction data received. `);
        const { data: transactionData } = response;

        yield put(
            formControlActions.initializeNewTransactionFormSuccess({
                transactionData,
            })
        );
        yield all([call(fetchTransactionFormConfig, transactionId), call(getTransactionEncodedImages, transactionId)]);
        console.log(`initializeNewForm(): Initialization of new form finished, transactionId=${transactionId}`);
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            title: 'Error initializing new transaction form',
        });
        yield put(formControlActions.initializeNewTransactionFormFailed(err.message));
    }
}

export function prepareTransactionForSubmit(form) {
    const {
        form: { values },
        transactionData,
        transactionIdAnnouncementUsed,
        internalIdAnnouncementUsed,
    } = form;

    const transactionToSubmit = structuredClone(transactionData);
    for (let fieldId of Object.keys(values)) {
        writeInObj(transactionToSubmit, fieldId, values[fieldId]);
    }
    transactionIdAnnouncementUsed && (transactionToSubmit.announcement.transactionIdUsed = transactionIdAnnouncementUsed);
    internalIdAnnouncementUsed && (transactionToSubmit.internalIdAnnouncementUsed = internalIdAnnouncementUsed);
    return transactionToSubmit;
}

export function* submitForm({ payload }) {
    const { transactionId, locationId, tabId, autoPopulateEmptyFormFields, retryAttempt = 0 } = payload;
    let form = yield select(selectFormById(transactionId));

    autoPopulateEmptyFormFields && (form = (yield call(populateEmptyTransactionFormFields, form)) || form);

    const errors = form?.form?.errors;

    let firstInvalidField;
    for (let fieldName of Object.keys(errors)) {
        if (errors[fieldName]) {
            yield put(
                formControlActions.setFieldTouched({
                    formId: transactionId,
                    fieldName,
                })
            );

            if (firstInvalidField) continue;
            firstInvalidField = fieldName;
        }
    }

    if (firstInvalidField) {
        yield put(formControlActions.submitFormFailed());
        yield call(notificationsHandler, {
            title: 'Fill all required fields!',
            variant: 'warning',
        });

        const element = document.querySelector(`[name="${firstInvalidField}"]`);
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
        element.select();
        return;
    }

    try {
        const transactionToSubmit = yield call(prepareTransactionForSubmit, form);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json, application/javascript, text/javascript, text/json',
            },
        };

        const response = yield axiosAiq.put(`/transactions/trucks/${transactionId}`, transactionToSubmit, config);

        const transactionData = response.data;

        yield put(formControlActions.submitFormSuccess(transactionData));

        yield sendLocalStorageSignal('transactionClosed', transactionId);
        sharedWorkerActions.transactionFinished(transactionId);

        yield put(
            tableControlActions.fetchTableDataStart({
                tableId: locationId,
                locationId,
                pageNum: 0,
                fetchLocationAnnouncements: true,
            })
        );
        yield call(notificationsHandler, {
            title: `Transaction ${transactionId} successfully submited.`,
            variant: 'success',
        });

        yield put(
            transactionActions.closeTransactionPanel({
                tabId,
                transactionId,
                locationId,
            })
        );
        console.log(`submitForm(): transactionId=${transactionId} is successfully submited`);
        yield put(transactionActions.setLastClosedTransactionId(transactionId));
    } catch (err) {
        if (yield call(handleDeskInactive, err)) return;
        if (err?.response?.status === 409) {
            yield call(updateTransaction, transactionId);
            if (retryAttempt < maxSubmitDiscardAttempts) {
                console.log(
                    `submitForm(): Mismatch version detected, transactionId=${transactionId} updated to newest version, retrying...  attempt #${
                        retryAttempt + 1
                    } of ${maxSubmitDiscardAttempts}`
                );
                yield call(submitForm, { payload: { ...payload, retryAttempt: retryAttempt + 1 } });
            } else {
                yield call(notificationsHandler, {
                    action: formControlActions.submitFormFailed,
                    title: 'New data was received for this transaction, if you want to save please click Submit again',
                    variant: 'info',
                });
            }
            return;
        }

        if (err?.response?.status === 503) {
            yield call(notificationsHandler, {
                action: formControlActions.submitFormFailed,
                title: 'AIQ not available right now, please try again later.',
                variant: 'info',
            });
            return;
        }

        yield call(notificationsHandler, {
            err,
            action: formControlActions.submitFormFailed,
            title: 'Error submiting transaction',
        });
    }
}

export function* discardTransaction({ payload }) {
    const { tabId, transactionId, locationId, retryAttempt } = payload;
    const form = yield select(selectFormById(transactionId));
    const { transactionData } = form || {};
    try {
        yield axiosAiq.put(`/transactions/trucks/${transactionId}/discard`, transactionData);

        yield sendLocalStorageSignal('transactionClosed', transactionId);
        sharedWorkerActions.transactionFinished(transactionId);

        yield put(
            tableControlActions.fetchTableDataStart({
                tableId: locationId,
                locationId,
                pageNum: 0,
            })
        );
        yield put(
            transactionActions.closeTransactionPanel({
                tabId,
                transactionId,
                locationId,
            })
        );
        yield put(transactionActions.setLastClosedTransactionId(transactionId));
        console.log(`discardTransaction(): transactionId=${transactionId} is successfully discarded`);
        yield put(formControlActions.discardTransactionSuccess());
    } catch (err) {
        if (yield call(handleDeskInactive, err)) return;
        if (err.response?.status === 400) {
            yield call(refreshTransaction, { transactionId, form });
            yield call(notificationsHandler, {
                variant: 'info',
                title: 'Transaction already FINISHED, data is autopopulated from previously submited transaction',
            });
            yield put(formControlActions.setdiscardTransactionInProgress(false));
            return;
        }

        if (err?.response?.status === 409) {
            yield call(updateTransaction, transactionId);
            if (retryAttempt <= maxSubmitDiscardAttempts) {
                console.log(
                    `submitForm(): Mismatch version detected, transactionId=${transactionId} updated to newest version, retrying...  attempt #${
                        retryAttempt + 1
                    } of ${maxSubmitDiscardAttempts}`
                );
                yield call(submitForm, { payload: { ...payload, retryAttempt: retryAttempt + 1 } });
            } else {
                yield call(notificationsHandler, {
                    title: 'New data was received for this transaction, if you want to discard please click Discard again',
                    variant: 'info',
                });
                yield put(formControlActions.setdiscardTransactionInProgress(false));
            }
            return;
        }

        if (err?.response?.status === 503) {
            yield call(notificationsHandler, {
                action: formControlActions.submitFormFailed,
                title: 'AIQ not available right now, please try again later.',
                variant: 'info',
            });
            return;
        }

        yield call(notificationsHandler, {
            err,
            title: 'Error discarding transaction',
        });
        yield put(formControlActions.discardTransactionFailed(err));
    }
}

export function* refreshTransaction({ transactionId, form }) {
    try {
        const response = yield axiosAiq.get(`/transactions/trucks/${transactionId}`);

        const { data: transactionData } = response;
        const { values, errors } = form.form;

        yield put(formControlActions.setTransactionData({ transactionData }));
        Object.keys(values).forEach((fieldName) => {
            const value = values[fieldName];
            const newValue = readFromObj(transactionData, fieldName);
            if (newValue !== 'aiq.fieldNotExist' && newValue !== value) {
                store.dispatch(
                    formControlActions.setFieldValue({
                        formId: transactionId,
                        fieldName,
                        value: newValue,
                    })
                );

                if (errors[fieldName] && newValue)
                    store.dispatch(
                        formControlActions.setFieldError({
                            formId: transactionId,
                            fieldName,
                            error: false,
                        })
                    );
            }
        });
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            action: formControlActions.submitFormFailed,
            title: 'Error refreshing transaction',
        });
    }
}

export function* fetchTransactionFormConfig(transactionId) {
    perf.mark(transactionId, perf.markNames.fetchFormConfigStart);
    try {
        console.log(`fetchTransactionFormConfig(): Fetch transaction form config, transactionId=${transactionId}`);
        yield put(formControlActions.fetchTransactionFormConfigStart());
        const response = yield axiosAiq(`/transactions/trucks/form/fields?transactionId=${transactionId}`);
        console.log(`fetchTransactionFormConfig(): Form config received, transactionId=${transactionId}`);
        const { data: transactionFormConfig } = response;

        yield put(
            formControlActions.fetchTransactionFormConfigSuccess({
                transactionId,
                transactionFormConfig,
            })
        );
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            action: formControlActions.fetchTransactionFormConfigFailed,
            title: 'Error fething transaction form config file',
        });
    }
    perf.mark(transactionId, perf.markNames.fetchFormConfigEnd);
}

export function* validateTransactionFormField({ payload: { formId, fieldName } }) {
    try {
        let form = yield select(selectFormById(formId));

        const transactionToSubmit = yield call(prepareTransactionForSubmit, form);
        const response = yield axiosAiq.put(`/transactions/trucks/${formId}/validate?dataField=${fieldName}`, transactionToSubmit);
        const { data: transactionData } = response;

        form = yield select(selectFormById(formId));

        if (!form) {
            console.log(
                `validateTransactionFormField(): transactionId=${formId}, fieldName=${fieldName} validation successful,but transaction already finished, exiting...`
            );
            return;
        }

        yield put(formControlActions.setTransactionData({ transactionData }));
        const {
            form: { values },
        } = form;

        let newValue = readFromObj(transactionData, fieldName);
        if (newValue !== values[fieldName]) {
            store.dispatch(
                formControlActions.setFieldValue({
                    formId,
                    fieldName,
                    value: newValue,
                })
            );
        }
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            title: `Error validating ${fieldName}`,
        });
    }
}

let ongoingRelayRequests = {};

export function* activateRelay({ payload: { relayId, operation } }) {
    const requestId = `${relayId}-${operation}`;
    try {
        if (ongoingRelayRequests[requestId]) return;
        else ongoingRelayRequests[requestId] = true;
        console.log(`activateRelay(): ACTIVATING operation=${operation} on relayId=${relayId}`);
        yield axiosAiq.post(`/relay/submit/${relayId}?operation=${operation}`);
        console.log(`activateRelay(): SUCCESSFUL operation=${operation} on relayId=${relayId}`);

        let title = `Gate successfully `;
        switch (operation) {
            case 'ON':
                title += 'opened';
                break;
            case 'OFF':
                title += 'closed';
                break;
            default:
                title += 'activated';
        }
        yield call(notificationsHandler, {
            title,
            variant: 'success',
        });
    } catch (err) {
        console.log(`activateRelay(): ERROR activating operation=${operation} on relayId=${relayId}`);
        const title = `Error activating operation=${operation} on relayId=${relayId} `;

        yield call(notificationsHandler, {
            err,
            title,
        });
    }
    // eslint-disable-next-line no-unused-vars
    const { [requestId]: toBeDeleted, ...rest } = ongoingRelayRequests;
    ongoingRelayRequests = rest;
}

export function* onActivateRelay() {
    yield takeEvery(formControlActions.activateRelay.type, activateRelay);
}

export function* onDiscardTransactionStart() {
    yield takeLeading(formControlActions.discardTransactionStart.type, discardTransaction);
}

export function* onSubmitFormStart() {
    yield takeLeading(formControlActions.submitFormStart.type, submitForm);
}

export function* onValidateTransactionFormField() {
    yield takeEvery(formControlActions.validateTransactionFormField.type, validateTransactionFormField);
}

export function* formControlSaga() {
    yield all([
        call(onSubmitFormStart),
        call(onValidateTransactionFormField),
        call(onDiscardTransactionStart),
        call(onActivateRelay),
        ...copyToForm.init(),
    ]);
}

function* handleDeskInactive(err) {
    if (err?.response?.data?.error?.includes('DESK_INACTIVE')) {
        yield call(notificationsHandler, {
            title: 'Unsubscribed from desk due network problems, try to select desk again...',
            variant: 'info',
        });
        yield call(leaveDesk);
        return true;
    }
}
