import { Button } from '@mui/material';
import styles from './CameraSection.module.css';
import Snapshots from './snapshots/Snapshots';
import { useCallback, useEffect } from 'react';

function CameraSection({ cameraId, cameraType, videoOutputId, stream, onTakeSnapshot, onRemoveSnapshot, snapshots, warning, sendingSnapshots }) {
    useEffect(
        () => () => onRemoveSnapshot(cameraId, 'all'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const takeSnapshotHandler = useCallback(() => onTakeSnapshot(cameraId, videoOutputId), [onTakeSnapshot, cameraId, videoOutputId]);

    const removeSnapshotHandler = function () {
        onRemoveSnapshot(cameraId, this);
    };

    const isRemoveButtonDisabled = !snapshots?.length || sendingSnapshots;
    const isTakeSnapshotButtonDisabled = !!warning?.type || sendingSnapshots;

    return (
        <div className={styles['camera-section']}>
            <div>
                <div>{stream}</div>
                <div className={styles.controls}>
                    <Button onClick={removeSnapshotHandler.bind('all')} disabled={isRemoveButtonDisabled} variant="outlined" color="error">
                        Remove all
                    </Button>
                    <div className={`${styles.warning}${!warning ? ` ${styles['hidden']}` : ''}`}>{warning?.warning}</div>
                    <div className={styles['controls__main']}>
                        <Button onClick={removeSnapshotHandler.bind('last')} disabled={isRemoveButtonDisabled} variant="contained" color="error">
                            Remove last
                        </Button>
                        <Button onClick={takeSnapshotHandler} variant="contained" disabled={isTakeSnapshotButtonDisabled}>
                            Take snapshot
                        </Button>
                    </div>
                </div>
            </div>
            <Snapshots cameraId={cameraId} cameraType={cameraType} onRemoveSnapshot={onRemoveSnapshot} snapshots={snapshots} />
        </div>
    );
}

export default CameraSection;
