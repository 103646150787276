import { formatTime, formatDateFromIsoString } from 'utils/dateTimeUtils';

export const renderLimitedTimeAccess = (weekdays) => {
    const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return weekdays
        .map((weekday, index) => formatWeekday(weekday, weekdayNames[index]))
        .filter(Boolean)
        .join(' | ');
};

const formatWeekday = (weekday, weekdayName) => {
    const timeRanges = weekday?.timeRanges;

    if (!timeRanges) return null;

    const timeRangeStrings = timeRanges.map(formatTimeRange).join(', ');

    return `${weekdayName} (${timeRangeStrings})`;
};

const formatTimeRange = (timeRange) => {
    return `${formatTime(timeRange.startTime)} - ${formatTime(timeRange.endTime)}`;
};

export const renderPermanentAccess = (startTime, endTime) => {
    if (!startTime && !endTime) return '';
    return `(${formatDateFromIsoString(startTime)} - ${formatDateFromIsoString(endTime)})`;
};
